import React, { Component, createRef } from "react";
import API,{API_ENDPOINT_SUPPLIER} from '../../../services/API';
import FullSpinner from '../Spinner/FullSpinner';
import { AddModal } from '../../common/Modals/CommonConfirmationModal.js';
import FileViewerModal from "../../common/FileViewerModal/FileViewerModal";
import User from "../../../services/User.js";
import { compareRole,enumRole } from "../../../configs/GlobalConfig.js";

import CommonPopUpModal from "../../CommonPopUpModal/CommonPopUpModal.js";
import FileUploader from "../FileUploader/FileUploader.js";
import fileUploadConfig from '../../../configs/FileUploader.js';
import InvoiceCustomerData from "../InvoiceComponents/InvoiceCustomerData.js";


var _salesContractLabel = "sales_contract";
var _shippingDocumentLabel= "shipping_documents";
var _paymentConfirmationLabel= "payment_confirmation";
const { 
    supportedFileTypes, 
    placeholders,
    duplicateFileMessage
} = fileUploadConfig;

const getFileConfig = (type) => {
    const fileTypeConfig = supportedFileTypes[type] || {};
    return {
        supportedExt: fileTypeConfig.extensions || [],
        errorMsgType: fileTypeConfig.message || '',
        placeholder: placeholders[type] || '',
        errorMsgDuplicate: duplicateFileMessage || ''
    };
};

export default class UploadInvPreFunding extends React.Component {
    user = new User();
    api = new API();
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            invoices: [],
            fromDate: '',
            toDate: '',
            data: this.props.selectedInv ?
                [{
                    "customerDetails": {
                        "name": this.props.selectedInv.name || "",
                        "email": this.props.selectedInv.email || "",
                        "address1": this.props.selectedInv.address || "",
                        "city": this.props.selectedInv.city || "",
                        "region": this.props.selectedInv.state || "",
                        "postalCode": this.props.selectedInv.zip || "",
                        "country": this.props.selectedInv.country || ""
                    }
                }
                ] : "",
            columns: null,
            error: null,
            uploadFileName: "Drop invoice file to upload",
            invoiceFile:this.props.selectedInv ? this.props.selectedInv.invoiceFile : [],
            isFileViewerModal: false,
            fileViewerContent: null,
            isPdfParsing: false,
            docs1:  [],
            docs2:  [],
            docs3: [],
            selectedCountryCode: "",
            isValidEmail: true,
            isEmailNotEmpty: true,
            isValidBusinessname: true,
            isValidBusinessaddress: true,
            isValidCountry: true,
            isValidState: true,
            additionalDocumentCount: 0,
            isAddedAdditionalFiles:false,
            isInvitedBuyer: this.props.selectedInv?.isInvitedBuyer ? true : false
        };
        this.state.invoiceConfig = getFileConfig('invoice');
        this.state.poConfig = getFileConfig('po');
        this.state.addDocConfig = getFileConfig('additionalDocx');
        this.invoiceCustomerDataRef = createRef();
    }
    
    componentDidMount(){
        // if(this.props.selectedInv){
        //     const isValidCountry = this.props.selectedInv.country &&  this.props.selectedInv.country.toString().trim() !== '';
        //     this.setState({isValidCountry})
        // }
        this.getAdditionalFile();
    }

    getAdditionalFile=async()=>{
        const { selectedInv } = this.props;
        if(selectedInv && selectedInv.additionalDocs && selectedInv.additionalDocs.length>0){
            const _docs1=selectedInv.additionalDocs.filter(f_=>f_.fileCategory==_salesContractLabel ||f_.fileType==_salesContractLabel);
            const _docs2=selectedInv.additionalDocs.filter(f_=>f_.fileCategory==_shippingDocumentLabel ||f_.fileType==_shippingDocumentLabel);
            const _docs3=selectedInv.additionalDocs.filter(f_=>f_.fileCategory==_paymentConfirmationLabel ||f_.fileType==_paymentConfirmationLabel);
            this.setState({
                docs1:_docs1,
                docs2:_docs2,
                docs3:_docs3,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.data && !prevState.data) {
            this.invoiceCustomerDataRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    handleInvoiceSubmit = async (isEditInv) => {
        const { data, invoiceFile, docs1,docs2,docs3 } = this.state;
        let _additionalDocs1 = docs1.map(d => {
            d.fileType = "sales_contract";
            return d;
        })
        let _additionalDocs2 = docs2.map(d => {
            d.fileType = "shipping_documents";
            return d;
        })
        let _additionalDocs3 = docs3.map(d => {
            d.fileType = "payment_confirmation";
            return d;
        })

        const _additionalDocs=[..._additionalDocs1,..._additionalDocs2,..._additionalDocs3];
        let _addedTradingPartnerDetails=null;
        let dataFields = {};
        let isFormValid=false;
        if (data && data[0] && data[0].customerDetails) {
            const customerDetails = data[0].customerDetails;
            dataFields = {
                businessName: customerDetails.name,
                email: customerDetails.email,
                address: customerDetails.address1,
                country: customerDetails.country,
                state: customerDetails.region,
                zipCode: customerDetails.postalCode,
                city: customerDetails.city,
                isEditInv: isEditInv,
                _id: isEditInv ? this.props.selectedInv._id : ""
            };
            const isValidBusinessaddress = dataFields.businessName.toString().trim() !== '';
            const isValidCountry=dataFields.country.toString().trim() !== '';
            const isValidState=dataFields.state.toString().trim() !== '';
            isFormValid = (
                customerDetails.name &&
                customerDetails.address1 &&
                customerDetails.country &&
                customerDetails.region && isValidBusinessaddress && isValidCountry && isValidState
            );
        }
        if(isFormValid) {
            if (!this.state.isInvitedBuyer) {
                _addedTradingPartnerDetails=await this.api.createTradingPartnerWithParitialData(dataFields);
            }
            else {
                _addedTradingPartnerDetails = this.props.selectedInv;
            }

            let _errorTitle = compareRole(this.user.currentUserRole(),enumRole.Supplier)?"Create Buyer Error":"Create Supplier Error";
            if(_addedTradingPartnerDetails && (_addedTradingPartnerDetails.error || _addedTradingPartnerDetails.msg)){
                AddModal(_errorTitle, <div>{this.props.t("could_register_trading_partner_details")} admin@ledgerfunding.com</div>, null, false);
            }
            else{
                if (_addedTradingPartnerDetails.isInvitedBuyer && !this.props.selectedInv) {
                    AddModal(this.props.t("business_already_registered"), <div>{this.props.t("trading_partner_is_already_registered")}</div>, null, false);
                }

                _addedTradingPartnerDetails.invoiceFile=invoiceFile;
                _addedTradingPartnerDetails.additionalDocs=_additionalDocs;
                this.props.onClose(true,_addedTradingPartnerDetails);
            }
        }
        else{
            _addedTradingPartnerDetails=this.props.selectedInv;
            _addedTradingPartnerDetails.invoiceFile=invoiceFile;
            _addedTradingPartnerDetails.additionalDocs=_additionalDocs;
            this.props.onClose(true,_addedTradingPartnerDetails);
        }
    }

    getCustomerDetails() {
        const { data } = this.state;
        if (data && data[0].customerDetails) {
            return data[0].customerDetails;
        }
        return {};
    }

    isButtonEnable=()=>{
        const { data, isValidEmail, isValidBusinessname, isValidBusinessaddress, isValidCountry, isValidState, isEmailNotEmpty,isAddedAdditionalFiles} = this.state;
        let isFormValid = false;
        if (data && data[0] && data[0].customerDetails) {
            const customerDetails = data[0].customerDetails;
            isFormValid = (
                customerDetails.name &&
                customerDetails.address1 &&
                customerDetails.country &&
                customerDetails.region && isValidBusinessaddress && isValidCountry && isValidState
            );
        }
        const isEditInv = this.props.selectedInv ? true : false
        if(isEditInv && isAddedAdditionalFiles){
            isFormValid=true;
        }
        return isFormValid;
    }

    handleModalClose = () => {
        this.props.onClose();
    }

  handleInvoiceLoaded = (documentData) => {
    this.setState({
      data: documentData.data,
      isValidCountry: documentData.isValidCountry,
      isValidState: documentData.isValidState,
      invoiceFile: documentData.invoiceFile,
      isPdfParsing: documentData.isPdfParsing,
    });
  };

    handleLoadingError = (errorData) => {
        console.log(errorData)
    };

    handleAddDocUpload = (newDocs, stateToUpdate) => {
        this.setState(prevState => ({
            [stateToUpdate]: newDocs,
            isAddedAdditionalFiles : true
        }));
    };
    
    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleDataUpdate = (updatedData) => {
        console.log(updatedData)
        this.setState(prevState => ({
            data: [
                {
                    ...prevState.data[0],
                    customerDetails: updatedData
                }
            ]
        }));
    }

    generateFileUploaders = () => {
        const items = [
            { title: this.props.t("file_upload_label.label09") },
            { title: this.props.t("file_upload_label.label10") },
            { title: this.props.t("file_upload_label.label11") },
        ];

        const fileUploaders = [];
        for (let i = 1; i <= items.length; i++) {
            fileUploaders.push({
                id: i,
                title: items[i - 1].title,
                documents: this.state[`docs${i}`],
                onDocUpload: (newDocs) => this.handleAddDocUpload(newDocs, `docs${i}`),
                onDelete: (filteredDocs) => this.handleDelete(filteredDocs, `docs${i}`),
                onView: this.handleView,
                namelength: 50,
                supportedExt: this.state.addDocConfig.supportedExt,
                //errorMsgType: this.props.t("file_upload_msg.please_provide_supported_file_format"),
                errorMsgDuplicate: this.props.t("file_upload_msg.default_error_msg"),
                placeholder: this.props.t("file_upload_msg.common_upload_msg"),
                isdisabled: false,
                isMultiple: true,
                isExtraction: false,
            });
        }

        return fileUploaders;
    };

    handleDelete = (filteredDocs, stateToUpdate) => {
        this.setState(prevState => ({
            [stateToUpdate]: filteredDocs,
        }));
    };

    render() {
        const { data, isValidEmail, isValidBusinessname, isValidBusinessaddress, isValidCountry, isValidState, isEmailNotEmpty } = this.state;
        let isFormValid = false;
        if (data && data[0] && data[0].customerDetails) {
            const customerDetails = data[0].customerDetails;
            isFormValid = (
                customerDetails.name &&
                customerDetails.address1 &&
                customerDetails.country &&
                customerDetails.region && isValidBusinessaddress && isValidCountry && isValidState
            );
        }
        const customerDetails = this.getCustomerDetails();
        const isEditInv = this.props.selectedInv ? true : false
        const { invoiceConfig, poConfig, addDocConfig  } = this.state;



        const fileUploaders = this.generateFileUploaders();

        return (
            <>
                <FullSpinner
                    isSpinning={this.state.isPdfParsing}
                    // message="Processing request..."
                />
                {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                    onClose={() => {
                        this.setState({ isFileViewerModal: false, fileViewerContent: null });
                    }}
                    data={this.state.fileViewerContent}
                    isFinancialDocument={false}
                ></FileViewerModal>}
                <CommonPopUpModal
                    onClose={this.handleModalClose}
                    onSubmit={() => this.handleInvoiceSubmit(isEditInv, this.state.isFormValid)}
                    title={this.props.t("upload_invoice_and_transaction_documents")}
                    primaryBtn={this.props.t("button_names.save_changes")}
                    isSubmitBtn={!this.isButtonEnable()}
                    size="large"
                    promptOnClose={this.state.data ? true : false}
                    children={
                        <div >
                            <div className="row">
                                <div className='col-12 col-md-6 mt-3'>
                                    <legend className="form-label-style required">{this.props.t("file_upload_label.label08")}</legend>
                                    <FileUploader
                                        onFileExtracted={this.handleInvoiceLoaded}
                                        onLoadingError={this.handleLoadingError}
                                        onView={this.handleView}
                                        isEditInv={isEditInv}
                                        convertToJson="invoice"
                                        fileToExtract={this.state.invoiceFile}
                                        supportedExt={invoiceConfig.supportedExt}
                                        //errorMsgType={this.props.t("file_upload_msg.only_pdf_file_msg")}
                                        placeholder={this.props.t("file_upload_msg.invoice_placeholder")}
                                        isdisabled={isEditInv}
                                        isMultiple={false}
                                        isExtraction={true}
                                        customHeight={"12vh"}
                                    />
                                </div>
                                {fileUploaders.map((config) => (
                                    <div key={config.id} className="col-12 col-md-6 mt-3">
                                        <legend className="form-label-style">{config.title}</legend>
                                        <FileUploader
                                            documents={config.documents}
                                            onDocUpload={config.onDocUpload}
                                            onDelete={config.onDelete}
                                            onView={config.onView}
                                            namelength={config.namelength}
                                            supportedExt={config.supportedExt}
                                            //errorMsgType={config.errorMsgType}
                                            errorMsgDuplicate={config.errorMsgDuplicate}
                                            placeholder={config.placeholder}
                                            isdisabled={config.isdisabled}
                                            isMultiple={config.isMultiple}
                                            isExtraction={config.isExtraction}
                                            customHeight={"12vh"}
                                        />
                                    </div>
                                ))}
                            </div>
                            {this.state.data &&
                                <div ref={this.invoiceCustomerDataRef}>
                                <InvoiceCustomerData
                                    onDataUpdate={this.handleDataUpdate}
                                    data={customerDetails}
                                    isFieldDisabled={this.state.isInvitedBuyer}
                                    t={this.props.t}
                                />
                                </div>
                            }
                        </div>
                    }
                >
                </CommonPopUpModal >
            </>
        )
    }
}