import React, { Component } from 'react';
import DashboardWidgetContainer from './DashboardWidgetContainer';
import Chart from "react-apexcharts";
import API from '../../../services/API';
import { dollarFormatter, shortDollarFormatter, localDateFormat } from '../../../services/Utilities';
import { Bar } from 'react-chartjs-2';
import { compareRole ,enumRole} from '../../../configs/GlobalConfig';
import User from '../../../services/User';

 export default class AgingReportWidget extends Component {
    api = new API();
    user =new User();
    chartOptions = {
        maintainAspectRatio: false,
        scales: {
            y: {
                grouped: true,
                ticks: {
                    callback: function (value, index, values) {
                        return shortDollarFormatter.format(value);
                    }
                },
                // title: {
                //     display: true,
                //     text: 'In Dollars',
                //     align: 'center',
                //     color: "#000",
                //     font: {
                //         size: 12,
                //         weight: "bold",
                //     }
                // },
            },
            x:
            {
                grouped: false,
                title: {
                    display: true,
                    text: this.props.t("common_names.days"),
                    align: 'center',
                    color: "#000",
                    font: {
                        size: 12,
                        weight: "bold",
                    }
                },
            },
        },
        onClick: (e, element) => {
            if (element.length > 0) {
                var ind = element[0].index;
                var datasetind = element[0].datasetIndex;
                console.log("index", ind);
                console.log("datasetindex", datasetind);
                this.props.setagingSelectedIndex(ind, datasetind);
            }
        }
    };
    constructor(props_) {
        super(props_);

        this.state = {
            supplierDashboard: {
                outstanding: 0,
                approved: 0,
                outstandingAmount: 0,
                approvedAmount: 0,
                chartData: null,
                reportAsOn: null,
                DSO: 0,
                buyerCount: 0
            },
            invoiceDAO: 0,
            user: this.props && this.props.data?this.props.data:{},
            inventoryOutstanding: 0,
            payableOutstanding: 0,
            cashConversionCycle: 0,
            chartData: null,
            receivableReportAsOn: null,
            payableReportAsOn: null
        };
    }

    componentDidMount() {
        this.getSupplierDashboard();
        this.getDocumentDetails();
        this.getV2Profile();
    }

    async getV2Profile() {
        return await this.api.getV2Profile(this.state.user._id);
    }

    async getDocumentDetails() {
        const invoicesResponse = await this.api.getalldocForDashboard(this.state.user._id);
        let invoices = invoicesResponse.invoices.filter(doc => doc.documentType == "Invoice")
        let invoiceOutstandingAmt = invoices
            .filter(docs => docs.status != 99 && docs.status != 100)
            .reduce((total, docs) => {
                return total + docs.total;
            }, 0);


        const profileResponse = await this.getV2Profile(this.state.user._id);
        let invoiceDAO = (invoiceOutstandingAmt / profileResponse.user.revenue) * 365;
        invoiceDAO = isNaN(invoiceDAO) ? 0 : invoiceDAO;
        this.setState({ invoiceDAO: invoiceDAO, user: profileResponse.user });
    }

    async getSupplierDashboard() {
        const profileResponse = await this.getV2Profile(this.state.user._id);
        if (profileResponse.user.invoiceAgingReport &&
            profileResponse.user.invoiceAgingReport.invoices &&
            profileResponse.user.invoiceAgingReport.invoices.length > 0) {
            this.loadAgingChart(profileResponse);
        }
        else if (profileResponse.user.accountingInformation == "quickbooks") {
            const agingValue = await this.api.getAgingReportFromQBO(this.props.appState.state.currentUser.email);
            if (agingValue) {
                if (agingValue.msg) {
                    this.loadAgingChart(profileResponse);
                }
                else {
                    this.api.submitAgingReportToLF(agingValue)
                        .then(aRes => {
                            this.api.getV2Profile().then(profile => this.loadAgingChart(profile));
                        });
                }
            }
        }
        else if (profileResponse.user.accountingInformation == "erpinfo") {
            this.api.getAgingReportFromERP(this.props.appState.state.currentUser.email)
                .then((res) => {
                    if (res.message) {
                        this.loadAgingChart(profileResponse);
                    }
                    else {
                        this.api.submitAgingReportToLF(res)
                            .then(aRes => {
                                this.api.getV2Profile().then(profile => this.loadAgingChart(profile));
                            });
                    }
                }).catch(ex => {
                    this.loadAgingChart(profileResponse);
                })
        }
        else if (profileResponse.user.accountingInformation == "D365BC") {
            const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(this.props.appState.state.currentUser.email);
            if (agingValue_) {
                if (agingValue_.msg) {
                    this.loadAgingChart(profileResponse);
                }
                else {
                    this.api.submitAgingReportToLF(agingValue_)
                        .then(aRes => {
                            this.api.getV2Profile().then(profile => this.loadAgingChart(profile));
                        });
                }
            }
            else {
                this.loadAgingChart(profileResponse);
            }
        }
        else {
            this.loadAgingChart(profileResponse);
        }
    }

    loadAgingChart = (profileResponse) => {
        let { receivableReportAsOn, payableReportAsOn } = this.state;
        let numOfOpenInvoices, currentDue, due30Days, due45Days, due60Days, due90Days, due120Days, due180Days, due180pluseDays;
        let [payableCurrentDue, payableDue30Days,
            payableDue45Days, payableDue60Days,
            payableDue90Days, payableDue120Days, payableDue180Days, payableDue180pluseDays] = [0, 0, 0, 0, 0, 0, 0, 0];
        let chartDataSets = [];
        numOfOpenInvoices = 0;
        currentDue = 0;
        due30Days = 0;
        due45Days = 0;
        due60Days = 0;
        due90Days = 0;
        due120Days = 0
        due180Days = 0;
        due180pluseDays = 0;
        if (profileResponse.user.invoiceAgingReport &&
            profileResponse.user.invoiceAgingReport.invoices &&
            profileResponse.user.invoiceAgingReport.invoices.length > 0) {
            let dt = new Date(profileResponse.user.invoiceAgingReport.pulledDate);
            receivableReportAsOn = localDateFormat(dt);

            profileResponse.user.invoiceAgingReport.invoices.forEach(invoices => {
                currentDue += parseInt(invoices.currentDue);
                due30Days += parseInt(invoices.due30Days);
                due45Days += parseInt(invoices.due45Days);
                due60Days += parseInt(invoices.due60Days)
                due90Days += parseInt(invoices.due90Days);
                due120Days += parseInt(invoices.due120Days);
                due180Days += parseInt(invoices.due180Days);
                due180pluseDays += parseInt(invoices.due180pluseDays);
            });
    
            due60Days = due45Days + due60Days;
        }
        if (profileResponse.user.payableAgingReport && profileResponse.user.payableAgingReport.Payables &&
            profileResponse.user.payableAgingReport.Payables.length > 0) {
            let dt = new Date(profileResponse.user.payableAgingReport.pulledDate);
            payableReportAsOn = localDateFormat(dt);

            profileResponse.user.payableAgingReport.Payables.forEach(payable => {
                payableCurrentDue += parseInt(payable.currentDue);
                payableDue30Days += parseInt(payable.due30Days);
                payableDue45Days += parseInt(payable.due45Days);
                payableDue60Days += parseInt(payable.due60Days)
                payableDue90Days += parseInt(payable.due90Days);
                payableDue120Days += parseInt(payable.due120Days);
                payableDue180Days += parseInt(payable.due180Days);
                payableDue180pluseDays += parseInt(payable.due180pluseDays);
            });
    
            payableDue60Days = payableDue45Days + payableDue60Days
        }
        if (profileResponse.user.accountingInformation == "erpinfo" || profileResponse.user.accountingInformation == "other") {
            chartDataSets.push(
                {
                    label: this.props.t("dashboard_page.aging_content.type_lables.receivables"),
                    data: [currentDue, due30Days, due60Days, due90Days, due120Days, due180Days, due180pluseDays],
                    backgroundColor: 'rgb(255,180,91)'
                }, {
                label: this.props.t("dashboard_page.aging_content.type_lables.payables"),
                    data: [payableCurrentDue, payableDue30Days, payableDue60Days, payableDue90Days, payableDue120Days, payableDue180Days, payableDue180pluseDays],
                backgroundColor: 'rgb(0, 150, 255)'
            });
        }
        if (profileResponse.user.accountingInformation == "quickbooks" || profileResponse.user.accountingInformation == "D365BC") {
            chartDataSets.push(
                {
                    label:this.props.t("dashboard_page.aging_content.type_lables.receivables"),
                    data: [currentDue, due30Days, due60Days, due90Days, due120Days, due180Days, due180pluseDays],
                    backgroundColor: 'rgb(255,180,91)'
                }, {
                label:this.props.t("dashboard_page.aging_content.type_lables.payables"),
                    data: [payableCurrentDue, payableDue30Days, payableDue60Days, payableDue90Days, payableDue120Days, payableDue180Days, payableDue180pluseDays],
                backgroundColor: 'rgb(0, 150, 255)'
            });
        }
        let chartData = {
            labels: [this.props.t("common_names.current_due"), '1-30', '30-60', '60-90', '90-120', '120-180', '> 180'],
            datasets: chartDataSets,
        }
        this.setState({
            chartData: chartData, receivableReportAsOn: receivableReportAsOn, payableReportAsOn: payableReportAsOn
        });
    }

    render() {
        //const { chartData, reportAsOn} = this.state.supplierDashboard;
        const {
            invoiceDAO,
            user,
            chartData,
            receivableReportAsOn,
            payableReportAsOn
        } = this.state;
        return (
            <DashboardWidgetContainer title={this.props.t("dashboard_page.widget_titles.aging")} size={this.props.isAccountingInfo  && !compareRole(this.user.currentUserRole(), enumRole.Funder)? "col-md-8" : "col-md-12"} isReady={chartData != undefined}>
                {
                    chartData &&
                    <div>
                        <div className="row m-0 p-0">
                            <div className="col-md-6 m-0 p-0">
                                <div className="d-flex">
                                    <div className="d-flex flex-column">
                                        <span className="font-weight-bold">
                                        {this.props.t("dashboard_page.aging_content.dso")}
                                        </span>

                                        <span className="font-weight-bold">
                                        {this.props.t("dashboard_page.aging_content.dio")}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column pl-4">
                                        <span className="link-text-color" style={{ fontWeight: 600 }}>
                                            {/* {user.accountingInformation == "quickbooks" ?
                                                <> */}
                                            {user.KPIs && user.KPIs.dso ? Math.round(user.KPIs.dso) : 0}
                                            {/* </> :
                                                <>{Math.round(invoiceDAO)}</>} */}
                                        </span>
                                        <span className="link-text-color" style={{ fontWeight: 600 }}>
                                            {user.KPIs && user.KPIs.dio ? Math.round(user.KPIs.dio) : 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 m-0 p-0">
                                <div className="d-flex">
                                    <div className="d-flex flex-column">
                                        <span className=" font-weight-bold">
                                            {this.props.t("dashboard_page.aging_content.dpo")}
                                        </span>
                                        <span className=" font-weight-bold">
                                            {this.props.t("dashboard_page.aging_content.ccc")}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column pl-4">
                                        <span className="link-text-color" style={{ fontWeight: 600 }}>
                                            {user.KPIs && user.KPIs.dpo ? Math.round(user.KPIs.dpo) : 0}
                                        </span>
                                        <span className="link-text-color" style={{ fontWeight: 600 }}>
                                            {user.KPIs && user.KPIs.ccc ? Math.round(user.KPIs.ccc) : 0}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mixed-chart">
                            <Bar data={chartData} options={this.chartOptions} height={205} />
                        </div>
                        {receivableReportAsOn && (
                            <div className="small text-center">
                                <strong>{this.props.t("dashboard_page.receivable_report_as_of")}</strong> {receivableReportAsOn}
                            </div>
                        )}
                        {payableReportAsOn && (
                            <div className="small text-center">
                                <strong>{this.props.t("dashboard_page.receivable_report_as_of")}</strong> {payableReportAsOn}
                            </div>
                        )}
                    </div>
                }
            </DashboardWidgetContainer>
        );
    }
}