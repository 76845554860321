import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import { localDateFormat } from "../../../services/Utilities"
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { getFileName } from '../../../services/ConversionService';
import { trans } from '../../../services/localizationService';

const styles = theme => ({
  popover: {
    pointerEvents: 'none',

  }
});

class FileMetaDataPopover extends React.Component {
  state = {
    anchorEl: null,
  };

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes,
      data
    } = this.props;
    console.log(data);
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
          className={"signature-zIndex"}
        >
          <Tooltip arrow>
            <InfoIcon fontSize="small" style={{ color: "#063A52", cursor: "pointer" }} />
          </Tooltip>
        </Typography>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>
            <div className='signature-tooltip'>
              {(data.filename || data.fileName) && (
                <>
                  <div style={{ fontWeight: "bold" }}>
                    {trans("common_names.fileName")}
                  </div>
                  <div>
                    {getFileName(data.filename,data.fileName)}
                  </div>
                </>
              )}
              {(data.createdDate || data.uploadDate) && (
                <>
                  <div style={{ fontWeight: "bold" }}>
                  {trans("common_names.upload_date")}
                  </div>
                  <div>
                   {localDateFormat(new Date(data.createdDate?data.createdDate:data.uploadDate))}
                  </div>
                </>
              )}
              {(data.userDetails && data.userDetails.name) && (
                <>
                  <div style={{ fontWeight: "bold" }}>
                  {trans("common_names.uploaded_by")}
                  </div>
                  <div>
                    {data.isUploadedByRepresentative?data.userDetails.representativeName?`${data.userDetails.representativeName} ${trans("on_behalf_of")} ${data.userDetails.name}`:data.userDetails.name:data.userDetails.name}
                  </div>
                </>
              )}
            </div>
          </Typography>
        </Popover>
      </div>
    );
  }
}

FileMetaDataPopover.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(FileMetaDataPopover);
