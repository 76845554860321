import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import AppIcon from "../../../assets/icons/ledgerfunding-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBell,
    faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import AppState from "../../../contexts/AppState/AppState";
import API, {
    API_ENDPOINT_SUPPLIER,
    API_ENDPOINT_BUYER,
    API_ENDPOINT_FUNDER,
    V2_API_ENDPOINT_SUPPLIER,
    V2_API_ENDPOINT_BUYER,
    V2_API_ENDPOINT_FUNDER
  } from "../../../services/API";
import User from "../../../services/User";
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import TranslateIcon from '@mui/icons-material/Translate';
import CommonConfirmationModal, { AddModal } from "..//Modals/CommonConfirmationModal";
import USER from "../../../services/User";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from "react-i18next";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { supported_languages } from "../../../services/languages";
import { changeLanguage } from "../../../services/localizationService";
import { compareRole, enumRole } from "../../../configs/GlobalConfig";
import { compose } from "recompose";

class Header extends Component {
    api = new API();
    user = new User();
    _timer;
    _jobUUID;
    _notificationTimer;

    constructor(props_) {
        super(props_);
        this.state = {
            userName: "",
            isGlobalSync: false,
            notificationCount: 0,
            isTranslatePopover: false,
            anchorEl: null,
            userDetails: {},
            managedUsers: [],
            selectedManagedUser: 0
        };
    }
    componentDidMount() {
        this.getProfile();
        this.getSyncStatus();
        this.getNotificationCount();
    }

    async notify(message) {
        toast.info(message)
    }

    handlePopoverOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handlePopoverClose = () => {
        this.setState({ anchorEl: null });
    };

    async getProfile() {
        try {
            if (this.user.currentUserRole() != 'admin') {
                const profileResponse = await this.api.getProfile();
                let _userDetails = profileResponse.user;
                let _managedUsers = _userDetails && _userDetails.managedUsers && _userDetails.managedUsers.length > 0 ? _userDetails.managedUsers : [];
                let _localStorageManageUsers=localStorage.getItem("managedUsers");
                const _localStorageSelectedManageUser=localStorage.getItem("selectedManagedUser");
                _localStorageManageUsers=_localStorageManageUsers?JSON.parse(_localStorageManageUsers):_localStorageManageUsers;
                if(_localStorageManageUsers && _localStorageManageUsers.length>0){
                    this.setState(
                        {
                            userName:_localStorageManageUsers[0] && _localStorageManageUsers[0].name?_localStorageManageUsers[0].name: _userDetails.name,
                            userDetails: _userDetails,
                            managedUsers: _localStorageManageUsers,
                            selectedManagedUser: _localStorageSelectedManageUser
                        });
                }
                else{
                    if (_managedUsers.length > 0) {
                        let _defaultUser = { _id: _userDetails._id, name: _userDetails.name, role: _userDetails.role,isAdmin:true };
                        _managedUsers.unshift(_defaultUser);
                        localStorage.setItem("managedUsers",JSON.stringify(_managedUsers));
                        localStorage.setItem("selectedManagedUser",this.state.selectedManagedUser);
                    }
                    this.setState({ userName: _userDetails.name, userDetails: _userDetails, managedUsers: _managedUsers });
                }
            }
            else {
                this.getAdminProfile();
            }

        } catch (error) {
            AddModal(this.props.t("unauthorized_title"), this.props.t("session_expired"), null, false);
            setTimeout(() => {
                window.location.href = `/${this.user.currentUserRole()}`;
                this.user.logOut();
            }, 2000);
            console.error(error);
            this.setState({ userName: "User" });
        }
    }

    getAdminProfile() {

        fetch(this.api.currentUserEndpoint() + '/profile', {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Basic ' + this.user.currentUser()['token'],
                "content-type": "application/json",
                "cache-control": "no-cache",
                "access-control-allow-credentials": true,
                "access-control-allow-origin": `${process.env.REACT_APP_UI_URL}`
            }),
            credentials: "include"
        }).then((result) => {
            return result.json()
        }).then((userdetail) => {
            this.setState({ userName: userdetail.name ? userdetail.name : "Admin" })
        })
    }

    getSyncStatus = async () => {
        this._timer = setInterval(async () => {
            const jobID = localStorage.getItem("BackGround_Job_ID");
            if (jobID) {
                this.setState({ isGlobalSync: true });
                const getJobStatusByKey = await this.api.getJobStatusByKey(jobID);
                if (getJobStatusByKey.message == "Done") {
                    this.setState({ isGlobalSync: false });
                    localStorage.removeItem("BackGround_Job_ID");
                    let isUIJOBValidate = localStorage.getItem("UI_JOB");
                    localStorage.removeItem("UI_JOB");
                    if (isUIJOBValidate == "true") {
                        this.notify(this.props.t("global_sync_done"))
                    }
                    // clearInterval(this._timer);
                } else {
                    console.log("Failed to remove sync msg");
                }
            }
        }, 6000);
    }

    getNotificationCount = async () => {
        if (this.user.currentUserRole() != 'admin') {
            this._notificationTimer = setInterval(async () => {
                const response = await this.api.getUnreadNotificationsCount();
                if (response && (response.count == 0 || response.count > 0)) {
                    this.setState({ notificationCount: response.count });
                }
            }, 6000);
        }
    }

    changeManageUsers = async (e) => {
        this.setState({ selectedManagedUser: e.target.value },async()=>{
            if(this.state.managedUsers.length>0 ){
                let _user=this.state.managedUsers[e.target.value];
                localStorage.setItem("selectedManagedUser",e.target.value);
                const _switchedUserDetails=await this.api.changeManagedUserAccount(_user);
                if (_switchedUserDetails.verified) {
                    if(_user.isAdmin){
                        let _existingAdminUser=localStorage.getItem("LEDGER_FUNDING_ADMIN_USER");
                        localStorage.setItem("LEDGER_FUNDING_CURRENT_USER",_existingAdminUser);
                        localStorage.removeItem("LEDGER_FUNDING_ADMIN_USER");
                    }
                    else{
                       let _isExistingAdminData= localStorage.getItem("LEDGER_FUNDING_ADMIN_USER");
                        if (!_isExistingAdminData) {
                            let _adminUser=localStorage.getItem("LEDGER_FUNDING_CURRENT_USER");
                            localStorage.setItem("LEDGER_FUNDING_ADMIN_USER", _adminUser);
                        }
                        localStorage.setItem("LEDGER_FUNDING_CURRENT_USER", JSON.stringify(_switchedUserDetails));
                    }
                    if(compareRole(_user.role,enumRole.Supplier)){
                        this.user.setCurrentUserRole(enumRole.Supplier);
                        this.api.setCurrentUserEndpoint(API_ENDPOINT_SUPPLIER);
                        this.api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_SUPPLIER);
                        this.props.history.push("/dashboard");
                        window.location.reload();
                    }
                    else if(compareRole(_user.role,enumRole.Buyer)){
                        this.user.setCurrentUserRole(enumRole.Buyer);
                        this.api.setCurrentUserEndpoint(API_ENDPOINT_BUYER);
                        this.api.setV2CurrentUserEndpoint(V2_API_ENDPOINT_BUYER);
                        this.props.history.push("/document");
                        window.location.reload();
                    }
                }

            }
        });
    }


    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <AppState.Consumer>
                {appState => (
                    <>
                        <nav className="navbar sticky-top navbar-expand-lg p-2 header-navbar">
                            <a className="navbar-brand" href="#">
                                <img src={AppIcon} alt="LedgerFunding Logo" style={{ position: "absolute", width: 160, height: 28.94, left: 18, top: 13 }} />
                            </a>
                            <ul className="navbar-nav ml-auto">
                                <li>
                                    <span className="navbar-text ml-auto d-flex justify-content-center pr-2" id="syn-glo-sys" style={{
                                        color: "white", fontWeight: 600, fontFamily: 'Cairo',
                                        fontStyle: 'normal', fontSize: 14
                                    }}>
                                        {this.state.isGlobalSync && (
                                            <>
                                                {this.props.t("erp_syncing_msg")} &nbsp;
                                                <svg className="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
                                                    <path className="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z" />
                                                    <path className="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z" />
                                                </svg>
                                                &nbsp;
                                            </>
                                        )}
                                        {`${this.props.t("welcome_msg")}, ${this.state.userName} ${this.state.managedUsers && this.state.managedUsers.length > 0 ? this.props.t("you_are_currently_accessing") : ""}`}
                                    </span>
                                </li>
                                <li>
                                    {this.state.managedUsers && this.state.managedUsers.length > 0 && (
                                        <select
                                            className="form-control"
                                            value={this.state.selectedManagedUser}
                                            id="managedUsers"
                                            name="managedUser"
                                            onChange={(e) => this.changeManageUsers(e)}
                                        >
                                            {this.state.managedUsers.map((u_, i) => (
                                                <option key={u_._id} value={i}>
                                                    {i > 0 ? `${u_.name} (${this.props.t("managed_business")})` : u_.name}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </li>
                                {this.user.currentUserRole() != 'admin' && (
                                    <>
                                        <li className="nav-item d-flex justify-content-center">
                                            <NavLink
                                                className="nav-link"
                                                to="/notifications"
                                                activeClassName="active"
                                            >
                                                {/* {!appState.state.isNotificationsNotRead &&
                                        appState.state.unreadNotificationsCount > 0 ? (
                                            <Badge badgeContent={appState.state.unreadNotificationsCount} color="secondary">
                                            <NotificationsIcon fontSize={"small"} />
                                          </Badge>
                                    ) : (
                                        <FontAwesomeIcon icon={faBell} />
                                    )} */}
                                                {this.state.notificationCount > 0 ? (
                                                    <Badge badgeContent={this.state.notificationCount} color="error">
                                                        <NotificationsIcon fontSize={"small"} />
                                                    </Badge>
                                                ) : (
                                                    <FontAwesomeIcon icon={faBell} />
                                                )}
                                            </NavLink>
                                        </li>
                                        <li className="nav-item d-flex justify-content-center" style={{ color: "white" }} >
                                            <TranslateIcon aria-describedby={id} onClick={this.handlePopoverOpen} fontSize={"small"} className="mt-2 mx-1" style={{ cursor: "pointer" }} />
                                            <Popover
                                                id={id}
                                                open={open}
                                                anchorEl={anchorEl}
                                                onClose={this.handlePopoverClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                disableRestoreFocus
                                            >
                                                {supported_languages.map((lang_, i) => (

                                                    <Typography key={lang_.code} sx={{ p: 1 }} style={{ cursor: "pointer" }} className={localStorage.getItem("i18nextLng") == lang_.code ? "hightLight_selected_lang" : ((localStorage.getItem("i18nextLng") == "en-US") && ("en" == lang_.code)) ? "hightLight_selected_lang" : ""} onClick={(e) => {

                                                        changeLanguage(lang_.code);
                                                        this.setState({ anchorEl: null }, () => {
                                                            this.api.updateLanguageForAllUser(lang_.code);
                                                            window.location.reload();
                                                        });
                                                    }}>{lang_.name}</Typography>
                                                ))}
                                            </Popover>
                                        </li>
                                        <li className="nav-item d-flex justify-content-center" style={{ color: "white" }}>
                                            <NavLink
                                                className="nav-link"
                                                to="/profile"
                                                activeClassName="active"
                                            >
                                                <FontAwesomeIcon className="fa-lg" icon={faUserCircle} />
                                            </NavLink>
                                        </li>
                                    </>
                                )}

                            </ul>
                        </nav>
                        <ToastContainer />
                    </>
                )}
            </AppState.Consumer>
        )
    }
}

export default compose(withRouter, withTranslation())(Header)