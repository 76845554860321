
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
} from 'react-router-dom';
import './Login.css';

import Spinner from '../Spinner/Spinner';
import Logo from '../../../assets/icons/newlogo.png';
import AppState from '../../../contexts/AppState/AppState';
import AppIcon from "../../../assets/icons/app_icon.png";
import { validateEmail } from '../../../services/Utilities';
import User from '../../../services/User';
import Popup from './Popup';
import pic1 from '../../../../src/assets/images/tax-credit.jpg';
import pic2 from '../../../../src/assets/images/Income-Factoring.png';
import FullSpinner from '../Spinner/FullSpinner';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { GoogleReCaptchaProvider, GoogleReCaptcha, } from 'react-google-recaptcha-v3';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { supported_languages } from '../../../services/languages';
import { changeLanguage } from '../../../services/localizationService';
import TranslateIcon from '@mui/icons-material/Translate';
import { AddModal } from '../Modals/CommonConfirmationModal';
import {ChooseRoleModal} from "../Modals/ChooseRoleModal";

const captchaRef = React.createRef();

class Login extends Component {
  user = new User();
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isPopupOpen: false,
      email: '',
      password: '',
      isShowPassword: false,
      recaptchaToken: "",
      isAllowedCountryForCaptcha:true,
      isChooseUserRole:false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }
  togglePopup() {
    this.setState({
      isPopupOpen: !this.state.isPopupOpen,
    });
  }
  actionLf = () => {
    window.location.href = '/dashboard';
  };
  actionCF = () => {
    alert("under development");
  };

  componentDidMount (){
    if(this.user.currentUserRole()){
      this.checkAllowedCountriesForCaptacha();
    }
    else{
      this.setState({isChooseUserRole:true});
    }
  }

  checkAllowedCountriesForCaptacha = async () => {
    let _res = await fetch('https://www.cloudflare.com/cdn-cgi/trace');
    _res = await _res.text();
    const _ipLocation = _res.match(/loc\=([A-Z.]*)/)[1];
    if (process.env.REACT_APP_NOT_ALLOWED_COUNTRIES.split(",").indexOf(_ipLocation)!=-1) {
      this.setState({isAllowedCountryForCaptcha : false});
    }
  }

  //Log Hcaptcha expire
  onExpireHcaptcha = () => {
    console.log("hCaptcha Token Expired");
  };
 
  //Log Hcaptcha error
  onErrorHcaptcha = (err) => {
    console.log(`hCaptcha Error: ${err}`);
  };

  //Set Htcaptcha verification token
  handleOnVerifyHcaptcha=(token,key)=>{
   this.setState({recaptchaToken:token});
  }

  async handleLogin() {
    //captchaRef.current.execute({async:true});
    const { email, password, recaptchaToken,isAllowedCountryForCaptcha } = this.state;
    if (recaptchaToken.length > 0 ||(!isAllowedCountryForCaptcha)) {
      this.setState({ isLoading: true });
      const loginResponse = await this.user.logIn({
        email,
        password,
      });
      if (!loginResponse.verified) {
        this.setState({
          isLoading: false,
          error: loginResponse.message,
        });
      }
      else {
        if (this.user.currentUserRole() == 'admin') {
          window.location.href = '/userList';
          this.setState({ isLoading: true });
        }
        else if (loginResponse.status === 0) {
          this.setState({
            isLoading: false,
            error: `${this.props.t("account_not_approved_msg")}!`,
          });
        } else {
          this.setState({
            isLoading: false,
            error: loginResponse.message,
          });
          changeLanguage(loginResponse.language?loginResponse.language:"en");
          if (this.user.currentUserRole() === "supplier") {
            this.user.setCurrentUserType("InvoiceFinancing");
            localStorage.setItem("BackGround_Job_ID", loginResponse.backgroundJobId);
            return (window.location.href = "/dashboard");
          }
          else if (this.user.currentUserRole() === "buyer") {
            this.user.setCurrentUserType("InvoiceFinancing");
            return (window.location.href = "/document");
          }
          else {
            return window.location.href = '/dashboard';
          }
        }
      }

    }
    else {
      throw new Error("Recaptcha token not found");
    }
  }

  handlePopoverOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  static renderLoadingView(isLoading) {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="row d-flex justify-content-center align-items-center loading-wrapper">
              <FullSpinner
                isSpinning={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoginView() {
    const { history } = this.props;
    const {t}=this.props;
    const {
      email, password, error, isPopupOpen, togglePopup
    } = this.state;
    const isEnabled = email.length > 0 && validateEmail(email) && password.length > 0;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <AppState.Consumer>
        {appState => (
          <>
            {/* <div className="home-4__bg"></div> */}
            <div className="container-fluid login-wrapper">
              <FullSpinner
                isSpinning={this.state.isLoading}
              />
              {this.state.isChooseUserRole && (
                <ChooseRoleModal onClose={(role_) => {
                  this.setState({ isChooseUserRole: false });
                  history.push(`/${role_}`);
                  window.location.reload();
                }} />
              )}
              <img className="signup-logo" src={Logo} alt="LedgerFunding logo" />
              <div className="row">
                <div className="signup-col-width">
                  <div className="row d-flex flex-column justify-content-center align-items-center h-100">
                    <div className="signup-form-width">
                      {/* <div className="login-form shadow-lg card mt-5"> */}
                      <div className="text-center mt-5">
                        <h3 className="title-form-page">
                          {t("login_resources.title")}
                        </h3>
                      </div>
                      <form className="text-center p-4">
                        <small className="form-text whiteText text-left">
                          {t("signup_labels.email_address")}
                        </small>
                        <input type="email" id="inputEmail" className="form-control mt-2 mb-4" onChange={e => this.setState({ email: e.target.value })} placeholder={t("signup_labels.email_address")} required />
                        <small className="form-text whiteText text-left">
                        {t("signup_labels.password")}
                        </small>
                        <input
                          type={this.state.isShowPassword ? "text" : "password"}
                          id="inputPassword" className="form-control mb-4 mt-2" onChange={e => this.setState({ password: e.target.value })} placeholder={t("signup_labels.password")} required />
                        <div style={{ float: "right", marginTop: "-55px", zIndex: 9999 }} onClick={() => {
                          this.setState({ isShowPassword: !this.state.isShowPassword });
                        }}>
                          <span className="input-group-text eye-icon">
                            {this.state.isShowPassword ? <VisibilityOff /> : <Visibility />}
                          </span>
                        </div>
                        {this.state.isAllowedCountryForCaptcha ? (<>
                          <GoogleReCaptchaProvider
                            reCaptchaKey="6Lf1vkMdAAAAAIh2hUwKgFOCLv23ShnmClsFea-j"
                          >
                            <GoogleReCaptcha onVerify={(t_) => {
                              if (t_) {
                                this.setState({ recaptchaToken: t_ });
                              }
                            }} />
                          </GoogleReCaptchaProvider>
                        </>) : (
                          <>
                            <HCaptcha
                              id="hcaptcha-size"
                              sitekey="4579b879-7dd5-4168-9134-431374078c8d"
                              //size="invisible"
                              ref={captchaRef}
                              onError={this.onErrorHcaptcha}
                              onExpire={this.onExpireHcaptcha}
                              onVerify={this.handleOnVerifyHcaptcha}
                            />
                          </>
                        )}

                        <button className="btn btn-primary btn-lg btn-block mt-4 mb-3 text-uppercase" type="submit" onClick={this.handleLogin} disabled={!isEnabled}>
                          {t("button_names.login_in")}
                        </button>
                        {
                          appState.state.currentUserRole !== "admin" &&
                          <React.Fragment>
                            {/* <GoogleReCaptchaProvider reCaptchaKey="[6LewGMkhAAAAAGO9rfWABPLj-ZkkeW7pVKl9Re21]">
                                <GoogleReCaptcha  />
                              </GoogleReCaptchaProvider> */}

                            <button
                              className="btn white-text-border btn-lg btn-block mt-2 text-uppercase"
                              type="submit"
                              onClick={() => history.push("/register")}
                            >
                              {t("button_names.sign_up")}
                            </button>
                            <br/>


                            <button type="button" className="btn whiteText mt-2" onClick={() => history.push('/forgot')}>
                            {t("button_names.forgot_password")}?
                            </button>
                          </React.Fragment>
                        }
                      </form>

                      {/* </div> */}
                      {appState.state.currentUserRole == "supplier" && (
                        <div className="terms-checkbox-container">
                          {isPopupOpen && <Popup
                            content={<>
                              <div>
                                <div>
                                  <label className="popup-close-icon" onClick={e => this.togglePopup()}></label>
                                </div>
                                <img className="popup-lefticon" src={pic1} onClick={this.actionCF} />
                                <img className="popup-righticon" src={pic2} onClick={this.actionLf} />
                                <label className="label-taxCredit">Tax Credit Financing</label>
                                <label className="label-InvoiceFactoring">Invoice Factoring</label>
                              </div>
                            </>}
                            handleClose={e => togglePopup()}
                          />}
                        </div>
                      )}
                      {appState.state.currentUserRole == "funder" && (
                        <div className="terms-checkbox-container">
                          {isPopupOpen && <Popup
                            content={<>
                              <div>
                                <div>
                                  <label className="popup-close-icon" onClick={e => this.togglePopup()}></label>
                                </div>
                                <img className="popup-lefticon" src={pic1} onClick={this.actionCF} />
                                <img className="popup-righticon" src={pic2} onClick={this.actionLf} />
                                <label className="label-taxCredit">Tax Credit Financing</label>
                                <label className="label-InvoiceFactoring">Invoice Factoring</label>
                              </div>
                            </>}
                            handleClose={e => togglePopup()}
                          />}
                        </div>
                      )}
                      {error && <Alert errorMessage={error} />}
                    </div>
                    <div className='copyright-text'>
                      <p className="mt-2 mb-3 whiteText">
                        &copy; {t("login_resources.copyright")}{" "}{new Date().getFullYear()}. {t("login_resources.rights")}.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="signup-col-width cover-image login-image d-flex justify-content-center align-items-center" >
                  <button aria-describedby={id} onClick={this.handlePopoverOpen} type="button" class="btn mt-2 translate_icon" style={{backgroundColor:"white",zIndex:9999}}>
                    <TranslateIcon  fontSize={"large"} style={{color:'#255F79'}} />
                  </button>
                 
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    disableRestoreFocus
                    style={{zIndex:"9999"}}
                  >
                    {supported_languages.map((lang_, i) => (

                      <Typography key={lang_.code} sx={{ p: 1 }} style={{ cursor: "pointer" }} onClick={(e) => {
                        changeLanguage(lang_.code);
                        this.setState({ anchorEl: null });
                      }}>{lang_.name}</Typography>
                    ))}
                  </Popover>
                </div>
              </div>
            </div>
          </>
        )}
      </AppState.Consumer>
    );
  }

  render() {
    const { isLoading } = this.state;
    return isLoading ? Login.renderLoadingView(isLoading) : this.renderLoginView();
  }
}

export default compose(
  withRouter,
  withTranslation()
)(Login)

const Alert = ({ errorMessage }) => (
  <div className="alert text-center alert-danger mt-2" role="alert">
    {errorMessage}
  </div>
);

Alert.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};
