import React, { Component } from 'react'
import Popup from '../../common/Auth/Popup';
import { API_ENDPOINT_SUPPLIER } from '../../../services/API';
import User from '../../../services/User';
import { dollarFormatter, getIndustryWithId, getEntity, dateFormatter, cslDataPreprocessor, fileStatusBadgeText } from '../../../services/Utilities';
import API from '../../../services/API';
import EnumFundingRequestStatus from './EnumFundingRequestStatus';
import FullSpinner from '../../common/Spinner/FullSpinner';
import { ShortText, localDateFormat } from '../../../services/Utilities';

import JSZip from "jszip";
import { saveAs } from 'file-saver';
import SigningStatusModal from '../FundingContract/SigningStatusModal';
import ViewCslDataModal from '../../common/Modals/ViewCslDataModal';
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import { Stepper, Step, StepLabel, StepIcon } from '@mui/material';
import UserDetails from "../../common/User/UserDetails";
import QuestionnaireFundingDetails from "../../common/FundingDetails/QuestionnaireFundingDetails";
import FundingOfferDetails from "../../common/FundingDetails/FundingOfferDetails";
import UploadSupplyChainModal from '../Documents/SupplyChain/UploadSupplyChainModal';
import { transactionType,compareTransactionType } from '../../../configs/GlobalConfig';
import { AddModal } from '../../common/Modals/CommonConfirmationModal';
import FinancialInformation from '../../common/Profile/FinancialInformation';
import NewDashboard from '../../common/Dashboard/NewDashboard';
import { convertUTF8String } from '../../../services/ConversionService';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import FileMetaDataPopover from '../../common/PopOver/FileMetaDataPopover';


var _salesContractLabel = "sales_contract";
var _shippingDocumentLabel= "shipping_documents";
var _paymentConfirmationLabel= "payment_confirmation";

export default class ViewFudingRequestModal extends Component {
    user = new User();
    viewData = {};
    api = new API();
    middeskResponse = {};

    constructor(props_) {
        super(props_);
        if (props_.data.cslData && props_.data.cslData.cslData) {
            cslDataPreprocessor(props_.data.cslData.cslData);
        }

        this.state = {
            activeStep: 0,
            supplierFincicalDocResponse: [],
            customerDocuments: {},
            agingReport: false,
            isUpdatedMiddesk: false,
            latestMiddeskResponse: {},
            bankTransactionHistory: {},
            isAgingfile: true,
            isBankStatement: true,
            isRecievablesDetailsAgingReport: false,
            isPayableDetailsAgingReport: false,
            payDetailAgingReport: {},
            recDetailAgingReport: {},
            payableAgingReport: {},
            isPayableAgingReport: true,
            isSigningPopup: false,
            signingContractValue: null,
            supplierFinancialAddDocs: [],
            isMiddesk: props_.data && props_.data.midDesk && props_.data.midDesk.response ? true : false,
            isCSLDone: props_.data && props_.data.cslData && props_.data.cslData.cslData ? true : false,
            isCSLHasData: props_.data && props_.data.cslData && props_.data.cslData.cslData && props_.data.cslData.cslData.results && props_.data.cslData.cslData.results.length ? true : false,
            receivableAgingReportData: {},
            payableAgingReportData: {},
            isViewCslModal: false,
            cslDetails: null,
            isFinancialDocument: false,
            isFileViewerModal: false,
            fileViewerContent: null,
            isViewSupplyChain:false,
            viewDocumentData:null,
            tradingPartnerLists:props_.data && props_.data.tradingPartnerLists?props_.data.tradingPartnerLists:[],
            isTermSheetAvailable:false,
            preFundingDocs:{}
        };
        this.viewData = props_.data;
        this.middeskResponse = props_.data.midDesk.response ? this.parseMidDeskResponse(props_.data.midDesk.response) : "";
    }

    componentDidMount() {
        let fundingRequest = this.props.data;
        if(this.props.data.isPreFundingRequest){
            this.getPreFundingDocs();
        }
        if (fundingRequest.fundingDocuments && fundingRequest.fundingDocuments.length>0) {
            const customersDocs = fundingRequest.fundingDocuments.reduce((cus, docs) => {
                let customerName = docs.customerDetails ? docs.customerDetails.name : "";
                if (!cus[customerName]) {
                    cus[customerName] = [];
                }
                cus[customerName].push(docs);

                if (docs.customerDetails && docs.customerDetails.cslData && docs.customerDetails.cslData.cslData) {
                    cslDataPreprocessor(docs.customerDetails.cslData.cslData);
                }
                return cus;
            }, {});
            this.setState({ customerDocuments: customersDocs }, () => this.getFinacialDocumentationForBuyer());
            this.getFinacialDocumentation();
            if (this.state.isMiddesk) {
                this.getLatestMiddeskResponse();
            }
            if (this.props.data.requesterDetails.accountingInformation) {
                if (this.props.data.requesterDetails.accountingInformation == "quickbooks") {
                    this.getSupplierbanktransactionhistory();
                    this.getReceivableDetailsAgingReport();
                    this.getPayableDetailsAgingReport();
                    this.getPayablesAgingReport();
                }
                else if (this.props.data.requesterDetails.accountingInformation == "D365BC") {
                    this.getReceivableDetailsAgingReport();
                    this.getPayableDetailsAgingReport();
                    this.getPayablesAgingReport();
                }
            }
        }
        else if(fundingRequest.tradingPartnerLists && fundingRequest.tradingPartnerLists.length>0){
            const {tradingPartnerLists}=this.props.data;
            tradingPartnerLists.map(tr_=>{
                if (tr_.cslData && tr_.cslData.cslData) {
                    cslDataPreprocessor(tr_.cslData.cslData);
                }
                return tr_;
            });
            this.setState({tradingPartnerLists});
        }
    }

    getPreFundingDocs=async()=>{
        if(this.props && this.props.data && this.props.data.requesterDetails){
            let res = await this.api.getPreFundingCommonDocsForFunder(this.props.data.fundingReqId,this.props.data.requesterDetails._id);
            if(res && res.items && res.items.length>0){
                let _docs1= res.items.filter(x => x.financialDocumentType === "FINANCING_APPLICATION");
                let _docs2= res.items.filter(x => x.financialDocumentType === "LAST_TWO_YEAR_FINANCIAL_STATEMENTS");
                let _docs3= res.items.filter(x => x.financialDocumentType === "AGING_REPORTS");
                let _docs4= res.items.filter(x => x.financialDocumentType === "ARTICLE_OF_INCORPORATION");
                let _docs5= res.items.filter(x => x.financialDocumentType === "COPY_OF_PRINCIPAL_ID");
                let _docs6= res.items.filter(x => x.financialDocumentType === "PAYMENT_HISTORY");
                let _docs7= res.items.filter(x => x.financialDocumentType === "SALES_PROJECTION");
                let _docs8= res.items.filter(x => x.financialDocumentType === "OTHER_PRE_FUNDING_DOCUMENTS");
                this.setState({
                    preFundingDocs: {
                        "FINANCING_APPLICATION": _docs1,
                        "LAST_TWO_YEAR_FINANCIAL_STATEMENTS": _docs2,
                        "AGING_REPORTS": _docs3,
                        "ARTICLE_OF_INCORPORATION": _docs4,
                        "COPY_OF_PRINCIPAL_ID": _docs5,
                        "PAYMENT_HISTORY": _docs6,
                        "SALES_PROJECTION": _docs7,
                        "OTHER_PRE_FUNDING_DOCUMENTS": _docs8
                    }
                });
            }
        }
    }

    async getLatestMiddeskResponse() {
        this.setState({ isUpdatedMiddesk: true });
        const midddesk = await this.api.getLatestMiddeskResponse(this.props.data.midDesk.response.id);
        this.setState({ latestMiddeskResponse: midddesk, isUpdatedMiddesk: false })
    }



    getBadges(scope_) {
        return scope_.tags.map(t =>
            <span className={"verfication-badge badge " + (scope_.verified ? "badge-success" : "badge-danger")}>{t}</span>
        );
    }

    parseMidDeskResponse(obj) {
        let verificationSummary = {
            name: {
                verified: false,
                tags: []
            },
            address: {
                verified: false,
                tags: []
            },
            tin: {
                verified: false,
                tags: []
            },
            phone: {
                verified: false,
                tags: []
            },
            extra: [
                {
                    "type": "People",
                    details: []
                },
                {
                    "type": "Website Address",
                    details: []
                },
                {
                    "type": "Profile",
                    details: []
                },
                {
                    "type": "Watch List",
                    details: []
                },
                {
                    "type": "Other Verifications",
                    details: []
                },
                {
                    "type": "Names",
                    details: []
                }
            ]
        };
        if (obj.website && obj.website.business_id) {
            let websiteDetails = {
                url: obj.website.url,
                status: obj.website.status,
                title: obj.website.title,
                description: obj.website.description,
                domain: obj.website.domain,
                // domainCreatedDate:obj.website.domain && obj.website.domain.creation_date,
                // domainExpirationDate:obj.website.domain && obj.website.domain.expiration_date,
                businessNameMatch: obj.website.business_name_match
            }
            verificationSummary.extra[1].details.push(websiteDetails);
        }
        for (let reviewTask of obj.review.tasks) {
            switch (reviewTask.key) {
                case "name":
                    verificationSummary.name.verified = reviewTask.status == "success";
                    verificationSummary.name.tags.push(reviewTask.sub_label);
                    break;
                case "address_verification":
                    verificationSummary.address.verified = reviewTask.status == "success";
                    verificationSummary.address.tags.push(reviewTask.sub_label);
                    break;
                case "address_property_type":
                    verificationSummary.address.tags.push(reviewTask.sub_label);
                    break;
                case "tin":
                    verificationSummary.tin.verified = reviewTask.status == "success";
                    verificationSummary.tin.tags.push(reviewTask.sub_label);
                    break;
                case "phone":
                    verificationSummary.phone.verified = reviewTask.status == "success";
                    verificationSummary.phone.tags.push(reviewTask.sub_label);
                default:
                    verificationSummary.extra[4].details.push({
                        "label": reviewTask.label,
                        "message": reviewTask.message
                    });
            }
        };

        for (let watchlist of obj.watchlist.lists) {
            verificationSummary.extra[3].details.push({
                "label": watchlist.agency,
                "message": (watchlist.results && watchlist.results.length) ? watchlist.results.join("\n") : "No entries found"
            });
        }

        for (let registration of obj.registrations) {
            let details = [];

            details.push({
                "label": "Status",
                "message": registration.status
            });
            details.push({
                "label": "Jurisdiction",
                "message": registration.jurisdiction
            });
            details.push({
                "label": "Entity Type",
                "message": registration.entity_type
            });
            details.push({
                "label": "Registration Date",
                "message": dateFormatter.format(new Date(registration.registration_date))
            });
            details.push({
                "label": "Registered State",
                "message": registration.state
            });
            details.push({
                "label": "Registration Officers",
                "message": registration.officers.length > 0 ? registration.officers : "No Officers found",
            });
            details.push({
                "label": "Registration Addresses",
                "message": registration.addresses,
            });

            verificationSummary.extra.splice(0, 0, {
                "type": `Registration Details of "${registration.name}"`,
                details: details
            });
        }
        return verificationSummary;
    }

    cslDetailsClick = async (e, cslDetails_) => {
        e.preventDefault();
        this.setState({ isViewCslModal: true, cslDetails: cslDetails_.cslData });
    }

    FileViewerModalClick = (e, file_) => {
        e.preventDefault();
        this.setState({ isFileViewerModal: true, isFinancialDocument: false, fileViewerContent: file_ });
    }


    handleNext = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState((prevState) => ({
            activeStep: prevState.activeStep - 1,
        }));
    };

    handleStepper = (value) => {
        this.setState({ activeStep: value });
    }

    async getFinacialDocumentation() {
        const res = await this.api.getFunderSupplierDocs(this.props.data.requesterDetails._id);
        let isAging = true;
        let isbankStmt = true;
        let isPayableAging = true;
        let aging = res.items.filter(
            x => x.financialDocumentType === "AGING_REPORT"
        );
        if (aging.length == 0) {

            isAging = false;
        }
        let payableAging = res.items.filter(
            x => x.financialDocumentType === "PAYABLE_AGING_REPORT"
        );
        if (payableAging.length == 0) {
            const accountingType_ = this.props.data.requesterDetails.accountingInformation;
            if (accountingType_ == "quickbooks" && accountingType_ == "D365BC") {
                isPayableAging = false;
            }
        }
        let bankStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
        if (bankStatement.length == 0) {
            isbankStmt = false;
        }
        let financialAdditionalDocs = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
        res.items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() != "additionaldocument");
        this.setState({
            supplierFincicalDocResponse: res.items ? res.items : 0,
            isBankStatement: isbankStmt,
            isAgingfile: isAging,
            isPayableAgingReport: isPayableAging,
            supplierFinancialAddDocs: financialAdditionalDocs
        }, async () => {
            if (!this.state.isAgingfile) {
                if (this.props.data && this.props.data.requesterDetails && this.props.data.requesterDetails.email) {
                    let agingResponse;
                    const accountingType_ = this.props.data.requesterDetails.accountingInformation;
                    const email = this.props.data.requesterDetails.email;
                    if (accountingType_ == "quickbooks") {
                        const agingValue = await this.api.getAgingReportFromQBO(email);
                        if (agingValue && !agingValue.msg) {
                            agingResponse = agingValue;
                        }
                    }
                    else if (accountingType_ == "D365BC") {
                        const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
                        if (agingValue_ && !agingValue_.msg) {
                            if (agingValue_.invoices) {
                                agingResponse = agingValue_;
                            } else {
                                agingResponse = {};
                                agingResponse.invoices = agingValue_;
                            }
                        }
                    }
                    else {
                        const erpAggingReport = await this.api.getAgingReportFromERP(email);
                        if (erpAggingReport && erpAggingReport.invoices) {
                            agingResponse = erpAggingReport;
                        }
                    }
                    if (agingResponse && agingResponse.invoices && agingResponse.invoices.length > 0) {

                        this.setState({ receivableAgingReportData: agingResponse, isAgingfile: false });
                    }
                    else {
                        this.setState({ isAgingfile: true });
                    }
                }
            }

        });
    }

    async getFinacialDocumentationForBuyer() {
        let { customerDocuments } = this.state;
        let customerNames_ = Object.keys(customerDocuments);
        if (customerDocuments && Object.keys(customerDocuments).length > 0) {
            customerNames_.forEach(async cDocs_ => {
                if (customerDocuments[cDocs_][0].customerDetails) {
                    const res = await this.api.getFunderSupplierDocs(customerDocuments[cDocs_][0].customerDetails._id);
                    let isAging = true;
                    let isbankStmt = true;
                    let isPayableAging = true;
                    let aging = res.items.filter(
                        x => x.financialDocumentType === "AGING_REPORT"
                    );
                    if (aging.length == 0) {
                        isAging = false;
                    }
                    let payableAging = res.items.filter(
                        x => x.financialDocumentType === "PAYABLE_AGING_REPORT"
                    );
                    if (payableAging.length == 0) {
                        isPayableAging = false;
                    }
                    let bankStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
                    if (bankStatement.length == 0) {
                        isbankStmt = false;
                    }
                    let financialAdditionalDocs = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
                    res.items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() != "additionaldocument");
                    customerDocuments[cDocs_][0].customerDetails.financialDocuments = res.items ? res.items : [];
                    customerDocuments[cDocs_][0].customerDetails.isAgingfile = isAging;
                    customerDocuments[cDocs_][0].customerDetails.additionalDocuments = financialAdditionalDocs && financialAdditionalDocs.length > 0 ? financialAdditionalDocs : [];
                    if (customerDocuments[cDocs_][0].customerDetails.accountingInformation && customerDocuments[cDocs_][0].customerDetails.accountingInformation == "quickbooks") {
                        if (!isbankStmt) {
                            const bankTransactionHistory_ = await this.api.getsupbanktransactionhistory(customerDocuments[cDocs_][0].customerDetails._id);
                            customerDocuments[cDocs_][0].customerDetails.bankStatement = bankTransactionHistory_;
                        }
                    }
                    if (customerDocuments[cDocs_][0].customerDetails.accountingInformation && customerDocuments[cDocs_][0].customerDetails.accountingInformation != "erpinfo") {
                        if (!isPayableAging) {
                            const pDetailReport_ = await this.api.getPayablesAgingReport(customerDocuments[cDocs_][0].customerDetails._id);
                            if (pDetailReport_ && pDetailReport_.Payables && pDetailReport_.Payables.length > 0) {
                                customerDocuments[cDocs_][0].customerDetails.payableAging = pDetailReport_;
                            }
                        }
                        const rDetailReport_ = await this.api.getReceivablesDetailsAgingReport(customerDocuments[cDocs_][0].customerDetails._id);
                        if (rDetailReport_ && rDetailReport_.receivablesDetails && rDetailReport_.receivablesDetails.length > 0) {
                            customerDocuments[cDocs_][0].customerDetails.receivableDetailsAging = rDetailReport_;
                        }

                        const pDetailReport_ = await this.api.getPayablesDetailsAgingReport(customerDocuments[cDocs_][0].customerDetails._id);
                        if (pDetailReport_ && pDetailReport_.payablesDetails && pDetailReport_.payablesDetails.length > 0) {
                            customerDocuments[cDocs_][0].customerDetails.payablesDetailsAging = pDetailReport_;
                        }
                    }
                }
            });

            this.setState({
                customerDocuments: customerDocuments
            });
        }
    }
    async getSupplierbanktransactionhistory() {
        const res = await this.api.getsupbanktransactionhistory(this.props.data.requesterDetails._id);
        this.setState({ bankTransactionHistory: res });
    }

    getDropBoxProfileContent(files, overrideFileName, isFileName_) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                let fileUrl = d.downloadUrl ? (d.downloadUrl.replace("http//", "http://").replace("https//", "https://")
                    .replace("http://http", "http").replace("https://http", "http")) : "";

                if (this.user.currentUserRole() == "funder") {
                    return <div className="col-md-3" key={d.filename}> <span className='d-flex'><a className="anchorstyle mr-2" href={""} onClick={(e) => {
                        e.preventDefault();
                        if (d.downloadUrl) {
                            d.downloadUrl = fileUrl;
                        }
                        this.setState(
                            {
                                isFileViewerModal: true,
                                fileViewerContent: d,
                                isFinancialDocument: true
                            }
                        );
                    }} >{isFileName_ ? d.filename : d.financialDocumentType}</a>
                    <FileMetaDataPopover data={d} /></span></div>;
                } else {
                    return <div key={d.filename}><a href={""} onClick={(e) => {
                        e.preventDefault();
                        if (d.downloadUrl) {
                            d.downloadUrl = fileUrl;
                        }
                        this.setState(
                            {
                                isFileViewerModal: true,
                                fileViewerContent: d,
                                isFinancialDocument: true
                            }
                        );
                    }} >{d.filename}</a></div>;
                }

            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>;
            });
        }
    }

    getDropBoxContent(files, type, overrideFileName) {
        if (this.props.isReadOnly) {
            return files.map(d => {
                return <div key={d.filename}><a className="anchorstyle" href={`${API_ENDPOINT_SUPPLIER}/tcf/download-document/${d._id}`} target="_blank" download={d.filename}>{overrideFileName ? overrideFileName : d.filename}</a></div>;
            });
        }
        else {
            return files.map(d => {
                return <div key={d.name}>{d.name}</div>;
            });
        }
    }

    getAggingReportDownload = async (e, email, accountingType_) => {
        e.preventDefault();
        this.setState({ agingReport: true });
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (accountingType_ == "quickbooks") {
            const agingValue = await this.api.getAgingReportFromQBO(email);
            if (agingValue && !agingValue.msg) {
                agingResponse = agingValue;
            }
        }
        else if (accountingType_ == "D365BC") {
            const agingValue_ = await this.api.getReceivableAgingReportFromD365BC(email);
            if (agingValue_ && !agingValue_.msg) {
                if (agingValue_.invoices) {
                    agingResponse = agingValue_;
                } else {
                    agingResponse = {};
                    agingResponse.invoices = agingValue_;
                }
            }
        }
        else {
            const erpAggingReport = await this.api.getAgingReportFromERP(email);
            if (erpAggingReport && erpAggingReport.invoices) {
                agingResponse = erpAggingReport;
            }
        }
        if (agingResponse && agingResponse.invoices && agingResponse.invoices.length > 0) {
            const rows = [
                ["Customer Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
            ];
            agingResponse.invoices.forEach(element => {
                let columns = [];
                columns.push('"' + element.customerName + '"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue ? element.currentDue : 0)
                    + (element.due30Days ? element.due30Days : 0)
                    + (element.due45Days ? element.due45Days : 0)
                    + (element.due60Days ? element.due60Days : 0)
                    + (element.due90Days ? element.due90Days : 0)
                    + (element.due120Days ? element.due120Days : 0)
                    + (element.due180Days ? element.due180Days : 0)
                    + (element.due180pluseDays ? element.due180pluseDays : 0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
                due30 + (element.due30Days ? element.due30Days : 0),
                due45 + (element.due45Days ? element.due45Days : 0),
                due60 + (element.due60Days ? element.due60Days : 0),
                due90 + (element.due90Days ? element.due90Days : 0),
                due120 + (element.due120Days ? element.due120Days : 0),
                due180 + (element.due180Days ? element.due180Days : 0),
                due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL");
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45);
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent,
                csvData: rows,
                filename: "Aging_Report.csv"
            };
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            });

            this.setState({ agingReport: false });
        }
        else {
            this.setState({ agingReport: false });
        }
    };

    getPayableAgingReportDownload = async (e, data_, accountingType_) => {
        e.preventDefault();
        this.setState({ agingReport: true });
        const { requesterDetails } = this.props.data;
        const { payableAgingReport } = this.state;
        let agingResponse;
        let [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        if (payableAgingReport && payableAgingReport.Payables && payableAgingReport.Payables.length > 0) {
            const rows = [
                ["Vendor Name", "Current Due", "Due 30 Days", "Due 45 Days", "Due 60 Days", "Due 90 Days", "Due 120 Days", "Due 180 Days", "Due > 180 Days", "Total"]
            ];
            payableAgingReport.Payables.forEach(element => {
                let columns = [];
                columns.push('"' + element.VendorName + '"');
                columns.push(element.currentDue);
                columns.push(element.due30Days);
                columns.push(element.due45Days);
                columns.push(element.due60Days);
                columns.push(element.due90Days);
                columns.push(element.due120Days);
                columns.push(element.due180Days);
                columns.push(element.due180pluseDays);
                let totalDueValue = (element.currentDue ? element.currentDue : 0)
                    + (element.due30Days ? element.due30Days : 0)
                    + (element.due45Days ? element.due45Days : 0)
                    + (element.due60Days ? element.due60Days : 0)
                    + (element.due90Days ? element.due90Days : 0)
                    + (element.due120Days ? element.due120Days : 0)
                    + (element.due180Days ? element.due180Days : 0)
                    + (element.due180pluseDays ? element.due180pluseDays : 0);
                columns.push(totalDueValue);
                [currentDues, due30, due45, due60, due90, due120, due180, due180pluse, totalDue] = [currentDues + (element.currentDue ? element.currentDue : 0),
                due30 + (element.due30Days ? element.due30Days : 0),
                due45 + (element.due45Days ? element.due45Days : 0),
                due60 + (element.due60Days ? element.due60Days : 0),
                due90 + (element.due90Days ? element.due90Days : 0),
                due120 + (element.due120Days ? element.due120Days : 0),
                due180 + (element.due180Days ? element.due180Days : 0),
                due180pluse + (element.due180pluseDays ? element.due180pluseDays : 0),
                totalDue + totalDueValue];
                rows.push(columns);
            });
            let columns = [];
            columns.push("TOTAL");
            columns.push(currentDues);
            columns.push(due30);
            columns.push(due45);
            columns.push(due60);
            columns.push(due90);
            columns.push(due120);
            columns.push(due180);
            columns.push(due180pluse);
            columns.push(totalDue);
            rows.push(columns);
            let csvContent = "data:text/csv;charset=utf-8,";

            rows.forEach((rowArray) => {
                let row = rowArray.join(",");
                csvContent += row + "\r\n";
            });
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent,
                csvData: rows,
                filename: "Payable_Aging_Report.csv"
            };
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            });
        }
        else {
            this.setState({ agingReport: false });
        }
    };

    getTransactionHistoryDownload = (e, isBuyer_, bankData_) => {
        e.preventDefault();
        const { supplierBankTransactionHistory } = bankData_;
        let csvContent = "data:text/csv;charset=utf-8,";
        const table = {
            indent: 1,
            rows: []
        };
        const readbankTransactionValue = (rowData, indentation_, isGroup) => {
            for (let rowType of Object.keys(rowData)) {
                let row_;
                if (isGroup) {
                    row_ = {
                        indent: indentation_ + 1,
                        columns: [],
                    };
                    table.indent = Math.max(table.indent, indentation_ + 1);
                }
                else {
                    row_ = {
                        indent: indentation_,
                        columns: [],
                    };
                }
                //table.rows.push(row_);
                row_.columns.push('"' + rowType + '"');
                table.rows.push(row_);
                if (rowData[rowType]) {
                    if (typeof rowData[rowType] === 'object' && rowData[rowType] !== null) {
                        Object.keys(rowData[rowType]).forEach((childRowType, i) => {
                            if (rowData[rowType][childRowType].TransactionType) {
                                let ele = rowData[rowType][childRowType];
                                let row_ = {
                                    columns: [],
                                };
                                //table.rows.push(row_);
                                row_.columns.push(ele.TransactionType);
                                row_.columns.push(ele.Date);
                                row_.columns.push(ele.Description);
                                row_.columns.push(ele.Amount);
                                row_.columns.push(ele.Balance);
                                table.rows.push(row_);
                            }
                            else {
                                let newIndentation = indentation_;
                                if (rowData[rowType][childRowType].length > 0 && Array.isArray(rowData[rowType][childRowType])) {
                                    row_ = {
                                        columns: [],
                                        indent: indentation_ + 1,
                                    };
                                    table.indent = Math.max(table.indent, indentation_ + 1);
                                    row_.columns.push('"' + childRowType + '"');
                                    table.rows.push(row_);
                                    rowData[rowType][childRowType].forEach(ele => {
                                        let row_ = {
                                            columns: [],
                                        };
                                        //table.rows.push(row_);
                                        row_.columns.push(ele.TransactionType);
                                        row_.columns.push(ele.Date);
                                        row_.columns.push(ele.Description);
                                        row_.columns.push(ele.Amount);
                                        row_.columns.push(ele.Balance);
                                        table.rows.push(row_);
                                    });
                                }
                                else if (typeof rowData[rowType][childRowType] === 'object' && rowData[rowType][childRowType] !== null) {
                                    ++newIndentation;
                                    let row_ = {
                                        indent: newIndentation,
                                        columns: ['"' + childRowType + '"'],
                                    };
                                    table.rows.push(row_);

                                    table.indent = Math.max(table.indent, newIndentation);
                                    readbankTransactionValue(rowData[rowType][childRowType], newIndentation, true);
                                }
                            }
                        });
                    }
                }
            }
        };

        const readableJsonToCSV = (table_) => {
            let lastRow = null;
            console.log(table_);
            for (let row of table_.rows) {
                if (row.padding && !lastRow.padding) {
                    csvContent += "\r\n";
                }

                for (let c = -table_.indent; c < row.columns.length; ++c) {
                    let col;
                    if (row.indent) {
                        col = row.columns[c + (table_.indent - row.indent + 1)];
                    } else {
                        col = row.columns[c];
                    }

                    if (col) {
                        csvContent += col;
                    }

                    if (c !== row.columns.length - 1) {
                        csvContent += ",";
                    }
                }

                if (row.padding) {
                    csvContent += "\r\n";
                }

                csvContent += "\r\n";

                lastRow = row;
            }

            return csvContent;
        };
        readbankTransactionValue(supplierBankTransactionHistory, 1, false);
        if (table.rows.length > 0) {
            let headerRows_ = {
                columns: ["TransactionType", "Date", "Description", "Amount", "Balance"]
            };
            table.rows.unshift(headerRows_);
            readableJsonToCSV(table);
            console.log(csvContent);
            var encodedUri = encodeURI(csvContent);
            let _fileContent = {
                encodedUri: encodedUri,
                isCSVString: true,
                csvString: csvContent.replace("data:text/csv;charset=utf-8,", ""),
                csvData: table.rows,
                filename: "BankTransactionHistory.csv",
                isBankTransactionHistory: true
            };
            this.setState({
                isFinancialDocument: false,
                fileViewerContent: _fileContent,
                isFileViewerModal: true
            });
        }
    };

    getReceivableDetailsAgingReport = async () => {
        const { requesterDetails } = this.props.data;
        const rDetailReport_ = await this.api.getReceivablesDetailsAgingReport(requesterDetails._id);
        if (rDetailReport_ && rDetailReport_.receivablesDetails && rDetailReport_.receivablesDetails.length > 0) {
            this.setState({ recDetailAgingReport: rDetailReport_, isRecievablesDetailsAgingReport: true });
        }
    };

    getPayableDetailsAgingReport = async () => {
        const { requesterDetails } = this.props.data;
        const pDetailReport_ = await this.api.getPayablesDetailsAgingReport(requesterDetails._id);
        if (pDetailReport_ && pDetailReport_.payablesDetails && pDetailReport_.payablesDetails.length > 0) {
            this.setState({ payDetailAgingReport: pDetailReport_, isPayableDetailsAgingReport: true });
        }
    };

    getPayablesAgingReport = async () => {
        const { requesterDetails } = this.props.data;
        const pDetailReport_ = await this.api.getPayablesAgingReport(requesterDetails._id);

        if (pDetailReport_ && pDetailReport_.Payables && pDetailReport_.Payables.length > 0) {
            this.setState({ payableAgingReport: pDetailReport_ });
        }
    };


    recDetailAgingReportDownload = (e, data_, accountingType_) => {
        e.preventDefault();
        const rows = [];
        const { receivablesDetails } = data_;
        let columns = [];
        let count;
        let reportDate = `As on ${localDateFormat(data_.pulledDate)}`;
        columns.push(reportDate);
        rows.push(columns);
        rows.push([]);
        if (accountingType_ == "D365BC") {
            rows.push(["Customer ID", "Customer Name", "Due Days ", "Amount"]);
        }
        else {
            rows.push(["Customer ID", "Customer Name", "Due Days ", "Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"]);
        }

        columns = [];
        receivablesDetails.forEach(element => {
            columns = [];
            count = 0;
            columns.push(element.customerId);
            columns.push('"' + element.customerName + '"');
            let isAlreadyInserted = false;
            if (element.currentDue > 0 && element.currentDuerecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.currentDuerecords, element.currentDue, "CurrentDue");
            }
            if (element.due30Days && element.due30Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due30Daysrecords, element.due30Days, "30 Days");
            }
            if (element.due45Days && element.due45Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due45Daysrecords, element.due45Days, "45 Days");
            }
            if (element.due60Days && element.due60Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due60Daysrecords, element.due60Days, "60 Days");
            }
            if (element.due90Days && element.due90Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due90Daysrecords, element.due90Days, "90 Days");
            }
            if (element.due120Days && element.due120Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days, "120 Days");
            }
            if (element.due180Days && element.due180Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180Daysrecords, element.due180Days, "180 Days");
            }
            if (element.due180pluseDays && element.due180pluseDaysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180pluseDaysrecords, element.due180pluseDays, "180 >");
            }
            if (!isAlreadyInserted) {
                if (element.currentDue && element.currentDue > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "CurrentDue", element.currentDue);
                    } else {
                        columns.push("CurrentDue", element.currentDue);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due30Days && element.due30Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "30 Days", element.due30Days);
                    } else {
                        columns.push("30 Days", element.due30Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due45Daysrecords && element.due45Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "45 Days", element.due45Daysrecords);
                    } else {
                        columns.push("45 Days", element.due45Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due60Days && element.due60Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "60 Days", element.due60Days);
                    } else {
                        columns.push("60 Days", element.due60Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due90Daysrecords && element.due90Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "90 Days", element.due90Daysrecords);
                    } else {
                        columns.push("90 Days", element.due90Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due120Days && element.due120Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "120 Days", element.due120Days);
                    } else {
                        columns.push("120 Days", element.due120Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180Days && element.due180Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 Days", element.due180Days);
                    } else {
                        columns.push("180 Days", element.due180Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180pluseDays && element.due180pluseDays > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 >", element.due180pluseDays);
                    } else {
                        columns.push("180 >", element.due180pluseDays);
                    }
                    rows.push(columns);
                    count++;
                }
            }
            // rows.push(columns);
        });
        function dueRecord(records_, dueAmt_, dueType_) {
            records_.forEach((d, i) => {
                if (i > 0 || count != 0) {
                    columns = [];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
            });
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let _fileContent = {
            encodedUri: encodedUri,
            isCSVString: true,
            csvString: csvContent,
            csvData: rows,
            filename: "Receivable_Details_Aging_Report.csv"
        };
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: _fileContent,
            isFileViewerModal: true
        });

    };

    payDetailAgingReportDownload = (e, data_, accountingType_) => {
        e.preventDefault();
        const rows = [];
        const { payablesDetails } = data_;
        let columns = [];
        let count;
        let reportDate = `As on ${localDateFormat(payablesDetails.pulledDate)}`;
        columns.push(reportDate);
        rows.push(columns);
        rows.push([]);
        if (accountingType_ == "D365BC") {
            rows.push(["Vendor ID", "Vendor Name", "Due Days", "Amount"]);
        } else {
            rows.push(["Vendor ID", "Vendor Name", "Due Days", "Transaction Type", "Transaction ID", "Transaction Date", "Due Date", "Amount"]);
        }
        payablesDetails.forEach(element => {
            columns = [];
            count = 0;
            columns.push(element.vendorId);
            columns.push('"' + element.vendorName + '"');
            let isAlreadyInserted = false;
            if (element.currentDue > 0 && element.currentDuerecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.currentDuerecords, element.currentDue, "CurrentDue");
            }
            if (element.due30Days && element.due30Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due30Daysrecords, element.due30Days, "30 Days");
            }
            if (element.due45Days && element.due45Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due45Daysrecords, element.due45Days, "45 Days");
            }
            if (element.due60Days && element.due60Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due60Daysrecords, element.due60Days, "60 Days");
            }
            if (element.due90Days && element.due90Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due90Daysrecords, element.due90Days, "90 Days");
            }
            if (element.due120Days && element.due120Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due120Daysrecords, element.due120Days, "120 Days");
            }
            if (element.due180Days && element.due180Daysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180Daysrecords, element.due180Days, "180 Days");
            }
            if (element.due180pluseDays && element.due180pluseDaysrecords.length > 0) {
                if (!isAlreadyInserted) {
                    isAlreadyInserted = true;
                }
                dueRecord(element.due180pluseDaysrecords, element.due180pluseDays, "180 >");
            }
            if (!isAlreadyInserted) {
                if (element.currentDue && element.currentDue > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "CurrentDue", element.currentDue);
                    } else {
                        columns.push("CurrentDue", element.currentDue);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due30Days && element.due30Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "30 Days", element.due30Days);
                    } else {
                        columns.push("30 Days", element.due30Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due45Daysrecords && element.due45Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "45 Days", element.due45Daysrecords);
                    } else {
                        columns.push("45 Days", element.due45Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due60Days && element.due60Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "60 Days", element.due60Days);
                    } else {
                        columns.push("60 Days", element.due60Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due90Daysrecords && element.due90Daysrecords > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "90 Days", element.due90Daysrecords);
                    } else {
                        columns.push("90 Days", element.due90Daysrecords);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due120Days && element.due120Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "120 Days", element.due120Days);
                    } else {
                        columns.push("120 Days", element.due120Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180Days && element.due180Days > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 Days", element.due180Days);
                    } else {
                        columns.push("180 Days", element.due180Days);
                    }
                    rows.push(columns);
                    count++;
                }
                if (element.due180pluseDays && element.due180pluseDays > 0) {
                    if (count > 0) {
                        columns = [];
                        columns.push("", "", "180 >", element.due180pluseDays);
                    } else {
                        columns.push("180 >", element.due180pluseDays);
                    }
                    rows.push(columns);
                    count++;
                }
            }
            // rows.push(columns);
        });
        function dueRecord(records_, dueAmt_, dueType_) {
            records_.forEach((d, i) => {
                if (i > 0 || count != 0) {
                    columns = [];
                    columns.push("");
                    columns.push("");
                }
                columns.push(dueType_);
                // columns.push(dueAmt_);
                columns.push(d.TransactionType);
                columns.push(d.TransactionID);
                columns.push(d.TransactionDate);
                columns.push(d.DueDate);
                columns.push(d.Amount);
                rows.push(columns);
            });
            count++;
        };
        let csvContent = "data:text/csv;charset=utf-8,";

        rows.forEach((rowArray) => {
            let row = rowArray.join(",");
            csvContent += row + "\r\n";
        });
        var encodedUri = encodeURI(csvContent);
        let _fileContent = {
            encodedUri: encodedUri,
            isCSVString: true,
            csvString: csvContent,
            csvData: rows,
            filename: "Payable_Details_Aging_Report.csv"
        };
        this.setState({
            isFinancialDocument: false,
            fileViewerContent: _fileContent,
            isFileViewerModal: true
        });
    };

    batchDownload = async (e) => {
        e.preventDefault();
        const { fundingDocuments, requesterDetails } = this.props.data;
        const { customerDocuments } = this.state;
        var zip = new JSZip();
        if (customerDocuments && Object.keys(customerDocuments).length > 0) {
            for (let cus of Object.keys(customerDocuments)) {
                if (customerDocuments[cus] && customerDocuments[cus].length > 0) {
                    for (let doc of customerDocuments[cus]) {
                        if (doc.documentFiles && doc.documentFiles.length > 0) {
                            for (let document_ of doc.documentFiles) {
                                const docContent_ = `${API_ENDPOINT_SUPPLIER}/tcf/base64-document/${document_._id}`;
                                const baseContent_ = await (await fetch(docContent_, {
                                    method: "GET",
                                    credentials: "include"
                                })).text();
                                zip.file(document_.filename, baseContent_, { base64: true });
                            }
                        }
                        else {
                            let taxTotal = 0;
                            const rows = [
                                ["From:"]
                            ];
                            let columns = [];
                            columns.push("");
                            columns.push('"' + requesterDetails.name + '"');
                            columns.push("");
                            columns.push("");
                            columns.push("Invoice No");
                            columns.push(doc.documentId);
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + (requesterDetails.address) + '"');
                            columns.push("");
                            columns.push("");
                            columns.push("Invoice Date");
                            columns.push(localDateFormat(doc.documentDate));
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + requesterDetails.city + ',' + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + requesterDetails.state + ' ' + requesterDetails.zip + ',' + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + (requesterDetails.country ? requesterDetails.country : "") + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + (requesterDetails.phone) + '"');
                            rows.push(columns);
                            columns = [];
                            rows.push(columns);
                            columns = [];
                            rows.push(columns);
                            columns = ["To:"];
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + doc.customerDetails.name + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + (doc.customerDetails.address) + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + doc.customerDetails.city + ',' + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + doc.customerDetails.state + ' ' + doc.customerDetails.zip + ',' + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + (doc.customerDetails.country ? doc.customerDetails.country : "") + '"');
                            rows.push(columns);
                            columns = [];
                            columns.push("");
                            columns.push('"' + doc.customerDetails.phone + '"');
                            rows.push(columns);
                            columns = [];
                            rows.push(columns);
                            columns = [];
                            rows.push(columns);
                            columns = ["#", "Product/Service", "Description", "Quantity", "Price", "Line Total"];
                            rows.push(columns);
                            doc.invoice && doc.invoice.items && doc.invoice.items.forEach((lineItem_, i) => {
                                columns = [];
                                columns.push(i);
                                columns.push('"' + (lineItem_.productName ? lineItem_.productName : "") + '"');
                                columns.push('"' + (lineItem_.descr ? lineItem_.descr : "") + '"');
                                columns.push(lineItem_.qty ? lineItem_.qty : 0);
                                columns.push(lineItem_.price ? lineItem_.price : 0);
                                columns.push(lineItem_.subTotal ? lineItem_.subTotal : 0);
                                rows.push(columns);
                                taxTotal += (lineItem_.price * lineItem_.qty);
                            });
                            columns = ["", "", "", "", "TAX", (doc.total - taxTotal)];
                            rows.push(columns);
                            columns = ["", "", "", "", "TOTAL", doc.total];
                            rows.push(columns);
                            let csvContent = "";
                            rows.forEach((rowArray) => {
                                let row = rowArray.join(",");
                                csvContent += row + "\r\n";
                            });
                            zip.file("Invoice-" + doc.documentId + ".csv", csvContent);
                        }
                    }
                }
            }
        }
        zip.generateAsync({ type: "blob" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, "Invoices.zip");
            });
    };

    ViewSupplyChainModalClick=(e,document_)=>{
        e.preventDefault();
        this.setState({isViewSupplyChain:true, viewDocumentData:document_ });
    }


    getMiddeskType=(type_)=>{
        switch(type_){
            case "People":
                return this.props.t("middesk_details_content.people");
            case "Website Address":
                return this.props.t("middesk_details_content.website_address");
            case "Profile":
                return this.props.t("middesk_details_content.profile");
            case "Watch List":
                return this.props.t("middesk_details_content.watch_list");
            case "Other Verifications":
                return this.props.t("middesk_details_content.other_verifications");
            case "Names":
                return this.props.t("middesk_details_content.names");
            case "Registration Officers":
                return this.props.t("middesk_details_content.registration_officers");
            default:
                return "";
        }
    }

    getPreFundingDocumentLabels=(type_)=>{
        switch(type_){
            case "FINANCING_APPLICATION":
                return this.props.t("file_upload_label.label01");
            case "LAST_TWO_YEAR_FINANCIAL_STATEMENTS":
                return this.props.t("file_upload_label.label02");
            case "AGING_REPORTS":
                return this.props.t("file_upload_label.label03");
            case "ARTICLE_OF_INCORPORATION":
                return this.props.t("file_upload_label.label04");
            case "COPY_OF_PRINCIPAL_ID":
                return this.props.t("file_upload_label.label05");
            case "PAYMENT_HISTORY":
                return this.props.t("file_upload_label.label06");
            case "SALES_PROJECTION":
                return this.props.t("file_upload_label.label07");
            case "OTHER_PRE_FUNDING_DOCUMENTS":
                return this.props.t("file_upload_label.label12");
            default:
                return "";
        }
    }

    getPreFundingAdditionalDocs=(additionalDocs_)=>{
        const _docs1=additionalDocs_.filter(f_=>f_.fileCategory==_salesContractLabel);
        const _docs2=additionalDocs_.filter(f_=>f_.fileCategory==_shippingDocumentLabel);
        const _docs3=additionalDocs_.filter(f_=>f_.fileCategory==_paymentConfirmationLabel);
        const _additionalDocs={
            "sales_contract":_docs1,
            "shipping_documents":_docs2,
            "payment_confirmation":_docs3
        }
        return (
            <>
                {(_docs1.length > 0 || _docs2.length > 0 || _docs3.length > 0 )? (<>
                {Object.keys(_additionalDocs).map((type_,i)=>{
                    return (
                        <>
                            <label className="form-label mt-2">
                                {type_ == _salesContractLabel && (
                                    <>
                                        {this.props.t("file_upload_label.label09")}
                                    </>
                                )}
                                {type_ == _shippingDocumentLabel && (
                                    <>
                                        {this.props.t("file_upload_label.label10")}
                                    </>
                                )}
                                {type_ == _paymentConfirmationLabel && (
                                    <>
                                        {this.props.t("file_upload_label.label11")}
                                    </>
                                )}
                            </label>
                            <>
                                {_additionalDocs[type_].length > 0 ? (<>
                                    {_additionalDocs[type_].map((doc_, index) => {
                                        return <span
                                            key={index} className="form-value p-0 d-flex">
                                            <a className="anchorstyle mr-2" href={""} onClick={e => this.FileViewerModalClick(e, doc_)}>{convertUTF8String(doc_.filename)}</a>
                                            <FileMetaDataPopover data={doc_} />
                                        </span>
                                    })}
                                </>) : (<div className='pl-4'>{this.props.t("documents_not_found_msg")}</div>)}
                            </>
                        </>
                    )
                })}
                </>) : (<>
                    <label className="form-label mt-2">
                        {this.props.t("previous_completed_transaction_documents")}
                    </label>
                    <>
                        {additionalDocs_.map((document_, index) => {
                            return <span
                                key={index} className="form-value p-0 d-flex">
                                <a className="anchorstyle mr-2" href={""} onClick={e => this.FileViewerModalClick(e, document_)}>{convertUTF8String(document_.filename)}</a>
                                <FileMetaDataPopover data={document_}/>
                            </span>
                        })}
                    </>
                </>)}
            </>
        )
    }


    render() {
        const {
            requesterDetails,
            midDesk,
            fundingDocuments,
            fundingType,
            status,
            contracts,
            additionalInfo,
            cslData,
            isSupplyChainFinancing,
            isPreFundingRequest,
            fundingReqAdditionalDocs,
            isCreatedByRepresentive,
            createdUserDetails
        } = this.props.data;
        const {
            customerDocuments, latestMiddeskResponse, isUpdatedMiddesk,
            isPayableDetailsAgingReport,
            isRecievablesDetailsAgingReport,
            isCSLDone,
            isCSLHasData,
            activeStep,
            tradingPartnerLists
        } = this.state;
        requesterDetails.questionnaires=[{"questionnaire": additionalInfo}];
        let steps = [
            { label: this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.requester_details") },
            { label: this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.funding_request_details") },
            { label: this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.financial_documents")  },
            { label: this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.trading_partner_details") },
        ];
        if(!compareTransactionType(fundingType[0],transactionType.SupplyChain)){
            steps.splice(3,0,{ label: this.props.t("fundingrequests_page.view_funding_request_content.stepper_labels.requester_charts") })
        }

        return (
        <>
            <FullSpinner
                isSpinning={isUpdatedMiddesk}
            />

            <FullSpinner
                isSpinning={isUpdatedMiddesk? false : this.state.agingReport}
                confirmationLabel={this.props.t("fundingrequests_page.view_funding_request_content.aging_spinner_msg")}
            />

            {this.state.isViewCslModal && (
                <ViewCslDataModal
                    data={this.state.cslDetails}
                    onClose={() => {
                        this.setState({ isViewCslModal: false, cslDetails: null });
                    }}
                >
                </ViewCslDataModal>
            )}

            {this.state.isFileViewerModal && this.state.fileViewerContent && <FileViewerModal
                onClose={() => {
                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                }}
                data={this.state.fileViewerContent}
                isFinancialDocument={this.state.isFinancialDocument}
            ></FileViewerModal>}
            
            {this.state.isSigningPopup && this.state.signingContractValue && <SigningStatusModal
                isReadOnly={true}
                onClose={() => { this.setState({ isSigningPopup: false }); }}
                data={this.state.signingContractValue}
                isTermSheetAvailable={this.state.isTermSheetAvailable}
            />}
            {(this.state.isViewSupplyChain) && <UploadSupplyChainModal
                isReadOnly={this.state.isViewSupplyChain}
                t={this.props.t}
                onClose={() => {
                    this.setState({ isViewSupplyChain: false, viewDocumentData: null });
                }}
                data={this.state.viewDocumentData}
            />}
            <CommonPopUpModal
                size="full"
                onClose={e => this.props.onClose()}
                secondaryBtn={this.props.t("button_names.previous")}
                isSecBtnEnabled={activeStep === 0}
                onSecBtnClick={this.handleBack}
                primaryBtn={this.props.t("button_names.next")}
                isSubmitBtn={activeStep === steps.length - 1}
                onSubmit={this.handleNext}
                promptOnClose={false}
                title={this.state.isMiddesk ? (
                    <>
                        <h1 style={{ cursor: "pointer" }} onClick={(e) => {
                            const names = this.middeskResponse.name;
                            const _similarMatchNames = midDesk.response.names;
                            if (names.tags.indexOf("Similar Match") != -1) {
                                AddModal(this.props.t("fundingrequests_page.view_funding_request_content.similar_match_titles"), <div className='d-flex flex-column'>{midDesk.response && midDesk.response.names && midDesk.response.names.length > 0 && midDesk.response.names.map(sName_ => {
                                    return <div><b>{sName_.name}</b></div>;
                                })}</div>, null, false);
                            }

                        }}>
                            {isCreatedByRepresentive && createdUserDetails && createdUserDetails.name && (
                                <span style={{fontSize:28}}>
                                    {`${createdUserDetails.name} on behalf of `}
                                </span>
                            )}
                            {midDesk.response.tin ? midDesk.response.tin.name : midDesk.response.name}
                            {this.getBadges(this.middeskResponse.name)}
                        </h1>
                    </>
                    ) : (<>
                        <h1>
                           {isCreatedByRepresentive &&  createdUserDetails && createdUserDetails.name &&requesterDetails && requesterDetails.name?`${createdUserDetails.name} on behalf of ${requesterDetails.name}`:
                           requesterDetails && requesterDetails.name ? requesterDetails.name : ""}
                        </h1>
                    </>)
                }   
                children={
                    <>
                        <div className="view-funding-modal w-100">
                            <div>
                                <Stepper activeStep={activeStep} data-testid="vertical-stepper" orientation="vertical" className="sticky-div">
                                    {steps.map((step, index) => {
                                        return (
                                            <Step key={index} data-testid="step" onClick={() => this.handleStepper(index)}>
                                                <StepLabel
                                                    StepIconComponent={GreenStepIcon}>
                                                    {step.label}
                                                </StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </div>
                            <div className={activeStep===3?"right-modal-content ml-3 bg-color":"right-modal-content ml-3"}>
                                {activeStep === 0 && (
                                    <>
                                        <div className='row mx-0 py-4'>
                                            <div className='col-md-4'>
                                                <label className="view-details-header pb-2">{this.props.t("requester_details")}</label>
                                                <span>
                                                    <address className="normal-text-color">
                                                        <strong>{this.props.t("fundingrequests_page.view_funding_request_content.requester_full_title")}:</strong>
                                                        {" "} {requesterDetails.userName ? requesterDetails.userName : ""}
                                                        <br />
                                                        <strong>{this.props.t("fundingrequests_page.view_funding_request_content.requester_job")}:</strong>
                                                        {" "} {requesterDetails.title ? requesterDetails.title : ""}

                                                        <br />
                                                        <strong>{this.props.t("user_labels.phone")}:</strong><strong className="link-text-color">&nbsp;{requesterDetails.phone}</strong>
                                                        <br />
                                                        <strong>{this.props.t("user_labels.founded")}:</strong>&nbsp; {requesterDetails.year}
                                                        <br />
                                                        <strong>{this.props.t("user_labels.entity")}:</strong>&nbsp;{getEntity(requesterDetails.entity)}
                                                        <br />
                                                        <strong>{this.props.t("user_labels.industry")}:</strong>&nbsp;{getIndustryWithId(requesterDetails.industry)}
                                                        <br />
                                                        <strong> {this.props.t("user_labels.sales")}:{" "}</strong>
                                                        <strong className="link-text-color">
                                                            {dollarFormatter.format(requesterDetails.revenue ?Number(
                                                                requesterDetails.revenue.toString().replace(/[^0-9.-]+/g, "")): 0) }
                                                        </strong>
                                                    </address>
                                                </span>
                                            </div>
                                            <div className='col-md-4'>
                                                <label className="view-details-header pb-2">{this.props.t("user_labels.address")} {this.state.isMiddesk && this.getBadges(this.middeskResponse.address)}</label>
                                                <span >
                                                    <address className="normal-text-color">
                                                        {requesterDetails.address} <br />
                                                        {requesterDetails.city} <br />
                                                        {requesterDetails.state} <br />
                                                        {requesterDetails.zip}<br />
                                                        {requesterDetails.country ? requesterDetails.country : ""}
                                                    </address>
                                                </span>
                                            </div>
                                            <div className='col-md-4'>
                                                <label className="view-details-header pb-2">{this.props.t("user_labels.tax_id")} {this.state.isMiddesk && this.getBadges(this.middeskResponse.tin)}</label>
                                                <strong className="link-text-color">
                                                    {this.state.isMiddesk ? (<>
                                                        {midDesk.response.tin ? midDesk.response.tin.tin : ""}
                                                    </>) : (<>
                                                        {requesterDetails && requesterDetails.taxId ? requesterDetails.taxId : ""}</>)}
                                                </strong>
                                                <label className="view-details-header pb-2">{this.props.t("user_labels.phone_number")}{this.state.isMiddesk && this.getBadges(this.middeskResponse.phone)}</label>
                                                {this.state.isMiddesk ? (<>
                                                    {midDesk.response.phone_numbers.length > 0 ?
                                                        (<>
                                                            {midDesk.response.phone_numbers.map((pnos) => (
                                                                <span>{pnos.phone_number}</span>
                                                            ))}
                                                        </>)
                                                        :
                                                        (<React.Fragment>
                                                            <span>{this.props.t("phone_number_not_found_msg")}</span>
                                                        </React.Fragment>)}
                                                </>) : (<>
                                                    <span>{requesterDetails.phone}</span>
                                                </>)}
                                            </div>
                                        </div>
                                        <div className='row mx-0 pb-4'>
                                            {this.state.isMiddesk && (
                                                <>
                                                    <div className="col-md-12">
                                                        <label className="view-details-header pb-2">{this.props.t("middesk_details_content.business_documents")}</label>
                                                        <div className='row mx-0'>
                                                            <div className='col-md-4 pl-3'>
                                                                <label className="form-label">{this.props.t("middesk_details_content.documents")}</label>
                                                                {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.documents && latestMiddeskResponse.documents.length > 0 ?
                                                                    <React.Fragment>
                                                                        {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.documents &&
                                                                            latestMiddeskResponse.documents.map(item => (
                                                                                <span className="form-value">
                                                                                    <a className="anchorstyle" key={item.document_type} href={item.download_url} target="_blank">{ShortText(item.document_type ? item.document_type : item.filename)}</a>
                                                                                </span>
                                                                            ))}
                                                                    </React.Fragment>
                                                                    : <span className="form-value">
                                                                        <div>{this.props.t("middesk_details_content.no_documents_msg")}</div>
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className='col-md-4 pl-3'>
                                                                <label className="form-label">{this.props.t("middesk_details_content.liens")}</label>
                                                                {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.liens && latestMiddeskResponse.liens.length > 0 ?
                                                                    <React.Fragment>
                                                                        {Object.keys(latestMiddeskResponse).length > 0 && latestMiddeskResponse.liens &&
                                                                            latestMiddeskResponse.liens.map(item => {
                                                                                return (
                                                                                    <React.Fragment>
                                                                                        {item.documents && item.documents.map(doc => (
                                                                                            <span className="form-value">
                                                                                                <a className="anchorstyle" key={doc.document_type} href={doc.download_url} target="_blank">{ShortText(doc.document_type ? doc.document_type : doc.filename)}</a>
                                                                                            </span>
                                                                                        ))}
                                                                                    </React.Fragment>
                                                                                )
                                                                            }
                                                                            )}
                                                                    </React.Fragment>
                                                                    : <span className="form-value">
                                                                        <div>{this.props.t("middesk_details_content.no_liens_msg")}</div>
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            )}

                                        </div>
                                        <div className='row mx-0 pb-4'>
                                            {this.state.isMiddesk && this.middeskResponse.extra.map(e =>
                                                <>
                                                    {((e.type.indexOf("Registration Details") != -1 || (e.type == "Watch List" || e.type == "Other Verifications"))) && (
                                                        <>
                                                            <div className={"col-md-6 pb-4"} >
                                                                <label className="view-details-header pb-2">{this.getMiddeskType(e.type)}</label>
                                                                {e.details.map(d =>
                                                                    <div>
                                                                        {(d.label == "Registration Officers" && d.message != "No Officers found") && (
                                                                            <>
                                                                                <label className="form-label">{this.props.t("middesk_details_content.registration_officers")}:</label>
                                                                                <div className="row pl-4">
                                                                                    <div className="col-md-6">
                                                                                        <label className="form-label">{this.props.t("middesk_details_content.officers_name")}</label>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label className="form-label">{this.props.t("middesk_details_content.officers_roles")}</label>
                                                                                    </div>
                                                                                    {d.message.map(officer =>
                                                                                        <React.Fragment>
                                                                                            <div className="col-md-6">
                                                                                                {officer.name ? officer.name : ""}
                                                                                            </div>
                                                                                            <div className="col-md-6">
                                                                                                {officer.roles && officer.roles.map(role =>
                                                                                                    <React.Fragment>
                                                                                                        {role}<br />
                                                                                                    </React.Fragment>
                                                                                                )}
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                        {((d.label != "Registration Addresses" && (d.label == "Registration Officers" && d.message == "No Officers found"))) && (
                                                                            <div>
                                                                                <b>{d.label}</b>: {d.message}
                                                                            </div>
                                                                        )}
                                                                        {/* {((d.label != "Registration Addresses") || (d.label =="Registration Officers" && d.message == "No Officers found")) && (
                                                                            <>
                                                                                <b>{d.label}</b>: {d.message}
                                                                            </>
                                                                        ) } */}
                                                                        {(d.label == "Registration Addresses") && (
                                                                            <>
                                                                                <label className="form-label">{this.props.t("middesk_details_content.registration_address")}:</label>
                                                                                {d.message.map(addr_ =>
                                                                                    <>
                                                                                        <div className="col-md-12">
                                                                                            {addr_}
                                                                                        </div>

                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        {( (e.type == "Watch List" || e.type == "Other Verifications")) && (
                                                                            <>
                                                                                
                                                                                <b>{d.label}</b>: {d.message}
                                                                            </>
                                                                        )}
                                                                        
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                    {e.type == "People" && (
                                                        <div className='col-md-12 mb-4'>
                                                            <label className="view-details-header pb-2">{this.getMiddeskType(e.type)}</label>
                                                            {midDesk.response.people && midDesk.response.people.length > 0 ?
                                                                (<>
                                                                    {midDesk.response.people.map((peoplelist) => (
                                                                        <span>
                                                                            <b>{peoplelist.name}</b>
                                                                            <p>
                                                                            {this.props.t("middesk_details_content.role")} : {(peoplelist.titles.length > 0 ? peoplelist.titles[0].title : "Not found")}<br />
                                                                            {this.props.t("middesk_details_content.status")} : {(peoplelist.sources.length > 0) ? peoplelist.sources[0].metadata.status : "Not found"}<br />
                                                                            {this.props.t("middesk_details_content.state")} : {(peoplelist.sources.length > 0) ? peoplelist.sources[0].metadata.state : "Not found"}<br />
                                                                            {this.props.t("middesk_details_content.jurisdiction")} : {(peoplelist.sources.length > 0) ? peoplelist.sources[0].metadata.jurisdiction : "Not found"}<br />
                                                                            </p>
                                                                        </span>
                                                                    ))}
                                                                </>)
                                                                : (
                                                                    <span className="form-value">{this.props.t("people_not_found_msg")}</span>
                                                                )}
                                                        </div>
                                                    )}
                                                    {e.type == "Profile" && (
                                                        <div className='col-md-12 mb-4'>
                                                            <label className="view-details-header pb-2">{this.getMiddeskType(e.type)}</label>
                                                            {midDesk.response.profiles && midDesk.response.profiles.length > 0 ?
                                                                (<>
                                                                    {midDesk.response.profiles.map((profile) => (
                                                                        <span>
                                                                            <p>
                                                                                {this.props.t("user_labels.type")} : {profile.type}<br />
                                                                                {this.props.t("user_labels.url")} : {profile.url}<br />
                                                                            </p>
                                                                        </span>
                                                                    ))}
                                                                </>)
                                                                : (
                                                                    <span className="form-value">{this.props.t("profile_not_found_msg")}</span>
                                                                )}
                                                        </div>
                                                    )}

                                                    {e.type == "Website Address" &&
                                                        (<div className='col-md-12 mb-4'>
                                                            <label className="view-details-header pb-2">{this.getMiddeskType(e.type)}</label>
                                                            {e.details.length > 0 ?
                                                                <React.Fragment>
                                                                    {e.details.map(d =>
                                                                        <React.Fragment>
                                                                            {(d.status && d.domain) ?
                                                                                <React.Fragment>
                                                                                    <div>
                                                                                        <b>{this.props.t("middesk_details_content.url")}</b>: {d.url ? d.url : ""}
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{this.props.t("middesk_details_content.title")}</b>: {d.title ? d.title : ""}
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{this.props.t("middesk_details_content.website_status")}</b>: {d.status ? d.status : ""}
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{this.props.t("middesk_details_content.domain")}</b>: {d.domain && d.domain.domain ? d.domain.domain : ""}
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{this.props.t("middesk_details_content.domain_creation_date")}</b>:  {d.domain && d.domain.creation_date ? localDateFormat(d.domain.creation_date) : ""}
                                                                                    </div>
                                                                                    <div>
                                                                                        <b>{this.props.t("middesk_details_content.domain_expiratin_date")}</b>:  {d.domain && d.domain.expiration_date ? localDateFormat(d.domain.expiration_date) : ""}
                                                                                    </div>
                                                                                    {d.domain && d.domain.registrar && (
                                                                                        <React.Fragment>
                                                                                            <div>
                                                                                                <b>{this.props.t("middesk_details_content.registrar_organization")}</b>:  {d.domain.registrar && d.domain.registrar.organization ? d.domain.registrar.organization : ""}
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{this.props.t("middesk_details_content.registrar_name")}</b>:  {d.domain.registrar && d.domain.registrar.name ? d.domain.registrar.name : ""}
                                                                                            </div>
                                                                                            <div>
                                                                                                <b>{this.props.t("middesk_details_content.registrar_url")}</b>:  {d.domain.registrar && d.domain.registrar.url ? d.domain.registrar.url : ""}
                                                                                            </div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment> :
                                                                                <React.Fragment>
                                                                                    <b>{d.label}</b>: {d.message}
                                                                                </React.Fragment>}
                                                                        </React.Fragment>
                                                                    )}
                                                                </React.Fragment> : (
                                                                    <React.Fragment><span className="form-value">{this.props.t("website_not_found_msg")}</span></React.Fragment>
                                                                )}
                                                        </div>)}
                                                </>
                                            )}
                                        </div>
                                        <div className="col-md-12">
                                            {isCSLDone && (
                                                <div className={"form-section" + (isCSLHasData ? " clickable-area" : "")}
                                                    onClick={(e) => {
                                                        if (isCSLHasData) {
                                                            e.preventDefault();
                                                            this.setState({ isViewCslModal: true, cslDetails: cslData.cslData });
                                                        }
                                                    }}>
                                                    <label className="form-label">
                                                        <a className="anchorstyle" >{this.props.t("csl_details_content.csl_title")} {isCSLHasData ? <span style={{ fontWeight: "normal" }}>({this.props.t("click_anywhere_msg")})</span> : null}</a>
                                                    </label>
                                                    <span className="form-value">
                                                        {cslData.cslData.sources && cslData.cslData.sources.length > 0 && cslData.cslData.sources.map(
                                                            sr_ => {
                                                                return (
                                                                    <div>{sr_.value} <span className="badge rounded-pill bg-success" style={{ color: "white" }}>{sr_.count}</span> </div>
                                                                )
                                                            }
                                                        )}

                                                    </span>
                                                </div>
                                            )}

                                        </div>
                                    </>
                                )}
                                {activeStep === 1 && (
                                    <>
                                        <div className='row mx-0 py-4' style={{height: "40rem"}}>
                                            <div className="col-md-12">
                                                {/* {additionalInfo && (
                                                    <>
                                                        <label className="view-details-header pb-2">Additional details for funding request</label>
                                                        <QuestionnaireFundingDetails questionnaireDetails={additionalInfo} />
                                                    </>
                                                )} */}
                                                {(status == EnumFundingRequestStatus.AwaitingFundingOffer && Object.keys(this.state.preFundingDocs).length == 0 && !isPreFundingRequest) ? (
                                                    <div>{this.props.t("fundingrequests_page.view_funding_request_content.no_funding_request_msg")}</div>
                                                ) : (
                                                        <>
                                                            <label className="view-details-header pb-2">{this.props.t("pre_funding_documents")}</label>
                                                            <div className="row pb-4 pl-4">
                                                                {Object.keys(this.state.preFundingDocs).length>0 ?(<>
                                                                {Object.keys(this.state.preFundingDocs).map((type_, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className="col-md-6">
                                                                                <label>{this.getPreFundingDocumentLabels(type_)}</label>
                                                                                {this.state.preFundingDocs[type_] && this.state.preFundingDocs[type_].length == 0 ? (
                                                                                    <div className='pl-4'>{this.props.t("documents_not_found_msg")}</div>
                                                                                ) : (
                                                                                    <>
                                                                                        {this.state.preFundingDocs[type_].map((d_, i) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="col-md-6">
                                                                                                        <span className="form-value d-flex">
                                                                                                            <a className="anchorstyle mr-2" href={""} onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                this.setState({
                                                                                                                    isFileViewerModal: true,
                                                                                                                    isFinancialDocument: true,
                                                                                                                    fileViewerContent: d_
                                                                                                                })
                                                                                                            }} >{convertUTF8String(d_.filename)}</a>
                                                                                                            <FileMetaDataPopover data={d_} />
                                                                                                        </span>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </>
                                                                    )
                                                                })
                                                                }
                                                                </>):(<div className='pl-4'>{this.props.t("documents_not_found_msg")}</div>)}
                                                            </div>
                                                        </>
                                                )}

                                                    {((status!=EnumFundingRequestStatus.RejectedFundingRequestOffer || (contracts && contracts.length > 0 )) && status >= EnumFundingRequestStatus.FundingOffered) && (
                                                        <>
                                                            <label className="view-details-header pb-2">{this.props.t("funding_offer_details.funding_offer_title")} {this.viewData.fundingType.indexOf("ABL") == 0 ? " for Asset-Based Lending" : ""}</label>
                                                            <div className="row pb-4">
                                                                <FundingOfferDetails
                                                                    fundingType_={this.viewData.fundingType}
                                                                    offerDetails={contracts[0].terms}
                                                                />
                                                                <div className="col-md-3">
                                                                    <label>{this.props.t("funding_offer_details.term_sheet_label")}</label>
                                                                    {(contracts.length > 0 && contracts[0].termSheetFile) ? (
                                                                    <>
                                                                        <div className="">
                                                                            <span className="form-value">
                                                                                <div className='d-flex'>
                                                                                <a className="anchorstyle pr-2" href={""} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({
                                                                                        isFileViewerModal: true,
                                                                                        isFinancialDocument: false,
                                                                                        fileViewerContent: contracts[0].termSheetFile
                                                                                    })
                                                                                }}>{this.props.t("funding_offer_details.term_sheet_label")}</a> {contracts[0].isManualTermSheetUpload && (<FileMetaDataPopover data={contracts[0].termSheetFile} />) }&nbsp;
                                                                                {!contracts[0].isManualTermSheetUpload && (
                                                                                    <Tooltip title={this.props.t("funding_offer_details.click_to_view_electronic_signature")} arrow id='signed-term-sheet'>
                                                                                        <InfoIcon fontSize="small" style={{ color: "#063A52", cursor: "pointer" }} onClick={(e) => {
                                                                                            let _cont = contracts[0];
                                                                                            _cont["supplier"] = requesterDetails;
                                                                                            _cont["funder"] = this.props.data && this.props.data.funder ? this.props.data.funder : "";
                                                                                            this.setState({ isSigningPopup: true, signingContractValue: _cont, isTermSheetAvailable: true });
                                                                                        }} />
                                                                                    </Tooltip>
                                                                                )}
                                                                                </div>
                                                                                {contracts[0].isManualTermSheetUpload && contracts[0].signedTermSheetFile && (
                                                                                    <div className='d-flex'>
                                                                                    
                                                                                    <a className="anchorstyle pr-2" href={""} onClick={(e) => {
                                                                                        e.preventDefault();
                                                                                        this.setState({
                                                                                            isFileViewerModal: true,
                                                                                            isFinancialDocument: false,
                                                                                            fileViewerContent: contracts[0].signedTermSheetFile
                                                                                        })
                                                                                    }}>{this.props.t("funding_offer_details.signed_term_sheet_label")}</a>
                                                                                    <FileMetaDataPopover data={contracts[0].signedTermSheetFile} />
                                                                                    </div> 
                                                                                )}

                                                                            </span>
                                                                        </div>
                                                                    </>
                                                                    ) : (
                                                                        <strong className="form-value link-text-color">{this.props.t("funding_offer_details.no_term_sheet_msg")}</strong> 
                                                                    )}

                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {(contracts.length > 0 && contracts[0].fundingAggrement && status!=EnumFundingRequestStatus.RejectedFundingRequestOffer && status >= EnumFundingRequestStatus.FundingOffered) && (
                                                        <>

                                                        <label className="view-details-header pb-2">{this.props.t("funding_offer_details.funding_agreement_title")}</label>

                                                        <div className="row pb-4">
                                                            {(contracts.length > 0 && contracts[0].isDigitalSignature && contracts[0].isDigitalSignature == "true") ?
                                                                (<>
                                                                    <div className="col-md-6">
                                                                        <span className="form-value">
                                                                            <a className="anchorstyle" href={""} onClick={(e) => {
                                                                                e.preventDefault();
                                                                                let _cont = contracts[0];
                                                                                _cont["supplier"] = requesterDetails;
                                                                                _cont["funder"] = this.props.data && this.props.data.funder ? this.props.data.funder : "";
                                                                                this.setState({ isSigningPopup: true, signingContractValue: _cont });
                                                                            }}>{this.props.t("funding_offer_details.signing_status")}</a>
                                                                        </span>
                                                                    </div>
                                                                </>)
                                                                : (<>
                                                                    {(contracts.length > 0 && contracts[0].fundingAggrement && status >= EnumFundingRequestStatus.FundingOffered) && (
                                                                        <div className="col-md-6">
                                                                            <span className="form-value d-flex">
                                                                                <a className="anchorstyle mr-2" href={""} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({
                                                                                        isFileViewerModal: true,
                                                                                        isFinancialDocument: false,
                                                                                        fileViewerContent: contracts[0].fundingAggrement
                                                                                    })
                                                                                }} >{this.props.t("funding_offer_details.funding_agreement_label")}</a>
                                                                                <FileMetaDataPopover data={contracts[0].fundingAggrement} />
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                    {(contracts.length > 0 && contracts[0].signedAggrements && status >= EnumFundingRequestStatus.FundingAccepted) && (
                                                                        <div className="col-md-6">
                                                                            <span className="form-value d-flex">
                                                                                <a className="anchorstyle mr-2" href={""} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    this.setState({
                                                                                        isFileViewerModal: true,
                                                                                        isFinancialDocument: false,
                                                                                        fileViewerContent: contracts[0].signedAggrements
                                                                                    })
                                                                                }}>{this.props.t("funding_offer_details.signed_copy_label")}</a>
                                                                                
                                                                                <FileMetaDataPopover data={contracts[0].signedAggrements} />
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </>)}
                                                        </div>

                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                                {activeStep === 2 && (
                                    <div className='row mx-0 py-4'>
                                        <div className="col-md-12">
                                            <FinancialInformation
                                                data={requesterDetails}
                                            />
                                        </div>
                                    </div>
                                )}
                                {!compareTransactionType(fundingType[0],transactionType.SupplyChain) &&(
                                    <>
                                    {activeStep == 3 && (
                                        <NewDashboard
                                            data={requesterDetails}
                                        />
                                    )}</> 
                                )}
                                {((!compareTransactionType(fundingType[0],transactionType.SupplyChain) && activeStep == 4) || (compareTransactionType(fundingType[0],transactionType.SupplyChain) && activeStep == 3)) && (
                                    <div className='col-md-12' style={{height: "40rem"}}>
                                        {
                                            Array.isArray(fundingType) && fundingType.map(type => {
                                                if (type == "TCF") {
                                                    return fundingDocuments.map(item => (
                                                        <React.Fragment>
                                                            {item.total && (
                                                                <div className="form-section">
                                                                    <label className="form-label">{this.props.t("fundingrequests_page.view_funding_request_content.tax_credit_label")}</label>
                                                                    <span className="form-value">
                                                                        {dollarFormatter.format(item.total)}
                                                                    </span>
                                                                </div>
                                                            )}
                                                            <div className="form-section">
                                                                <div className="row">
                                                                    <div className="col-md-6 files-container">
                                                                        <label className="form-label">{this.props.t("fundingrequests_page.view_funding_request_content.financial_statements_title")}</label>
                                                                        {item.tcf.findoc.length
                                                                            ? <div>
                                                                                {
                                                                                    this.getDropBoxContent(item.tcf.findoc, type.toLowerCase())
                                                                                }
                                                                            </div>
                                                                            : <div>{this.props.t("documents_not_found_msg")}</div>
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-6 files-container">
                                                                        <label className="form-label">{this.props.t("fundingrequests_page.view_funding_request_content.tax_documents_label")}</label>
                                                                        {item.documentFiles.length
                                                                            ? <div>
                                                                                {
                                                                                    this.getDropBoxContent(item.documentFiles, type.toLowerCase())
                                                                                }
                                                                            </div>
                                                                            : <div>{this.props.t("documents_not_found_msg")}</div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    ));
                                                }
                                                else if (type == "ABL") {

                                                }
                                                else {
                                                    return (
                                                        <>
                                                            {isPreFundingRequest ? (
                                                                <div className="row pt-4">
                                                                    
                                                                    {tradingPartnerLists.map((tp_, index) => {
                                                                        return (<>
                                                                        
                                                                            <div className="col-lg-6">
                                                                            <label className="view-details-header pb-2">{this.props.t("trading_partner_details.title")}</label>
                                                                                <UserDetails userDetails={tp_}  />
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                {process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED == "true" && (
                                                                                    <>
                                                                                        {compareTransactionType(type, transactionType.Invoice) && this.props.data && this.props.data.contracts && this.props.data.contracts.length > 0 && this.props.data.contracts[0].creditLimitForTradingPartner && (
                                                                                            <div className='d-flex'>
                                                                                                {this.props.data.contracts && this.props.data.contracts.length > 0 && this.props.data.contracts[0].creditLimitForTradingPartner && (
                                                                                                    <> <div><b>{this.props.t("credit_limit_label")}:</b></div><strong className='form-value link-text-color'>{this.props.data.contracts[0].creditLimitForTradingPartner[tp_._id].creditLimitValue == 0 ? this.props.t("no_credit_limit") : dollarFormatter.format(this.props.data.contracts[0].creditLimitForTradingPartner[tp_._id].creditLimitValue)}
                                                                                                    </strong>
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                )}

                                                                                {tp_.invoiceFile && tp_.invoiceFile.map((document_, index) => {
                                                                                    return <span
                                                                                        key={index} className="d-flex">
                                                                                        <a className="anchorstyle mr-2" href={""} onClick={e => this.FileViewerModalClick(e, document_)}>{this.props.t("view_invoice")}</a>
                                                                                        <FileMetaDataPopover data={document_} />
                                                                                    </span>
                                                                                })}
                                                                                {(tp_.additionalDocs && tp_.additionalDocs.length > 0) && (
                                                                                    <>
                                                                                        {this.getPreFundingAdditionalDocs(tp_.additionalDocs)}
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div className="col-lg-12">
                                                                                <div
                                                                                    className={tp_ && tp_.cslData && tp_.cslData.cslData && tp_.cslData.cslData.results && tp_.cslData.cslData.results.length ? "clickable-area" : ""}
                                                                                    onClick={(e) => {
                                                                                        if (tp_ && tp_.cslData && tp_.cslData.cslData && tp_.cslData.cslData.results && tp_.cslData.cslData.results.length) {
                                                                                            this.cslDetailsClick(e, tp_.cslData);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <label className="form-label">
                                                                                        <a className="anchorstyle">{this.props.t("csl_details_content.csl_title")} {(tp_ && tp_.cslData && tp_.cslData.cslData && tp_.cslData.cslData.results && tp_.cslData.cslData.results.length) ? <span style={{ fontWeight: "normal" }}>({this.props.t("click_anywhere_msg")})</span> : null}</a>
                                                                                    </label>
                                                                                    <span className="form-value">
                                                                                        {(tp_ && tp_.cslData && tp_.cslData.cslData )? (<>
                                                                                            {tp_.cslData.cslData.sources && tp_.cslData.cslData.sources.length > 0 && tp_.cslData.cslData.sources.map(
                                                                                                sr_ => {
                                                                                                    return (
                                                                                                        <div>{sr_.value} <span className="badge rounded-pill bg-success" style={{ color: "white" }}>{sr_.count}</span> </div>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                        </>) : (<>
                                                                                            <div>{this.props.t("no_data_found")}</div>
                                                                                        </>)}
                                                                                    </span>
                                                                                    {index !== tradingPartnerLists.length - 1 && tradingPartnerLists.length && <hr style={{ width: '100%', color: 'black' }} />}
                                                                                </div>
                                                                            </div>
                                                                        </>);
                                                                    })}

                                                                </div>
                                                            )

                                                            : <DocumentDetails
                                                            t={this.props.t}
                                                                customerDocuments={customerDocuments}
                                                                fundingDocuments={fundingDocuments}
                                                                batchDownload={this.batchDownload}
                                                                cslDetailsClick={this.cslDetailsClick}
                                                                FileViewerModalClick={this.FileViewerModalClick}
                                                                ViewSupplyChainModalClick={this.ViewSupplyChainModalClick}
                                                                creditLimitForTradingPartner={this.props.data && this.props.data.contracts && this.props.data.contracts.length > 0 && this.props.data.contracts[0].creditLimitForTradingPartner ? this.props.data.contracts[0].creditLimitForTradingPartner : {}}
                                                            />}
                                                        </>
                                                    );
                                                }
                                            })
                                        }
                                        {fundingType.map(type => {
                                            if (type == "TCF") {
                                                return (<></>);
                                            }
                                            else if (type == "ABL") {
                                                return (<></>);
                                            }
                                            else {
                                                return (
                                                    <>
                                                        {Object.keys(customerDocuments).length > 0 && (

                                                            <>
                                                                {Object.keys(customerDocuments).map(item => (
                                                                    <>
                                                                        {customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.financialDocuments && customerDocuments[item][0].customerDetails.financialDocuments.length > 0 && (
                                                                            <>
                                                                                {customerDocuments[item][0].customerDetails.financialDocuments.length > 0 ? (
                                                                                    <>
                                                                                        <label className="view-details-header py-3">{customerDocuments[item][0].customerDetails.name} {this.props.t("financial_documents")}
                                                                                            <span className="ml-2 badge badge-primary">
                                                                                                {customerDocuments[item][0].customerDetails.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                                            </span>
                                                                                            <span className="ml-2 badge badge-success">
                                                                                                {fileStatusBadgeText(customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.financialDocuments.length > 0 && customerDocuments[item][0].customerDetails.financialDocuments[0].source ? customerDocuments[item][0].customerDetails.financialDocuments[0].source : "")}
                                                                                            </span>
                                                                                        </label>
                                                                                        <span className="form-value">
                                                                                            <div className="row">
                                                                                                {this.getDropBoxProfileContent(customerDocuments[item][0].customerDetails.financialDocuments)}
                                                                                                {customerDocuments[item][0].customerDetails.accountingInformation && customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" && (
                                                                                                    <>
                                                                                                        {!customerDocuments[item][0].customerDetails.isAgingfile && (
                                                                                                            <React.Fragment>
                                                                                                                <div className="col-md-3">
                                                                                                                    <span className="form-value">
                                                                                                                        <a
                                                                                                                            className="anchorstyle"
                                                                                                                            href=""
                                                                                                                            onClick={(e) => this.getAggingReportDownload(e, customerDocuments[item][0].customerDetails.email, customerDocuments[item][0].customerDetails.accountingInformation, "buyer")}
                                                                                                                        >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )}

                                                                                                        {customerDocuments[item][0].customerDetails.payableAging && (
                                                                                                            <React.Fragment>
                                                                                                                <div className="col-md-3">
                                                                                                                    <span className="form-value">
                                                                                                                        <a
                                                                                                                            className="anchorstyle"
                                                                                                                            href=""
                                                                                                                            onClick={(e) => this.getPayableAgingReportDownload(e, customerDocuments[item][0].customerDetails.payableAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                                        >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </React.Fragment>
                                                                                                        )}

                                                                                                        {(customerDocuments[item][0].customerDetails.accountingInformation && customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks") &&
                                                                                                            (<React.Fragment>
                                                                                                                {(customerDocuments[item][0].customerDetails.bankStatement) && (
                                                                                                                    <div className="col-md-3">
                                                                                                                        <span className="form-value">
                                                                                                                            <a
                                                                                                                                className="anchorstyle text-uppercase"
                                                                                                                                href=""
                                                                                                                                onClick={(e) => this.getTransactionHistoryDownload(e, true, customerDocuments[item][0].customerDetails.bankStatement)}
                                                                                                                            >
                                                                                                                                 {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                                            </a>
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </React.Fragment>
                                                                                                            )}
                                                                                                        {(customerDocuments[item][0].customerDetails.payablesDetailsAging && customerDocuments[item][0].customerDetails.accountingInformation && (customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" || customerDocuments[item][0].customerDetails.accountingInformation == "D365BC")) &&
                                                                                                            <div className="col-md-3">
                                                                                                                <span className="form-value">
                                                                                                                    <a
                                                                                                                        className="anchorstyle text-uppercase"
                                                                                                                        href=""
                                                                                                                        onClick={(e) => this.payDetailAgingReportDownload(e, customerDocuments[item][0].customerDetails.payablesDetailsAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                                    >
                                                                                                                        {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                                                    </a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        }
                                                                                                        {(customerDocuments[item][0].customerDetails.receivableDetailsAging && customerDocuments[item][0].customerDetails.accountingInformation && (customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" || customerDocuments[item][0].customerDetails.accountingInformation == "D365BC")) &&
                                                                                                            <div className="col-md-3">
                                                                                                                <span className="form-value">
                                                                                                                    <a
                                                                                                                        className="anchorstyle text-uppercase"
                                                                                                                        href=""
                                                                                                                        onClick={(e) => this.recDetailAgingReportDownload(e, customerDocuments[item][0].customerDetails.receivableDetailsAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                                    >
                                                                                                                        {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                                                    </a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        }
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </span>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {!(!customerDocuments[item][0].customerDetails.isAgingfile ||
                                                                                            customerDocuments[item][0].customerDetails.payableAging ||
                                                                                            customerDocuments[item][0].customerDetails.payablesDetailsAging ||
                                                                                            customerDocuments[item][0].customerDetails.receivableDetailsAging) && (<>
                                                                                                <div className="col-lg-12">
                                                                                                    <label className="view-details-header pb-2">{customerDocuments[item][0].customerDetails.name} {this.props.t("financial_documents")}
                                                                                                        <span className="ml-2 badge badge-primary">
                                                                                                            Buyer
                                                                                                        </span>
                                                                                                    </label>
                                                                                                    <span className='form-value'>
                                                                                                    {this.props.t("documents_not_found_msg")}
                                                                                                    </span>
                                                                                                </div></>)}
                                                                                    </>
                                                                                )}
                                                                                {
                                                                                    (!customerDocuments[item][0].customerDetails.isAgingfile ||
                                                                                        customerDocuments[item][0].customerDetails.payableAging ||
                                                                                        customerDocuments[item][0].customerDetails.payablesDetailsAging ||
                                                                                        customerDocuments[item][0].customerDetails.receivableDetailsAging
                                                                                    ) && (customerDocuments[item][0].customerDetails.accountingInformation && customerDocuments[item][0].customerDetails.accountingInformation != "quickbooks") && (
                                                                                        <>
                                                                                            <label className="view-details-header pb-2">{customerDocuments[item][0].customerDetails.name} {this.props.t("financial_documents")}
                                                                                                <span className="ml-2 badge badge-primary">
                                                                                                    {customerDocuments[item][0].customerDetails.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                                                </span>
                                                                                                <span className="ml-2 badge badge-success">
                                                                                                    {fileStatusBadgeText(customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.accountingInformation ? customerDocuments[item][0].customerDetails.accountingInformation : "")}
                                                                                                </span>
                                                                                            </label>
                                                                                            <span className="form-value">
                                                                                                <div className="row">
                                                                                                    {!customerDocuments[item][0].customerDetails.isAgingfile && (
                                                                                                        <React.Fragment>
                                                                                                            <div className="col-md-3">
                                                                                                                <span className="form-value">
                                                                                                                    <a
                                                                                                                        className="anchorstyle"
                                                                                                                        href=""
                                                                                                                        onClick={(e) => this.getAggingReportDownload(e, customerDocuments[item][0].customerDetails.email, customerDocuments[item][0].customerDetails.accountingInformation, "buyer")}
                                                                                                                    >{this.props.t("profile_page.financial_information_content.receivable_aging_label")}</a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )}

                                                                                                    {customerDocuments[item][0].customerDetails.payableAging && (
                                                                                                        <React.Fragment>
                                                                                                            <div className="col-md-3">
                                                                                                                <span className="form-value">
                                                                                                                    <a
                                                                                                                        className="anchorstyle"
                                                                                                                        href=""
                                                                                                                        onClick={(e) => this.getPayableAgingReportDownload(e, customerDocuments[item][0].customerDetails.payableAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                                    >{this.props.t("profile_page.financial_information_content.payable_aging_label")}</a>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </React.Fragment>
                                                                                                    )}

                                                                                                    {(customerDocuments[item][0].customerDetails.accountingInformation && customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks") &&
                                                                                                        (<React.Fragment>
                                                                                                            {(customerDocuments[item][0].customerDetails.bankStatement) && (
                                                                                                                <div className="col-md-3">
                                                                                                                    <span className="form-value">
                                                                                                                        <a
                                                                                                                            className="anchorstyle text-uppercase"
                                                                                                                            href=""
                                                                                                                            onClick={(e) => this.getTransactionHistoryDownload(e, true, customerDocuments[item][0].customerDetails.bankStatement)}
                                                                                                                        >
                                                                                                                             {this.props.t("profile_page.financial_information_content.bank_transaction_label")}
                                                                                                                        </a>
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            )}
                                                                                                        </React.Fragment>
                                                                                                        )}
                                                                                                    {(customerDocuments[item][0].customerDetails.payablesDetailsAging && customerDocuments[item][0].customerDetails.accountingInformation && (customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" || customerDocuments[item][0].customerDetails.accountingInformation == "D365BC")) &&
                                                                                                        <div className="col-md-3">
                                                                                                            <span className="form-value">
                                                                                                                <a
                                                                                                                    className="anchorstyle text-uppercase"
                                                                                                                    href=""
                                                                                                                    onClick={(e) => this.payDetailAgingReportDownload(e, customerDocuments[item][0].customerDetails.payablesDetailsAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                                >
                                                                                                                    {this.props.t("profile_page.financial_information_content.payable_details_aging_label")}
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    }
                                                                                                    {(customerDocuments[item][0].customerDetails.receivableDetailsAging && customerDocuments[item][0].customerDetails.accountingInformation && (customerDocuments[item][0].customerDetails.accountingInformation == "quickbooks" || customerDocuments[item][0].customerDetails.accountingInformation == "D365BC")) &&
                                                                                                        <div className="col-md-3">
                                                                                                            <span className="form-value">
                                                                                                                <a
                                                                                                                    className="anchorstyle text-uppercase"
                                                                                                                    href=""
                                                                                                                    onClick={(e) => this.recDetailAgingReportDownload(e, customerDocuments[item][0].customerDetails.receivableDetailsAging, customerDocuments[item][0].customerDetails.accountingInformation)}
                                                                                                                >
                                                                                                                    {this.props.t("profile_page.financial_information_content.receivable_details_aging_label")}
                                                                                                                </a>
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </span>
                                                                                        </>
                                                                                    )
                                                                                }


                                                                                <div className="row">
                                                                                    <div className="col-lg-12">
                                                                                        <label className="view-details-header pb-2">{customerDocuments[item][0].customerDetails.name} {this.props.t("profile_page.financial_information_content.additional_document_title")}
                                                                                            <span className="ml-2 badge badge-primary">
                                                                                                {customerDocuments[item][0].customerDetails.role == "Supplier" ? "Supplier" : "Buyer"}
                                                                                            </span>
                                                                                            {customerDocuments[item][0].customerDetails.additionalDocuments && customerDocuments[item][0].customerDetails.additionalDocuments.length > 0 && (
                                                                                                <span className="ml-2 badge badge-success">
                                                                                                    {fileStatusBadgeText("")}
                                                                                                </span>
                                                                                            )}
                                                                                        </label>
                                                                                        <span className="form-value">
                                                                                            {customerDocuments[item][0].customerDetails.additionalDocuments && customerDocuments[item][0].customerDetails.additionalDocuments.length > 0
                                                                                                ? (<div className="row">
                                                                                                    {this.getDropBoxProfileContent(customerDocuments[item][0].customerDetails.additionalDocuments, "", true)}
                                                                                                </div>)
                                                                                                : (<div>{this.props.t("documents_not_found_msg")}</div>)
                                                                                            }
                                                                                        </span>
                                                                                    </div>
                                                                                </div>

                                                                            </>
                                                                        )}
                                                                    </>
                                                                ))}
                                                            </>
                                                        )}
                                                    </>
                                                )
                                            }
                                        })}
                                    </div>
                                )}
                            </div>
                        </div>                   
                    </>
                }   
            >     
            </CommonPopUpModal>
        </>           
        );
    }
}


const DocumentDetails = ({ customerDocuments, fundingDocuments, batchDownload, cslDetailsClick, FileViewerModalClick ,ViewSupplyChainModalClick,creditLimitForTradingPartner,t}) => {
    return (
        <React.Fragment>
            {Object.keys(customerDocuments).map((item, index) => (
                <div className="row pt-4">
                    <div className="col-lg-6">
                        <label className="view-details-header pb-2">{t("trading_partner_details.title")}</label>
                        {customerDocuments[item][0].customerDetails && (
                          
                          <UserDetails userDetails={customerDocuments[item][0].customerDetails} />
                        )}

                    </div>
                    <div className="col-lg-6">

                        {customerDocuments[item].map((doc,i) => (
                            <React.Fragment>
                                <label>
                                    {doc.documentType == "PO" ? t("purchase_order_to_be_financed") : t("grid_view_header_labels.invoice_amount")}
                                </label>
                                <strong className='form-value link-text-color'>
                                    {dollarFormatter.format(doc.total)}
                                </strong>
                                <label className='d-flex'>
                                    {doc.documentType == "PO" ? t("po_label.po_number") : t("invoice_labels.invoice_number") }
                                    <strong className='link-text-color'>{doc.documentId}</strong>
                                    {doc.approved && (
                                        <>
                                            <span className="ml-2 badge badge-success">
                                                {t("approved_by_buyer_label")}
                                            </span>
                                        </>
                                    )}
                                </label>
                                {doc.documentType == "SupplyChain" ? (<div className='d-flex'>
                                    <a className="anchorstyle mr-2" href={""} onClick={e => ViewSupplyChainModalClick(e, doc)}>{t("view_invoice_supporting_documents_label")}</a>
                                     <FileMetaDataPopover data={doc} /><br/>
                                </div>) : (<>
                                    {
                                        doc.documentFiles &&
                                        doc.documentFiles.map((document_, index) => {
                                            return <span
                                                key={index} className="d-flex">
                                                <a className="anchorstyle mr-2" href={""} onClick={e => FileViewerModalClick(e, document_)}>{doc.documentType == "PO" ? t("view_purchase_order") :t("view_invoice")}</a>
                                                <FileMetaDataPopover data={document_} />
                                            </span>
                                        })
                                    }
                                </>)}
                                {doc.buyerApprovedDocs && (
                                    <label className="form-label">
                                        {t("uploaded_buyer_approval_documents_label")}
                                    </label>
                                )}
                                {doc.buyerApprovedDocs &&
                                    doc.buyerApprovedDocs.map((document_, index) => {
                                        return <span
                                            key={index} className="form-value d-flex">
                                            <a className="anchorstyle mr-2" href={""} onClick={e => FileViewerModalClick(e, document_)}>{convertUTF8String(document_.filename)}</a>
                                            <FileMetaDataPopover data={document_} />
                                        </span>;
                                    })
                                }
                                {doc.purchaseOrder && doc.purchaseOrder.additionalDocs && doc.purchaseOrder.additionalDocs.length > 0 && (
                                    <>
                                        <label className="form-label">
                                            {t("supporting_documents_label")}
                                        </label>
                                        <>
                                            {doc.purchaseOrder.additionalDocs.map((document_, index) => {
                                                return <span
                                                    key={index} className="form-value d-flex">
                                                    <a className="anchorstyle mr-2" href={""} onClick={e => FileViewerModalClick(e, document_)}>{convertUTF8String(document_.filename)}</a>
                                                    <FileMetaDataPopover data={document_} />
                                                </span>;
                                            })}
                                        </>
                                    </>
                                )}
                                {process.env.REACT_APP_IS_CREDIT_LIMIT_ALLOWED == "true" && (<>
                                    {customerDocuments[item] && customerDocuments[item].length > 0 && (customerDocuments[item].length - 1 == i) && (
                                        <>
                                            {compareTransactionType(doc.documentType, transactionType.Invoice) && Object.keys(creditLimitForTradingPartner).length > 0 && (
                                                <div className='d-flex'>
                                                    {creditLimitForTradingPartner[doc.tradingPartnerId] && (
                                                        <> <div><b>Credit Limit:</b></div><strong className='form-value link-text-color'>{creditLimitForTradingPartner[doc.tradingPartnerId].creditLimitValue == 0 ? "No Credit Limit" : dollarFormatter.format(creditLimitForTradingPartner[doc.tradingPartnerId].creditLimitValue)}
                                                        </strong>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>)}
                            </React.Fragment>
                        ))}
                    </div>
                    <div className="col-lg-12">
                        <div
                            className={customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.cslData && customerDocuments[item][0].customerDetails.cslData.cslData && customerDocuments[item][0].customerDetails.cslData.cslData.results && customerDocuments[item][0].customerDetails.cslData.cslData.results.length ? "clickable-area" : ""}
                            onClick={(e) => {
                                if (customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.cslData && customerDocuments[item][0].customerDetails.cslData.cslData && customerDocuments[item][0].customerDetails.cslData.cslData.results.length) {
                                    cslDetailsClick(e, customerDocuments[item][0].customerDetails.cslData);
                                }
                            }}
                        >
                            <label className="form-label">
                                <a className="anchorstyle">{t("csl_details_content.csl_title")} {(customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.cslData && customerDocuments[item][0].customerDetails.cslData.cslData && customerDocuments[item][0].customerDetails.cslData.cslData.results && customerDocuments[item][0].customerDetails.cslData.cslData.results.length) ? <span style={{ fontWeight: "normal" }}>({t("click_anywhere_msg")})</span> : null}</a>
                            </label>
                            <span className="form-value">
                                {customerDocuments[item][0].customerDetails && customerDocuments[item][0].customerDetails.cslData && customerDocuments[item][0].customerDetails.cslData.cslData ? (<>
                                    {customerDocuments[item][0].customerDetails.cslData.cslData.sources && customerDocuments[item][0].customerDetails.cslData.cslData.sources.length > 0 && customerDocuments[item][0].customerDetails.cslData.cslData.sources.map(
                                        sr_ => {
                                            return (
                                                <div>{sr_.value} <span className="badge rounded-pill bg-success" style={{ color: "white" }}>{sr_.count}</span> </div>
                                            );
                                        }
                                    )}
                                </>) : (<>
                                    <div>{t("no_data_found")}</div>
                                </>)}
                            </span>
                            {index !== Object.keys(customerDocuments).length - 1 && Object.keys(customerDocuments).length && <hr style={{ width: '100%', color: 'black' }} />}
                        </div>
                    </div>

                </div>
            ))}
            {fundingDocuments && fundingDocuments.length > 0 && fundingDocuments[0].documentType == "Invoice" && (
                <div className="row">
                    <div className="col-lg-6">
                        <span className="form-value">
                            <a className="anchorstyle" href="" onClick={batchDownload}>{t("download_all_invoices")}</a>
                        </span>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

//.. added the customized css color ..//
const GreenStepIcon = (props) => {
    const { active, completed } = props;
    return (
        <StepIcon
            {...props}
            classes={{
                active: 'MuiStepIcon-active',
                completed: 'MuiStepIcon-completed',

            }}
            style={{
                color: active || completed ? 'green' : '',

            }}
        />
    );
};

