import React, { Component } from 'react'
import { withRouter,NavLink } from "react-router-dom";
import '../../common/List/List.css';
import AppState from '../../../contexts/AppState/AppState';
import API from '../../../services/API';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faSortDown
} from "@fortawesome/free-solid-svg-icons";
import Stepper from "react-stepper-horizontal";
import CheckmarkIcon from "../../../assets/icons/checkmark.png";
import CloseIcon from "../../../assets/icons/closeicon.png";
import {dollarFormatter,getStepForFRTimeline,localDateFormat,addDaysInSufixForPaymentTerm} from "../../../services/Utilities";
import { compareTransactionType,transactionType } from '../../../configs/GlobalConfig';
import EnumFundingRequestStatus from './EnumFundingRequestStatus';
import User from '../../../services/User';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

class FundingRequestListItem extends Component {
    api = new API();
    user =new User();
    _isLive = false;

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: false,
            isDocExpanded:false,
            selectedTCF: null,
        };
    }

    componentDidMount() {
        this._isLive = true;
    }

    componentWillUnmount() {
        this._isLive = false;
    }

    render() {
        const {
            index,
            fundingReqId,
            totalfundingAmount,
            fundingDocuments,
            contractId,
            fundingType,
            requesterDetails,
            isDeclined,
            contracts,
            isSupplyChainFinancing,
            isUsingExistingContract,
            isPreFundingRequest,
            isCreatedByImpersonateUser
        } = this.props;
        console.log(isCreatedByImpersonateUser);
        console.log("fundingReqId:",fundingReqId)
        const { isExpanded,isDocExpanded } = this.state;
        const status=this.props.status==3 && isDeclined?5:this.props.status
        const hasFundingAgreement = (contracts && contracts.length && contracts[0].fundingAggrement) ? true : false;
        const hasSignedAgreement = (contracts && contracts.length && contracts[0].signedAggrements) ? true : false;
        let _stepperItems=[
            {
                title: this.props.t("status_label.document_submitted"),
                icon: CheckmarkIcon
            },
            {
                title: this.props.t("status_label.requester_verification"),
                icon: CheckmarkIcon
            },
            {
                title: this.props.t("status_label.funding_request_created"),
                icon: CheckmarkIcon
            },
            {
                title:this.props.t("status_label.awaiting_funding_offers"),
                icon: CheckmarkIcon
            },
            {
                title:this.user.currentUserRole() === "funder"?this.props.t("status_label.funding_offer_sent"):this.props.t("status_label.funding_offers_received"),
                icon: status == EnumFundingRequestStatus.FundingRejected ? CloseIcon : CheckmarkIcon
            },
            {
                title:status == EnumFundingRequestStatus.RejectedFundingRequestOffer?this.props.t("status_label.offer_not_accepted"):this.props.t("status_label.accept_funding_offer"),
                icon: status == EnumFundingRequestStatus.RejectedFundingRequestOffer?CloseIcon : CheckmarkIcon
            }

        ];
        if (!isPreFundingRequest) {
            _stepperItems.push({
                title: this.props.t("status_label.paid_back"),
                icon: CheckmarkIcon
            });
        }
        return (
            <AppState.Consumer>
                {appState => (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                <div className="bg-white-1 wmy-2">
                                    <div className="card-body">
                                        <div className="row d-flex align-self-center">
                                            {appState.state.currentUserRole == "funder" && (
                                                <React.Fragment>
                                                    <div className="col-2 align-self-center text-muted cusName">
                                                        <label className="namelbl">{requesterDetails && requesterDetails.name ? requesterDetails.name : ""}
                                                            <span className="ml-2 badge badge-primary">
                                                                {requesterDetails.role=="Supplier"?"Supplier":"Buyer"}
                                                            </span>
                                                        </label>
                                                        <div>
                                                            <a href="" onClick={(e) => {
                                                                e.preventDefault();
                                                                if (this.props.callback) {
                                                                    this.props.callback("VIEW", index);
                                                                }
                                                            }}>
                                                               {fundingReqId}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {appState.state.currentUserRole == "supplier" && (
                                                <React.Fragment>
                                                    <div className="col-2 align-self-center text-center">
                                                    <label className="namelbl">{fundingReqId}</label>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <div className="col-2 align-self-center text-center">
                                                <label className="namelbl">{dollarFormatter.format(totalfundingAmount)}</label>
                                            </div>
                                            <div className="col-2 align-self-center text-center"
                                                onClick={() => {
                                                    if (!isPreFundingRequest) {
                                                        this.setState({ isDocExpanded: !isDocExpanded })
                                                    }
                                                }
                                                }
                                            ><label className="namelbl">
                                                    {fundingType == "TCF" && (
                                                        <>
                                                            {this.props.t("fundingType.taxcredit")} {isPreFundingRequest?<div className="progress-text-color">({this.props.t("fundingType.prefundingrequest")})</div>: `(${fundingDocuments.length})`}
                                                        </>
                                                    )}
                                                    {fundingType == "Invoice" && (
                                                        <>
                                                            {this.props.t("fundingType.invoice")} {isPreFundingRequest?<div className="progress-text-color">({this.props.t("fundingType.prefundingrequest")})</div>: `(${fundingDocuments.length})`}
                                                        </>
                                                    )}
                                                    {fundingType == "PO" && (
                                                        <>
                                                            {this.props.t("fundingType.po")} {isPreFundingRequest?<div className="progress-text-color">({this.props.t("fundingType.prefundingrequest")})</div>: `(${fundingDocuments.length})`}
                                                        </>
                                                    )}
                                                    {fundingType == "ABL" && (
                                                        <>
                                                            {this.props.t("fundingType.abl")}
                                                        </>
                                                    )}
                                                    {fundingType == "SupplyChain" && (
                                                        <>
                                                            {this.props.t("fundingType.supplychain")} {isPreFundingRequest?<div className="progress-text-color">({this.props.t("fundingType.prefundingrequest")})</div>: `(${fundingDocuments.length})`}
                                                        </>
                                                    )}
                                                </label>
                                                {(isPreFundingRequest) ? <></> : (<>
                                                    {isDocExpanded ? (
                                                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronUp} />
                                                    ) : (
                                                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronDown} />
                                                    )}
                                                </>)}
                                               
                                            </div>
                                            <div className="col-2 align-self-center text-center">
                                                <button
                                                    style={{
                                                        backgroundColor: 'transparent', border: 'none', borderInline: 'none', outline: 'none'
                                                    }}
                                                    onClick={() =>
                                                        this.setState({ isExpanded: !isExpanded })
                                                    }
                                                >
                                                    <StatusBadge
                                                        status={status}
                                                        extraText={status == EnumFundingRequestStatus.FundingAccepted ? (!hasFundingAgreement ? `(${this.props.t("waiting_for_funding_agreement")})`: (!hasSignedAgreement ? `(${this.props.t("waiting_for_signed_funding_agreement")})` : "") ) : ""}
                                                        currentUserRole={appState.state.currentUserRole}
                                                        isSupplyChain={isSupplyChainFinancing}
                                                        isPreFundingRequest={isPreFundingRequest}
                                                        t={this.props.t}
                                                    />
                                                    {isExpanded ? (
                                                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronUp} />
                                                    ) : (
                                                        <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faChevronDown} />
                                                    )}
                                                </button>
                                            </div>
                                            {appState.state.currentUserRole == "funder" && (
                                                <React.Fragment>
                                                    <div className="col-2 align-self-center text-center">
                                                        {contractId && (fundingType!="TCF") ? <NavLink className="anchorstyle" to="/fundingcontract">
                                                            {contractId}
                                                            {isUsingExistingContract && (
                                                                <><br/>
                                                                <div className='archived-text-color'><b>({this.props.t("additional_trading_partner_added")})</b></div>
                                                                </>
                                                            )}

                                                        </NavLink> : this.props.t("none")}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            <div className="col-2 d-flex justify-content-center align-self-center">
                                                <div className="btn-group actionBtn">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm dropdown-toggle"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        {this.props.t("grid_view_header_labels.actions")} <FontAwesomeIcon icon={faSortDown} />
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <button
                                                            className="dropdown-item"
                                                            type="button"
                                                            onClick={() => {
                                                                if (this.props.callback) {
                                                                    this.props.callback("VIEW", index);
                                                                }
                                                            }}
                                                        >
                                                            {(appState.state.currentUserRole === "supplier") &&
                                                                <div>
                                                                    {this.props.t("grid_view_action_labels.view_documents")}
                                                                </div>
                                                            }
                                                            {(appState.state.currentUserRole === "funder") &&
                                                                <div>
                                                                    {this.props.t("grid_view_action_labels.view_funding_request")}
                                                                </div>
                                                            }

                                                        </button>
                                                        {(appState.state.currentUserRole === "funder") && !isCreatedByImpersonateUser && (
                                                            <div>
                                                                <button
                                                                    className="dropdown-item"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (this.props.callback) {
                                                                            this.props.callback("MESSAGE", index);
                                                                        }
                                                                    }}
                                                                >
                                                                     {this.props.t("grid_view_action_labels.message_requester")}
                                                                </button>
                                                            </div>
                                                        )}
                                                        {(appState.state.currentUserRole === "supplier") &&
                                                            status >= EnumFundingRequestStatus.AwaitingFundingOffer && (
                                                                <div>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("MESSAGE", index);
                                                                            }
                                                                        }}
                                                                    >
                                                                        Message Funder
                                                                    </button>
                                                                </div>
                                                            )}
                                                        {appState.state.currentUserRole === "supplier" && !contractId &&
                                                            (status >1 && status <5) && (
                                                            <React.Fragment>
                                                                <button
                                                                    className="dropdown-item"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        if (this.props.callback) {
                                                                            this.props.callback("ViewFunders", index);
                                                                        }
                                                                    }}
                                                                >
                                                                    View Matched Funders 
                                                                </button>
                                                            </React.Fragment>
                                                            )
                                                        }
                                                        {appState.state.currentUserRole === "supplier" && 
                                                            status === EnumFundingRequestStatus.FundingOffered && (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("fundOfferView", index);
                                                                            }

                                                                        }}
                                                                    >
                                                                        View Funding Offer
                                                                    </button>
                                                                </React.Fragment>
                                                            )
                                                        }

                                                        {appState.state.currentUserRole === "funder" &&  !contractId &&
                                                            status === EnumFundingRequestStatus.AwaitingFundingOffer && (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("MAKEOFFER", index);
                                                                            }
                                                                        }
                                                                        }
                                                                    >
                                                                        {this.props.t("grid_view_action_labels.make_offer")}
                                                                    </button>
                                                                </React.Fragment>
                                                            )}
                                                        {appState.state.currentUserRole === "funder" && !contractId &&
                                                            status === EnumFundingRequestStatus.AwaitingFundingOffer && (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("DeclineReq", index);
                                                                            }
                                                                        }
                                                                        }
                                                                    >
                                                                        {this.props.t("grid_view_action_labels.decline_request")}
                                                                    </button>
                                                                </React.Fragment>
                                                            )}
                                                        {appState.state.currentUserRole === "funder" && contractId &&
                                                            status === EnumFundingRequestStatus.AwaitingFundingOffer && (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("AcceptReq", index);
                                                                            }
                                                                        }
                                                                        }
                                                                    >
                                                                        {this.props.t("grid_view_action_labels.accept_request")}
                                                                    </button>
                                                                </React.Fragment>
                                                            )}
                                                        {appState.state.currentUserRole === "funder" && contractId &&
                                                            status === EnumFundingRequestStatus.AwaitingFundingOffer && (
                                                                <React.Fragment>
                                                                    <button
                                                                        className="dropdown-item"
                                                                        type="button"
                                                                        onClick={() => {
                                                                            if (this.props.callback) {
                                                                                this.props.callback("DeclineReq", index);
                                                                            }
                                                                        }}
                                                                    >
                                                                        {this.props.t("grid_view_action_labels.decline_request")}
                                                                    </button>
                                                                </React.Fragment>
                                                        )}

                                                        {(appState.state.currentUserRole === "funder" &&
                                                        hasFundingAgreement &&
                                                        hasSignedAgreement &&
                                                        (status === EnumFundingRequestStatus.FundingAccepted  ||
                                                        status === EnumFundingRequestStatus.FundingRequestApprovedExistingContract)) && (
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => {
                                                                    if (this.props.callback) {
                                                                        this.props.callback("Paid", index);
                                                                    }
                                                                }}
                                                            >
                                                                {this.props.t("grid_view_action_labels.mark_as_paid")}
                                                            </button>
                                                        )}

                                                        {(appState.state.currentUserRole === "funder" &&
                                                        !hasFundingAgreement && status === EnumFundingRequestStatus.FundingAccepted) && (
                                                            <button
                                                                className="dropdown-item"
                                                                type="button"
                                                                onClick={() => {
                                                                    if (this.props.callback) {
                                                                        this.props.callback("FUNDING_AGREEMENT", index);
                                                                    }
                                                                }}
                                                            >
                                                                {this.props.t("grid_view_action_labels.upload_funding_agreement")}
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {isDocExpanded && (
                                                <FundingDocuments fundingType={fundingType} fundingDocuments={fundingDocuments} index={index} values={this.props} t={this.props.t}/>
                                            )}
                                            {isExpanded && (
                                                <div className="row mt-3 d-flex align-self-center" style={{ width: "100%" }}>
                                                    <div className="col align-self-center title-padding">
                                                        <Stepper
                                                            circleFontSize={6}
                                                            completeColor="#002f53"
                                                            activeColor={(status == EnumFundingRequestStatus.FundingRejected  || EnumFundingRequestStatus.RejectedFundingRequestOffer)? "#dc3545" : "#002f53"}
                                                            defaultColor="#fff"
                                                            defaultBorderColor="#e0e0e0"
                                                            defaultBorderStyle="solid"
                                                            defaultBorderWidth={1}
                                                            onClick={() =>
                                                                this.setState({ isExpanded: !isExpanded })
                                                            }
                                                            steps={_stepperItems}
                                                            activeStep={getStepForFRTimeline(status)}
                                                            
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </AppState.Consumer>
        )
    }
}

export default compose(withRouter,withTranslation())(FundingRequestListItem);


const StatusBadge = ({ status, currentUserRole, extraText ,isSupplyChain,isPreFundingRequest,t}) => {
    switch (status) {
        case EnumFundingRequestStatus.Submitted:
            return (
                <span className="badge badge-pill-1 badge-secondary p-2">
                    {t("status_label.awaiting_submission")}
                </span>
            );
        case EnumFundingRequestStatus.MidDeskVerification:
            return (
                <span className="badge badge-pill-1 badge-warning p-2">
                    {t("status_label.in_review")}
                </span>
            );
        case EnumFundingRequestStatus.CreateFundingRequest:
            return (
                <span className="badge badge-pill-1 badge-primary p-2">
                    {t("status_label.funding_request_created")}
                </span>
            );
        case EnumFundingRequestStatus.AwaitingFundingOffer:
            return (
                <span className="badge badge-pill-1 badge-warning p-2">
                    {t("status_label.awaiting_funding_offer")}
                </span>
            );
        case EnumFundingRequestStatus.FundingOffered:
            return (
                <span className="badge badge-pill-1 badge-success p-2">
                    {currentUserRole == "funder" ? t("status_label.funding_offer_made") : t("status_label.funding_offer_received")}
                </span>
            );
        case EnumFundingRequestStatus.FundingRejected:
            return (
                <span className="badge badge-pill-1 badge-danger p-2">
                    {t("status_label.funding_declined")}
                </span>
            );
        case EnumFundingRequestStatus.FundingAccepted:
            return (
                <span className="badge badge-pill-1 badge-primary p-2 btn-white-space">
                  {isSupplyChain?t("status_label.buyer_accepted_funding_offer"):t("status_label.supplier_accepted_funding_offer")}   {extraText ? <div className="mt-1"><br /> {extraText}</div> : null}
                </span>
            );

        case EnumFundingRequestStatus.Repaid:
            return (
                <span className="badge badge-pill-1 badge-success p-2">
                   {isPreFundingRequest?t("status_label.supplier_accepted_funding_offer"):t("status_label.paid_back")}
                </span>
            );
        case EnumFundingRequestStatus.RejectedFundingRequestOffer:
            return (
                <span className="badge badge-pill-1 badge-danger p-2">
                    {t("status_label.offer_not_accepted")}
                </span>
            );
        case EnumFundingRequestStatus.FundingRequestApprovedExistingContract:
            return (
                <span className="badge badge-pill-1 badge-primary p-2 btn-white-space">
                    {t("status_label.funding_request_approved_under_funding_contract")}
                </span>
            );
        default:
            return (
                <span className="badge badge-pill-1 badge-danger p-2">
                    {t("status_label.unknown")}
                </span>
            );
    }
};

const FundingDocuments = ({ fundingType, fundingDocuments,index ,values,t}) => {
    return (
        <div className="row mt-3 d-flex align-self-center" style={{ width: "100%" }}>
            <div className="col align-self-center">
                <div className="document-border">
                    <React.Fragment>
                        <div className="row d-flex align-self-center">
                            <div className="col-2 align-self-center text-center">
                                {fundingType == "TCF" && (
                                    <span className="blue-text text-uppercase" >Tax Year</span>
                                )}
                                {(fundingType == "Invoice" || fundingType == "SupplyChain") && (
                                    <span className="blue-text text-uppercase" >{t("grid_view_header_labels.invoice_number")}</span>
                                )}
                                {fundingType == "PO" && (
                                    <span className="blue-text text-uppercase" >{t("grid_view_header_labels.po_number")}</span>
                                )}
                                {fundingType == "ABL" && (
                                    <span className="blue-text text-uppercase" >Asset #</span>
                                )}
                            </div>
                            <div className="col-2 align-self-center text-center">
                                {fundingType == "TCF" && (
                                    <span className="blue-text text-uppercase" >Tax Credit to be Pre-Funded</span>
                                )}
                                {(fundingType == "Invoice" || fundingType == "SupplyChain") && (
                                    <span className="blue-text text-uppercase" >{t("grid_view_header_labels.invoice_amount")}</span>
                                )}
                                {fundingType == "PO" && (
                                    <span className="blue-text text-uppercase" >{t("grid_view_header_labels.po_amount")}</span>
                                )}
                                {fundingType == "ABL" && (
                                    <span className="blue-text text-uppercase" >Collateral Amount</span>
                                )}
                            </div>
                            <div className="col-2 align-self-center text-center">
                                {fundingType != "Invoice" && (
                                    <span className="blue-text text-uppercase" >{t("grid_view_header_labels.created_at")}</span>
                                )}
                                {fundingType == "Invoice" && (
                                    <span className="blue-text text-uppercase" >{t("grid_view_header_labels.due_date")}</span>
                                )}
                            </div>
                            <div className="col-2 align-self-center text-center">
                                {(fundingType == "Invoice" || fundingType == "SupplyChain") && (
                                    <span className="blue-text text-uppercase" >{t("invoice_labels.payment_term")}</span>
                                )}
                            </div>
                        </div>
                        
                        {fundingDocuments.map((value,i) => (
                            <div className="row mt-2 d-flex align-self-center">
                                <div className="col-2 align-self-center text-center">
                                    <a href="" onClick={(e) => {
                                        e.preventDefault();
                                        if(fundingType=="Invoice"){
                                            values.callback("viewinvoicemodal", index,i);
                                        }else if(fundingType=="PO"){
                                            values.callback("viewpurchaseordermodal", index,i);
                                        }
                                        else if(fundingType=="SupplyChain"){
                                            values.callback("viewsupplychainmodal", index,i);
                                        }
                                     }}>
                                        <label className="namelbl">{value.documentId}</label>
                                    </a>
                                </div>
                                <div className="col-2 align-self-center text-center">
                                    <label className="namelbl">{dollarFormatter.format(value.total)}</label>
                                </div>
                                <div className="col-2 align-self-center text-center">
                                    {value.documentType != "Invoice" && (
                                        <label className="namelbl">{localDateFormat(value.createdAt)}</label>
                                    )}
                                    {value.documentType == "Invoice" && (
                                        <label className="namelbl">{localDateFormat(value.documentDueDate)}</label>
                                    )}
                                </div>
                                <div className="col-2 align-self-center text-center">
                                    {(value.documentType == "Invoice" || value.documentType=="SupplyChain") && (
                                        <label className="namelbl">
                                            {compareTransactionType(transactionType.Invoice, value.documentType)?addDaysInSufixForPaymentTerm(value.invoice.net):addDaysInSufixForPaymentTerm(value.supplyChain.net)}
                                        </label>
                                    )}
                                </div>
                            </div>
                        )
                        )}
                    </React.Fragment>
                </div>
            </div>
        </div>
    );
}