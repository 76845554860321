import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import { withTranslation } from 'react-i18next';

class PaymentTermsInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            error: '',
            isPrefunding: this.props.isPrefunding
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange = (e) => {
        e.preventDefault();
        const inputValue = e.target.value;
        const minInputLength = 1;
        const maxInputLength = 3;

        if (e.target.name === "Terms" && !this.props.isReadOnly) {
            if (inputValue === "") {
                this.setState({
                    error: ""
                });
                this.props.onDataChange(inputValue);
            } else if (inputValue <= 0 || inputValue > 365) {
                this.setState({
                    error: this.props.t("acceptable_values_for_payment_term_error_msg")
                });
                this.props.onDataChange(inputValue);
            } else if (inputValue.length >= minInputLength && inputValue.length <= maxInputLength) {
                this.setState({
                    error: ""
                });
                this.props.onDataChange(inputValue);
            } else {
                this.props.onDataChange("");
                this.setState({
                    error: this.props.t("accept_only_numeric_value_msg")
                });
            }
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Backspace' || e.key === 'Tab') {
            return;
        }
        if (!/^\d$/.test(e.key)) {
            e.preventDefault();
        }
    }

    render() {
        const { isPrefunding } = this.state
        const InputComponent = isPrefunding ? TextField : 'input';
        return (
            <div >
                <div className="label-container">
                    <label className={`form-label-style ${isPrefunding ? 'required' : ''}`} htmlFor="name">{this.props.t("invoice_labels.payment_terms")}</label>
                    {isPrefunding && <small className="sub-text text-muted ml-2 ">({this.props.t("fundingrequests_page.pre_funding_request_content.payment_terms_message")})</small>}
                </div>
                <div >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <InputComponent
                            type="text"
                            size="small"
                            className="form-control"
                            autoComplete="off"
                            name="Terms"
                            pattern="[0-9]+"
                            placeholder={this.props.t("please_enter_a_value")}
                            required
                            maxLength="4"
                            disabled={this.props.isReadOnly}
                            value={isPrefunding ? this.props.preFundterms : this.props.isReadOnly ?
                                this.props.value : (this.state.data[0]?.Terms !== undefined ? this.state.data[0].Terms : "")}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleKeyPress}
                            InputProps={{
                                inputProps: { maxLength: 4 },
                            }} />
                        <div className="input-group-append">
                            <span className="input-group-text">{this.props.t("common_names.days")}</span>
                        </div>
                    </div>
                    {this.state.error && <div className="error-message"><small style={{ color: 'red' }}>{this.state.error}</small></div>}
                </div>
            </div>
        );
    }
}

export default withTranslation()(PaymentTermsInput);
