import { AzureKeyCredential, DocumentAnalysisClient } from "@azure/ai-form-recognizer";
import { localDateFormat,convertDocumentDate} from "./Utilities";
import { isUSCountryUser, parseNetTermToDays, ShortText } from "./Utilities";
import { allCountryDetails } from "./Utilities";


export const extractPdfFile = async (file_) => {
    const endpoint = process.env.REACT_APP_FORM_RECOGNIZER_ENDPOINT;
    const apiKey = process.env.REACT_APP_FORM_RECOGNIZER_KEY;
    const credential = new AzureKeyCredential(apiKey);
    const client = new DocumentAnalysisClient(endpoint, credential);
    const poller = await client.beginAnalyzeDocumentFromUrl("prebuilt-invoice", file_);
    return await poller.pollUntilDone();
}

export const extractEmailAddresses = (text) => {
    let objectString = JSON.stringify(text);
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const emailAddresses = objectString.match(emailRegex) || [];
    return emailAddresses;

}

export const extractPhoneNumber = (text) => {
    let objectString = JSON.stringify(text);
    const regex = /(?:[-+() ]*\d){10,13}/g;
    const matches = objectString.match(regex);
    console.log(matches, "matches>>>");
    return matches;
}

export const convertToJSONInvoiceData=(column)=> {
    let invObj = {};
    for (let columnName_ in column) {
        switch (columnName_) {
            case "CustomerAddress":
                invObj["buyerAddress"] = column[columnName_].value;
                break;
            case "ShippingAddress":
                invObj["shippingAddress"] = column[columnName_].value;
                break;
            case "BillingAddress":
                invObj["billingAddress"] = column[columnName_].value;
                break;
            case "InvoiceTotal":
                invObj["total"] = column[columnName_].value && column[columnName_].value.amount?column[columnName_].value.amount.toString():"";
                break;
            case "SubTotal":
                invObj["subTotal"] = column[columnName_].value && column[columnName_].value.amount?column[columnName_].value.amount.toString():"";
                break;
            case "CustomerId":
                invObj["customerId"] = column[columnName_].value;
                break;
            case "CustomerName":
                invObj["buyerName"] = column[columnName_].value;
                break;
            case "ShippingAddressRecipient":
                invObj["shippingCustomerName"] = column[columnName_].value;
                break;
            case "BillingAddressRecipient":
                invObj["billingCustomerName"] = column[columnName_].value;
                break;
            case "VendorName":
                invObj["SupplierCompanyName"] = column[columnName_].value;
                break;
            case "InvoiceDate":
                invObj["createdAt"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "InvoiceId":
                invObj["Invoice#"] = column[columnName_].value;
                break;
            case "DueDate":
                invObj["DueDate"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "PaymentTerm":
                invObj["Terms"] = column[columnName_].value;
                break;
            case "Items":
                invObj["items"] = buildLineItemsArray(column[columnName_].values);
                    break;
            default:
                invObj[columnName_] =  column[columnName_].value;

        }
    }
    if(invObj.buyerAddress){
        invObj.buyerAddress=invObj.buyerAddress;
    }
    else if(invObj.shippingAddress){
        invObj.buyerAddress=invObj.shippingAddress;
    }
    else if(invObj.billingAddress){
        invObj.buyerAddress=invObj.billingAddress;
    }
    if(invObj.buyerName){
        invObj.buyerName=invObj.buyerName;
    }
    else if(invObj.shippingCustomerName){
        invObj.buyerName=invObj.shippingCustomerName;
    }
    else if(invObj.billingCustomerName){
        invObj.buyerName=invObj.billingCustomerName;
    }
    invObj["customerDetails"] = buildAddressDetails(invObj.buyerAddress,invObj.buyerName,invObj.email);
    invObj["items"] =   invObj.items && invObj.items.length>0?invObj.items:[];
    //invObj.items=invObj.items.filter(item_=> item_.subTotal.length!=0 && item_.descr.length!=0 && item_.qty.length!=0 && item_.price.length!=0);
    return invObj;
}


export const convertToJSONPOData=(column)=> {
    let invObj = {};
    for (let columnName_ in column) {
        switch (columnName_) {
            case "VendorAddress":
                invObj["buyerAddress"] = column[columnName_].value;
                break;
            case "InvoiceTotal":
                invObj["total"] = column[columnName_].value.amount;
                break;
            case "SubTotal":
                invObj["subTotal"] = column[columnName_].value.amount;
                break;
            case "VendorName":
                invObj["buyerName"] = column[columnName_].value;
                break;
            case "InvoiceDate":
                invObj["createdAt"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "InvoiceId":
                invObj["po#"] = column[columnName_].value;
                break;
            case "DueDate":
                invObj["DueDate"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "PaymentTerm":
                invObj["Terms"] = column[columnName_].value;
                break;
            default:
                invObj[columnName_] =  column[columnName_].value;

        }
    }
    invObj["customerDetails"] = buildAddressDetails(invObj.buyerAddress,invObj.buyerName,invObj.email);
    invObj["items"] =   invObj.items && invObj.items.length>0?invObj.items:[];
    return invObj;
}


export const convertToJSONSupplyChainData=(column)=> {
    let invObj = {};
    for (let columnName_ in column) {
        switch (columnName_) {
            case "CustomerAddress":
                invObj["buyerAddress"] = column[columnName_].value;
                break;
            case "VendorAddress":
                invObj["supplierAddress"] = column[columnName_].value;
                break;
            case "InvoiceTotal":
                invObj["total"] = column[columnName_].value.amount;
                break;
            case "SubTotal":
                invObj["subTotal"] = column[columnName_].value.amount;
                break;
            case "CustomerId":
                invObj["customerId"] = column[columnName_].value;
                break;
            case "CustomerName":
                invObj["buyerName"] = column[columnName_].value;
                break;
            case "VendorName":
                invObj["SupplierCompanyName"] = column[columnName_].value;
                break;
            case "InvoiceDate":
                invObj["createdAt"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "InvoiceId":
                invObj["Invoice#"] = column[columnName_].value;
                break;
            case "DueDate":
                invObj["DueDate"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "PaymentTerm":
                invObj["Terms"] = column[columnName_].value;
                break;
            case "Items":
                    invObj["items"] = buildLineItemsArray(column[columnName_].values);
                    break;
            default:
                invObj[columnName_] =  column[columnName_].value;
        }
    }
    invObj["customerDetails"] = buildAddressDetails(invObj.buyerAddress,invObj.buyerName,invObj.email);
    invObj["items"] =   invObj.items && invObj.items.length>0?invObj.items:[];
   // invObj.items=invObj.items.filter(item_=> item_.subTotal.length!=0 && item_.descr.length!=0 && item_.qty.length!=0 && item_.price.length!=0);
    return invObj;
}


export const convertToJSONPoBuyerData=(column)=> {
    let invObj = {};
    for (let columnName_ in column) {
        switch (columnName_) {
            case "CustomerAddress":
                invObj["supplierAddress"] = column[columnName_].value;
                break;
            case "VendorAddress":
                invObj["buyerAddress"] = column[columnName_].value;
                break;
            case "InvoiceTotal":
                invObj["poAmount"] = column[columnName_].value.amount;
                break;
            case "SubTotal":
                invObj["subTotal"] = column[columnName_].value.amount;
                break;
            case "CustomerId":
                invObj["customerId"] = column[columnName_].value;
                break;
            case "CustomerName":
                invObj["SupplierCompanyName"] = column[columnName_].value;
                break;
            case "VendorName":
                invObj["buyerName"] = column[columnName_].value;
                break;
            case "InvoiceDate":
                invObj["poDate"] = column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "InvoiceId":
                invObj["poNumber"] = column[columnName_].value;
                break;
            case "DueDate":
                invObj["DueDate"] =column[columnName_].value?localDateFormat(convertDocumentDate(column[columnName_].value)):localDateFormat(convertDocumentDate(new Date()));
                break;
            case "PaymentTerm":
                invObj["Terms"] = column[columnName_].value;
                break;
            default:
                invObj[columnName_] =  column[columnName_].value;

        }
    }
    invObj["supplierDetails"] =buildAddressDetails(invObj.supplierAddress,invObj.SupplierCompanyName,invObj.email);
    invObj["supplierName"]=invObj.supplierDetails && invObj.supplierDetails.name?invObj.supplierDetails.name:"";
    invObj["supplierEmail"]=invObj.supplierDetails && invObj.supplierDetails.email?invObj.supplierDetails.email:"";
    return invObj;
}

const buildLineItemsArray=(lineItemsArray_)=>{
    let _itemsArray=[];
    for(let item of lineItemsArray_){
        let _lineItemPropertiesObj={};
        if(item.properties){
            let _properties=item.properties;
            _lineItemPropertiesObj["subTotal"]=_properties &&_properties.Amount && _properties.Amount.value && _properties.Amount.value.amount?_properties.Amount.value.amount.toString():"";
            _lineItemPropertiesObj["descr"]=_properties &&_properties.Description && _properties.Description.value?_properties.Description.value:"";
            _lineItemPropertiesObj["item"]=_properties &&_properties.ProductCode && _properties.ProductCode.value?_properties.ProductCode.value:"";
            _lineItemPropertiesObj["qty"]=_properties &&_properties.Quantity && _properties.Quantity.value?_properties.Quantity.value.toString():"";
            _lineItemPropertiesObj["price"]=_properties && _properties.UnitPrice && _properties.UnitPrice.value && _properties.UnitPrice.value.amount?_properties.UnitPrice.value.amount.toString():"";
            if(_lineItemPropertiesObj.subTotal.length==0 && 
                _lineItemPropertiesObj.descr.length ==0 &&
                _lineItemPropertiesObj.item.length==0 &&
                _lineItemPropertiesObj.qty.length==0 && 
                _lineItemPropertiesObj.price.length ==0){

                }
                else{
                    _itemsArray.push(_lineItemPropertiesObj);
                }

        }
    }
    return _itemsArray;
}

const buildAddressDetails=(details_,name_,email_)=>{
    let customerDetails = {};
    customerDetails["name"] = name_?name_:"";
    customerDetails["email"] = email_?email_:"";
    customerDetails["address1"] =details_ && details_.streetAddress?details_.streetAddress:"";
    customerDetails["city"] =details_ && details_.city?details_.city:"";
    customerDetails["region"] =details_ && details_.state?details_.state:"";
    customerDetails["postalCode"] =details_ && details_.postalCode?details_.postalCode:"";
    customerDetails["phone"] =details_ && details_.phone?details_.phone:"";
    customerDetails["country"] =details_ && details_.countryRegion?details_.countryRegion:"";
    return customerDetails;
}

export const processFile = async (
  file,
  convertToJson,
  acceptedFiles,
  onFileExtracted
) => {
  const { documents, pages, keyValuePairs } = await extractPdfFile(file);

  if (convertToJson === "invoice") {
    let _invoiceDetails = convertToJSONInvoiceData(documents[0].fields);
    var termsNum =
      _invoiceDetails && _invoiceDetails.Terms
        ? parseNetTermToDays(_invoiceDetails.Terms)
        : 30;
    _invoiceDetails["Terms"] = termsNum;
    let salesTax_;
    for (const { key, value } of keyValuePairs) {
      if (key.content === "SALES TAX") {
        salesTax_ = value.content;
        _invoiceDetails.tax = salesTax_.toString();
      }
    }
    _invoiceDetails.tax = _invoiceDetails.tax ? _invoiceDetails.tax : "";
    if (documents.length > 0 && pages.length > 0) {
      //const phoneNumbers = extractPhoneNumber(pages[0].lines);
      const emailField_ = extractEmailAddresses(
        pages[0].lines.filter((x) => x.content)
      );
      if (_invoiceDetails && _invoiceDetails.customerDetails) {
        _invoiceDetails.customerDetails.email =
          emailField_ && emailField_.length > 0 ? emailField_[1] : "";
      }
      _invoiceDetails.supplierEmail =
        emailField_ && emailField_.length > 0 ? emailField_[0] : "";

      let _allCountryDetails = allCountryDetails();
      let _country =
        _invoiceDetails.customerDetails &&
          _invoiceDetails.customerDetails["country"]
          ? _invoiceDetails.customerDetails.country
          : "United States";
      const isValidCountry = _country.trim() !== "";
      const _state =
        _invoiceDetails.customerDetails &&
          _invoiceDetails.customerDetails["region"]
          ? _invoiceDetails.customerDetails.region
          : "";
      let _isValidState = _country.trim() !== "";
      let _updatedState = null;
      let _updatedCountry = null;
      _country = isUSCountryUser(_country) ? "United States" : _country;
      _allCountryDetails.forEach((c_) => {
        if (
          c_.countryName.toString().toLowerCase() ===
          _country.toString().toLowerCase() ||
          (_country.toString.length === 2 &&
            _country.toString().toLowerCase() ===
            c_.countryShortCode.toLowerCase())
        ) {
          _updatedCountry = c_.countryName;
          c_.stateList.forEach((st_) => {
            if (
              st_.stateName.toLowerCase() === _state.toString().toLowerCase()
            ) {
              _updatedState = st_.stateName;
              _isValidState = true;
            } else if (
              st_.stateCode.toLowerCase() === _state.toString().toLowerCase()
            ) {
              _updatedState = st_.stateName;
              _isValidState = true;
            }
          });
        }
      });
      if (_updatedState) {
        _invoiceDetails.customerDetails["region"] = _updatedState;
      }
      if (_updatedCountry) {
        _invoiceDetails.customerDetails["country"] = _updatedCountry;
      }
      onFileExtracted({
        data: [_invoiceDetails],
        isValidCountry,
        isValidState: _isValidState,
        invoiceFile: acceptedFiles,
      });
    } else {
      console.log("Email field not found in the invoice.");
    }
  } else if (convertToJson == "po" || convertToJson == "poBuyer") {
    let _poDetails;
    if (convertToJson == "poBuyer") {
      _poDetails = convertToJSONPoBuyerData(documents[0].fields);

      if (documents.length > 0 && pages.length > 0) {
        const emailField_ = extractEmailAddresses(
          pages[0].lines.filter((x) => x.content)
        );
        _poDetails.supplierEmail =
          emailField_ && emailField_.length > 0 ? emailField_[0] : "";
      }
    } else if (convertToJson == "po") {
      _poDetails = convertToJSONPOData(documents[0].fields);
    }
    onFileExtracted({
      invoiceFile: acceptedFiles,
      poAmount: _poDetails["total"],
      poNumber: _poDetails["po#"],
      data: [_poDetails],
      uploadFileName: ShortText(acceptedFiles[0].name),
      isPdfParsing: false,
    });
  } else if (convertToJson === "sc") {
    let _scDetails = convertToJSONSupplyChainData(documents[0].fields);
    var termsNum =
      _scDetails && _scDetails.Terms
        ? parseNetTermToDays(_scDetails.Terms)
        : 30;
    _scDetails["Terms"] = termsNum;
    let salesTax_;
    for (const { key, value } of keyValuePairs) {
      if (key.content === "SALES TAX") {
        salesTax_ = value.content;
        _scDetails.tax = salesTax_.toString();
      }
    }
    _scDetails.tax = _scDetails.tax ? _scDetails.tax : "";
    if (documents.length > 0 && pages.length > 0) {
      const emailField_ = extractEmailAddresses(
        pages[0].lines.filter((x) => x.content)
      );
      _scDetails.supplierEmail =
        emailField_ && emailField_.length > 0 ? emailField_[0] : "";
      if (_scDetails && _scDetails.customerDetails) {
        _scDetails.customerDetails.email =
          emailField_ && emailField_.length > 0 ? emailField_[1] : "";
      }
    }
    onFileExtracted({
      data: [_scDetails],
      uploadFileName: acceptedFiles[0].name,
      fileToUpload: acceptedFiles,
      scFile: acceptedFiles,
    });
  }
};
