import React, { Component } from 'react';
import DashboardWidgetContainer from './DashboardWidgetContainer';
import API from '../../../services/API';
import { withRouter, NavLink } from "react-router-dom";
import moment from 'moment';
import FullSpinner from "../Spinner/FullSpinner";
import User from '../../../services/User';
import NotificationService from '../../../services/NotificationService';

export default class TaskListWidget extends Component {
    api = new API();
    user = new User();
    constructor(props_) {
        super(props_);
        this.state = {
            isFinancialInfo: false,
            isAgingReport: false,
            isSupplierdocs: false,
            isBankStatement: false,
            isLoading: false,
            openInvoices: [],
            openFundingReqInvoices: [],
            expireContracts: [],
            terminatedContracts: [],
            currentPathlocation: "",
            taskApiCallDoneFlag: false,
            waitingFundingAgreement: [],
            pendingSignedContracts: [],
            awaitFundingOfferList: [],
            markAsPaidList: [],
            waitFundingAgreementList: [],
            approveDeclineList: [],
            offerReceivedFundingReqList: []
        };
    }

    componentDidMount() {
        if (this.user.currentUserRole() == "supplier") {
            this.getProfile();
            this.getInvoices();
            this.getContract();
        }
        this.getFundingRequest();
    }

    async getProfile() {
        const profileResponse = await this.api.getV2Profile();
        let isFinancialInformation = false;
        if (profileResponse.user.taxId && profileResponse.user.revenue) {
            isFinancialInformation = true;
        }
        const res = await this.api.getSupplierDocs();
        this.setState({
            isFinancialInfo: isFinancialInformation,
            isSupplierdocs: res.items ? res.items.length >= 3 : false
        });

        let aging = res.items.filter(
            x => x.financialDocumentType === "AGING_REPORT"
        );
        if (aging.length == 0) {
            await this.erpInformation(profileResponse.user.email, profileResponse.user.accountingInformation, profileResponse);
        }
        else {
            this.setState({ isAgingReport: true });
        }
        let balanceStatement = res.items.filter(x => x.financialDocumentType == "BANK_STATEMENT_1");
        if (balanceStatement.length == 0) {
            if (profileResponse.user.accountingInformation == "quickbooks") {
                const bankTransaction = await this.api.getsupbanktransactionhistory(profileResponse.user._id);
                if (bankTransaction && bankTransaction.supplierBankTransactionHistory) {
                    this.setState({ isBankStatement: true });
                }
            }
        }
        else {
            this.setState({ isBankStatement: true });
        }
        this.setState({
            taskApiCallDoneFlag: true
        });
    }

    async erpInformation(email, source, userDetails) {
        if (userDetails.user.invoiceAgingReport &&
            userDetails.user.invoiceAgingReport.invoices &&
            userDetails.user.invoiceAgingReport.invoices.length > 0) {
            this.setState({ isAgingReport: true });
        }
        else {
            if (source == "quickbooks") {
                const agingValue = await this.api.getAgingReportFromQBO(email);
                if (agingValue) {
                    if (!agingValue.msg) {
                        this.setState({ isAgingReport: true });
                    }
                }
            }
            else if (source == "D365BC") {
                const bcAgingValue = await this.api.getReceivableAgingReportFromD365BC(email);
                if (bcAgingValue) {
                    if (!bcAgingValue.msg) {
                        this.setState({ isAgingReport: true });
                    }
                }
            }
            else {
                const ERPInfo = await this.api.getErpInfo(email);
                if (ERPInfo.isValid) {
                    this.setState({ isAgingReport: true });
                }
            }
        }
    }

    async getInvoices() {
        const invoicesResponse = await this.api.getallInvdocForDashboard();
        const openResponse = invoicesResponse.invoices.filter(value => value.status == 2);
        this.setState({ openInvoices: openResponse });
    }

    async getFundingRequest() {
        let fundingRequest = await this.api.getFundingReqForDashboard();
        if (this.user.currentUserRole() == "supplier") {
            const openResponse = fundingRequest.filter(value => value.status == 2);
            const fundingOfferReceived = fundingRequest.filter(value => value.status == 4);
            this.setState({ openFundingReqInvoices: openResponse, offerReceivedFundingReqList: fundingOfferReceived });
        }
        else if (this.user.currentUserRole() == "funder") {
            const awaitFundingOfferList_ = fundingRequest.filter(fund_ => fund_.status == 3 && !fund_.contractId);
            const markAsPaidList_ = fundingRequest.filter(fund_ => ((fund_.status == 6 || fund_.status == 11) && fund_.contracts && fund_.contracts.length>0 && fund_.contracts[0].fundingAggrement && fund_.contracts[0].signedAggrements));
            const waitFundingAgreementList_ = fundingRequest.filter(fund_ => {
                return fund_.status == 6 && fund_.contracts  && fund_.contracts.length > 0 && !fund_.contracts[0].fundingAggrement && !fund_.contracts[0].signedAggrements;
            });
            const approveDeclineList_ = fundingRequest.filter(fund_ => fund_.status == 3 && fund_.contractId);
            this.setState({ awaitFundingOfferList: awaitFundingOfferList_, markAsPaidList: markAsPaidList_, waitFundingAgreementList: waitFundingAgreementList_, approveDeclineList: approveDeclineList_ });
        }
    }

    async getContract() {
        const contractResponse = await this.api.getContractsForDashboard();
        const terminatedContracts = contractResponse.filter(value => value.status == 3);
        const expireContractsResponse = contractResponse.filter(value => {
            // let endDate=moment.utc(value.contractDueDate).format("MM-DD-YYYY");
            if (value.fundingAggrement && value.signedAggrements) {
                let endDate = moment(value.contractDueDate);
                let remainingDays = endDate.diff(moment(value.createdAt), 'days');
                return remainingDays <= 31;
            }
        });
        const pendingSignedContractsResponse = contractResponse.filter(value => value.status == 2 && value.fundingAggrement && !value.signedAggrements);
        this.setState({ terminatedContracts: terminatedContracts, expireContracts: expireContractsResponse, pendingSignedContracts: pendingSignedContractsResponse });
    }



    render() {
        let buyers = this.state.buyers;
        const {
            isFinancialInfo,
            isAgingReport,
            isSupplierdocs,
            openInvoices,
            openFundingReqInvoices,
            expireContracts,
            terminatedContracts,
            isBankStatement,
            pendingSignedContracts,
            awaitFundingOfferList,
            markAsPaidList,
            waitFundingAgreementList,
            approveDeclineList,
            offerReceivedFundingReqList,
            isLoading
        } = this.state;
        const {t}=this.props;
        return (

            <DashboardWidgetContainer title={t("dashboard_page.widget_titles.task_list")} size="col-md-4">
                {this.user.currentUserRole() == "supplier" && (
                    <React.Fragment>
                        {!this.state.taskApiCallDoneFlag && (
                            <React.Fragment>
                                <FullSpinner
                                    className="small-spinner"
                                    isSpinning={"Loading.."}
                                />
                            </React.Fragment>
                        )}

                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {(!isFinancialInfo && this.state.taskApiCallDoneFlag) && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mb-3" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                        {t("dashboard_page.task_list_content.finanical_information") }
                                    </div>
                                    <div className="archived-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        <NavLink style={{ color: "#930000" }} className="anchorstyle" to="/profile/financialInformation">{t("incomplete")}</NavLink>
                                    </div>
                                </React.Fragment>
                            )}
                            {(!isSupplierdocs && this.state.taskApiCallDoneFlag) && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mb-3" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.finanical_information") } 
                                    </div>
                                    <div className="archived-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        <NavLink style={{ color: "#930000" }} className="anchorstyle" to="/profile/financialInformation">{t("common_names.not_uploaded")}</NavLink>
                                    </div>
                                </React.Fragment>
                            )}
                            {(!isAgingReport && this.state.taskApiCallDoneFlag) && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mb-3" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.aging_erp_users") } 
                                    </div>
                                    <div className="archived-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        <NavLink style={{ color: "#930000" }} className="anchorstyle" to="/profile/financialInformation">{t("common_names.not_uploaded")}</NavLink>
                                    </div>
                                </React.Fragment>
                            )}
                            {(!isBankStatement && this.state.taskApiCallDoneFlag) && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mb-3" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.bank_statement") }
                                    </div>
                                    <div className="archived-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        <NavLink style={{ color: "#930000" }} className="anchorstyle" to="/profile/financialInformation">{t("common_names.not_uploaded")}</NavLink>
                                    </div>
                                </React.Fragment>
                            )}

                            {openInvoices.length > 0 && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.buyer_approved_invoices") }
                                    </div>
                                    <div className="progress-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        {openInvoices.length} {t("grid_view_header_labels.actions") }
                                    </div>
                                    <div className='row w-100'>
                                        {openInvoices.map(inv => (
                                            <div className="col-md-4"><NavLink className="anchorstyle" to="/document/InvoiceFinancing" onClick={()=>{
                                            NotificationService.subscribe(inv.documentId);
                                        }}>{inv.documentId}</NavLink></div>
                                        ))}
                                    </div>
                                    
                                </React.Fragment>
                            )}

                            {openFundingReqInvoices.length > 0 && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mt-2 mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.selected_match_funders") }
                                    </div>
                                    <div className="link-text-color mt-2" style={{ fontSize: 14, fontWeight: 600 }}>
                                        {openFundingReqInvoices.length} {t("grid_view_header_labels.actions") }
                                    </div>
                                    <div className='row w-100'>
                                        {openFundingReqInvoices.map(fundingRequest_ => (
                                            <div className="col-md-4"><NavLink className="anchorstyle" to="/fundingrequest"
                                            onClick={() => {
                                                NotificationService.subscribe(fundingRequest_.fundingReqId);
                                            }}>{fundingRequest_.fundingReqId}</NavLink></div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            )}
                            {pendingSignedContracts.length > 0 && (
                                <React.Fragment>
                                    {pendingSignedContracts.map(fundingContract_ => (
                                        <div className="col-md-12 px-0 mt-2">{t("dashboard_page.task_list_content.funding_aggrement_signed") }<NavLink className="anchorstyle ml-2" to="/fundingrequest"
                                            onClick={() => {
                                                NotificationService.subscribe(fundingContract_.fundingReqId);
                                            }}>{fundingContract_.fundingReqId}</NavLink></div>
                                    ))}
                                </React.Fragment>
                            )}
                            {offerReceivedFundingReqList.length > 0 && (
                                <React.Fragment>
                                    {offerReceivedFundingReqList.map(fundingReq_ => (
                                        <>
                                            <div className="my-2 mr-2">{t("dashboard_page.task_list_content.funding_offer_received") }
                                            <NavLink className="anchorstyle ml-2" to="/fundingrequest"
                                                onClick={() => {
                                                    NotificationService.subscribe(fundingReq_.fundingReqId);
                                                }}>{fundingReq_.fundingReqId}</NavLink></div>
                                            <NavLink className="anchorstyle my-2" to="/fundingrequest" onClick={() => {
                                                NotificationService.subscribe(fundingReq_.fundingReqId);
                                            }}>{fundingReq_.contracts.length} {t("offers") }</NavLink>
                                        </>
                                    ))}
                                </React.Fragment>
                            )}

                            {expireContracts.length > 0 && (
                                <React.Fragment>
                                    <div className="awaiting-text-color mt-2 mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                        {t("dashboard_page.task_list_content.contract_about_to_expire") }
                                    </div>
                                    <div className="mt-2" style={{ fontSize: 14, fontWeight: 600, color: "#FFB45B" }}>
                                        {expireContracts.length} {t("grid_view_header_labels.actions")}
                                    </div>
                                    <div className='row w-100'>
                                        {expireContracts.map(contracts_ => (
                                            <div className="col-md-4"><NavLink className="anchorstyle" to="/fundingcontract">{contracts_.fundingContractId}</NavLink></div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            )}
                        </div>

                        {isFinancialInfo && isSupplierdocs && isAgingReport && isBankStatement && openInvoices.length == 0
                            && openFundingReqInvoices.length == 0
                            && pendingSignedContracts.length == 0
                            && expireContracts.length == 0
                            && offerReceivedFundingReqList.length == 0
                            && (
                                <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                                    <b>{t("dashboard_page.task_list_content.no_task_list") }</b>
                                </div>
                            )}
                    </React.Fragment>
                )}


                {this.user.currentUserRole() == "funder" && (
                    <React.Fragment>
                        <FullSpinner isSpinning={isLoading} />
                        {awaitFundingOfferList.length > 0 && (
                            <React.Fragment>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="awaiting-text-color mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.awaiting_funding_offer") }
                                    </div>
                                    <div className="progress-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        {awaitFundingOfferList.length} {t("grid_view_header_labels.actions") }
                                    </div>
                                    {awaitFundingOfferList.map(contracts_ => (
                                        <div className="col-md-4"><NavLink className="anchorstyle" to="/fundingrequest/openInvoices"
                                        onClick={() => {
                                            NotificationService.subscribe(contracts_.fundingReqId);
                                        }}>{contracts_.fundingReqId}</NavLink></div>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                        {waitFundingAgreementList.length > 0 && (
                            <React.Fragment>

                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="awaiting-text-color mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.waiting_for_funding_agreement") }
                                    </div>
                                    <div className="progress-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        {waitFundingAgreementList.length} {t("grid_view_header_labels.actions") }
                                    </div>
                                    {waitFundingAgreementList.map(contracts_ => (
                                        <div className="col-md-4"><NavLink className="anchorstyle" to="/fundingrequest/inFundingInvoices" 
                                        onClick={() => {
                                            NotificationService.subscribe(contracts_.fundingReqId);
                                        }}>{contracts_.fundingReqId}</NavLink></div>
                                    ))}
                                </div>

                            </React.Fragment>
                        )}
                        {approveDeclineList.length > 0 && (
                            <React.Fragment>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="awaiting-text-color mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.approve_decline_funding_request") }
                                    </div>
                                    <div className="progress-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        {approveDeclineList.length} {t("grid_view_header_labels.actions") }
                                    </div>
                                    {approveDeclineList.map(contracts_ => (
                                        <div className="col-md-4"><NavLink className="anchorstyle" to="/fundingrequest/openInvoices" 
                                        onClick={() => {
                                            NotificationService.subscribe(contracts_.fundingReqId);
                                        }}>{contracts_.fundingReqId}</NavLink></div>
                                    ))}
                                </div>
                            </React.Fragment>
                        )}
                        {markAsPaidList.length > 0 && (
                            <React.Fragment>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <div className="awaiting-text-color mb-2" style={{ fontSize: 14, width: "75%", fontWeight: 600 }}>
                                    {t("dashboard_page.task_list_content.wait_mark_as_paid") }
                                    </div>
                                    <div className="progress-text-color" style={{ fontSize: 14, fontWeight: 600 }}>
                                        {markAsPaidList.length} {t("grid_view_header_labels.actions") }
                                    </div>
                                    {markAsPaidList.map(contracts_ => (
                                        <div className="col-md-4"><NavLink className="anchorstyle" to="/fundingrequest/inFundingInvoices" 
                                        onClick={() => {
                                            NotificationService.subscribe(contracts_.fundingReqId);
                                        }}>{contracts_.fundingReqId}</NavLink></div>
                                    ))}
                                </div>

                            </React.Fragment>
                        )}
                        {awaitFundingOfferList.length == 0 && markAsPaidList.length == 0 && waitFundingAgreementList.length == 0 && approveDeclineList.length == 0 && (
                            <div className="awaiting-text-color text-center" style={{ fontSize: 14, fontWeight: 600 }}>
                                <b>{t("dashboard_page.task_list_content.no_task_list") }</b>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </DashboardWidgetContainer>
        );
    }
}

