import React, { Component } from 'react'
import API from '../../../services/API';
import DataGrid from '../../common/DataGrid/DataGrid';
import FileViewerModal from '../../common/FileViewerModal/FileViewerModal';
import { convertUTF8String } from '../../../services/ConversionService';


var _fileIndex=0;

export default class PreFundingInvList extends Component {
    api = new API();

    _columnsSpecification = [
        {
            key: "name",
            name: this.props.t("grid_view_header_labels.customer_name"),
        },
        
        {
            key: "address",
            name: this.props.t("grid_view_header_labels.address"),
        },
        {
            key: "country",
            name: this.props.t("grid_view_header_labels.country"),
        },
        {
            key: "invoiceFile",
            name: this.props.t("grid_view_header_labels.invoice_file"),
            contentProvider: (datum_) => {
                if(datum_.invoiceFile && datum_.invoiceFile.length>0){
                    return datum_.invoiceFile.map((d,i) => {
                        return <div key={d.filename?d.filename:d.name}><a className="link-text-color anchorstyle website-link" href={""} onClick={(e)=>{
                            e.preventDefault();
                        }}>{d.filename?convertUTF8String(d.filename):d.name}</a></div>
                    });
                }
                else{
                    return null;
                }
            },
            eventType: "viewInvoice"
        },
        {
            key: "additionalDocs",
            name: this.props.t("grid_view_header_labels.other_documents"),
            contentProvider: (datum_) => {
                if(datum_.additionalDocs && datum_.additionalDocs.length>0){
                    return datum_.additionalDocs.map((d,i) => {
                        return <div key={d.filename?d.filename:d.name}><a className="link-text-color anchorstyle website-link" href={""} onClick={(e)=>{
                            e.preventDefault();
                            _fileIndex=i;
                        }}>{d.filename?convertUTF8String(d.filename):d.name}</a></div>
                    });
                }
                else{
                    return null;
                }
            },
            eventType: "additionalDocs"
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isAllCheckboxEnabled: false,
            selectedItems:[],
            emptydataLabel: this.props.t("empty_pre_funding_grid_view_msg"),
            isFileViewerModal: false,
            fileViewerContent: null,

        };
    }



    selectedItems=(items_)=>{
        this.props.selectedTradingPartners(items_);
    }

    render() {
        const { data ,selectedListData} = this.props;
        return (
            <div >
                {this.state.isFileViewerModal && this.state.fileViewerContent &&
                    <FileViewerModal
                        onClose={() => {
                            this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                        }}
                        data={this.state.fileViewerContent}
                    ></FileViewerModal>
                }
                <div className="form-group">
                    <DataGrid
                        title={this.props.t("select_trading_partners")}
                        emptydataLabel={this.state.emptydataLabel}
                        data={data && data.length>0?data:[]}
                        columns={this._columnsSpecification}
                        isNoPagination={true}
                        isRowsNonSelectable={true}
                        isGridSearch={false}
                        isNoMoreLink={false}
                        height={"25vh"}
                        onSelectionChange={(items_) =>  this.props.selectedTradingPartners(items_)}
                        isAllCheckboxEnabled={false}
                        temp={true}
                        //defaultselectedItems={selectedListData}
                        isPrefunding={this.props.isPrefunding ? this.props.isPrefunding : false}
                        popoverItems={ [
                            { key: "updatePreFundingInvoice", label: this.props.t("grid_view_action_labels.update_invoice") },
                            { key: "deletePreFundingInvoice", label: this.props.t("grid_view_action_labels.remove_invoice") }
                        ]}
                        onPopoverClick={(key_, row_) => {
                            if (key_ == "updatePreFundingInvoice") {
                                this.props.openEditInvoice(true, row_);
                            }
                            else if (key_ == "deletePreFundingInvoice") {
                               this.props.removeInvoice(row_);
                            }
                        }}
                        handleTableCellClick={(dataRow, type, index) => {
                            if (type == "viewInvoice" || type == "additionalDocs") {
                                this.setState({
                                    isFileViewerModal:true,
                                    fileViewerContent:type == "viewInvoice"?dataRow.invoiceFile[0]:dataRow.additionalDocs[_fileIndex],
                                    isFinancialDocument:false
                                 });
                            }
                        }}
                    />
                </div>
            </div>
        )
    }
}
