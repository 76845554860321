import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { faTrashAlt, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import API from '../../../services/API';
import User from '../../../services/User';
import { compareRole, enumRole } from '../../../configs/GlobalConfig';
import FileViewerModal from "../../common/FileViewerModal/FileViewerModal";
import PaymentTermsInput from "../../../services/InputTerms.js";
import "./modal.css";

import UploadInvPreFunding from '../../common/Modals/UploadInvPreFunding';
import PreFundingInvList from "../../../components/supplier/FundingRequest/PreFundingInvList.js";
import fileUploadConfig from '../../../configs/FileUploader.js';
import FileUploader from '../FileUploader/FileUploader.js';
import CommonPopUpModal from '../../CommonPopUpModal/CommonPopUpModal.js';
import TextInput from '../../CustomInputs/TextInput.js';
import MaskedTextInput from '../../CustomInputs/MaskedTextInput.js';
import { isUSCountryUser } from '../../../services/Utilities.js';


const {
    supportedFileTypes,
    placeholders,
    duplicateFileMessage
} = fileUploadConfig;

const getFileConfig = (type) => {
    const fileTypeConfig = supportedFileTypes[type] || {};
    return {
        supportedExt: fileTypeConfig.extensions || [],
        errorMsgType: fileTypeConfig.message || '',
        placeholder: placeholders[type] || '',
        errorMsgDuplicate: duplicateFileMessage || ''
    };
};

var _additonalDocumentName = "FUNDING_ADDITIONAL_DOCS";
var _fundingReqDocumentName = "FUNDING_EXISTING_DOCS";

class PreFundingRequestModal extends Component {
    api = new API();
    user = new User();
    constructor(props) {
        super(props);
        this.state = {
            financingType: '',
            selectedExistingBuyer: this.props.data && this.props.data.tradingPartnerLists ? this.props.data.tradingPartnerLists : [],
            collateral: this.props.data && this.props.data.totalfundingAmount ? this.props.data.totalfundingAmount : this.props.financingVolume,
            annualSales: this.props.data && this.props.data.annualRevenue ? this.props.data.annualRevenue : this.props.userDetails.revenue ? this.props.userDetails.revenue : "",
            paymentTerms: this.props.data && this.props.data.paymentTerms ? this.props.data.paymentTerms : "",
            taxId:this.props.data && this.props.data.taxId?this.props.data.taxId : this.props.userDetails.taxId ? this.props.userDetails.taxId : "",
            existingBuyers: [],
            additionalDocsName: '',
            openBuyerForm: false,
            createdBuyerName: "",
            error: "",
            docs1: [],
            docs2: [],
            docs3: [],
            docs4: [],
            docs5: [],
            docs6: [],
            docs7: [],
            docs8: [],
            fundingReqDocs: [],
            deletedAdditionalDocs: [],
            isFileViewerModal: false,
            fileViewerContent: null,
            isValueSelected: false,
            additionalDocumentCount: 0,
            selectedCountry: '',
            selectedCountryCurrency: '',
            buttonEnabled: false,
            isFinancialDocument: false,
            tradingPartnerDetailsList: this.props.data && this.props.data.addedTradingPartnersInListView ? this.props.data.addedTradingPartnersInListView : [],
            selectedInvToEdit: null,
            isAdditionalDocs: false,
            userDetails:this.props.userDetails?this.props.userDetails:{},
            isValidTaxID:true
        };
        this.state.addDocConfig = getFileConfig('additionalDocx');
    }

    componentDidMount() {
        //this.getAdditionalDocsCount();
        if(this.props && this.props.data){
            this.getPreFundingDocs();
        }
        // this.getBuyersList();
    }

    getPreFundingDocs = async () => {
        let res = await this.api.getSupplierCommonDocs(this.props.data.fundingReqId);
        if (res && res.items && res.items.length > 0) {
            let _docs1 = res.items.filter(x => x.financialDocumentType === "FINANCING_APPLICATION");
            let _docs2 = res.items.filter(x => x.financialDocumentType === "LAST_TWO_YEAR_FINANCIAL_STATEMENTS");
            let _docs3 = res.items.filter(x => x.financialDocumentType === "AGING_REPORTS");
            let _docs4 = res.items.filter(x => x.financialDocumentType === "ARTICLE_OF_INCORPORATION");
            let _docs5 = res.items.filter(x => x.financialDocumentType === "COPY_OF_PRINCIPAL_ID");
            let _docs6 = res.items.filter(x => x.financialDocumentType === "PAYMENT_HISTORY");
            let _docs7 = res.items.filter(x => x.financialDocumentType === "SALES_PROJECTION");
            let _docs8 = res.items.filter(x => x.financialDocumentType === "OTHER_PRE_FUNDING_DOCUMENTS");
            let _isAdditionalDocPresented = false;
            if (_docs1.length > 0 || _docs2.length > 0 || _docs3.length > 0 || _docs4.length > 0 || _docs5.length > 0 || _docs6.length > 0 || _docs7.length > 0 || _docs8.length > 0) {
                _isAdditionalDocPresented = true;
            }
            this.setState({ docs1: _docs1, docs2: _docs2, docs3: _docs3, docs4: _docs4, docs5: _docs5, docs6: _docs6, docs7: _docs7, docs8: _docs8, isAdditionalDocs: _isAdditionalDocPresented });
        }
    }

    getAdditionalDocsCount = async () => {
        const res = await this.api.getSupplierDocs();
        let _additonalDocCount = 0;
        let _items = [];
        let _additionalDocs = [];
        let _fundingReqDocs = [];
        if (res && res.items && res.items.length > 0) {
            _items = res.items.filter(x => x.financialDocumentType.replace(/[\d_]+/g, '').toLowerCase() === "additionaldocument");
            _additonalDocCount = _items.length;
            if (this.props && this.props.data && this.props.data._id) {
                _items.forEach((item) => {
                    if ((item.linkedId == this.props.data.fundingReqId) && (item.linkedType == _additonalDocumentName)) {
                        _additionalDocs.push(item);
                    }
                    else if ((item.linkedId == this.props.data.fundingReqId) && (item.linkedType == _fundingReqDocumentName)) {
                        _fundingReqDocs.push(item);
                    }
                })
            }

        }
        this.setState({ additionalDocumentCount: _additonalDocCount, docs1: _additionalDocs, fundingReqDocs: _fundingReqDocs });
    }

    // getBuyersList=async(addedTPDetails_)=>{
    //     console.log(addedTPDetails_)
    //     let _buyersList=await this.api.getAllTradingPartnersWithoutContract();
    //     this.setState({existingBuyers:_buyersList},()=>{
    //         if(addedTPDetails_){
    //             this.autoSelectBuyers(addedTPDetails_._id);
    //         }
    //     })

    // }

    // autoSelectBuyers=async(val_)=>{
    //     let { selectedExistingBuyer } = this.state;
    //     const _autoSelectBuyers=this.state.existingBuyers.filter(t_=>t_._id==val_);
    //     if (_autoSelectBuyers.length > 0) {
    //         if (Array.isArray(selectedExistingBuyer)) {
    //             selectedExistingBuyer.push(_autoSelectBuyers[0]);
    //         } else {
    //             selectedExistingBuyer = [_autoSelectBuyers[0]];
    //         }
    //         this.setState({ selectedExistingBuyer: selectedExistingBuyer, isValueSelected: true });
    //     }
    // }

    handleCreatePreFundingRequest = async (isSaveFundingRequest_) => {
        const { financingType, selectedExistingBuyer, collateral, paymentTerms, additionalDocumentCount, selectedCountryCurrency, annualSales, fundingReqDocs, tradingPartnerDetailsList, deletedAdditionalDocs, isAdditionalDocs,taxId } = this.state;
        const { docs1, docs2, docs3, docs4, docs5, docs6, docs7, docs8 } = this.state;
        const{isUpdate,data}=this.props
        const financingVolume = collateral;
        const dataFields = {
            financingType: compareRole(this.user.currentUserRole(), enumRole.Supplier) ? "Invoice" : "SupplyChain",
            selectedExistingBuyer: selectedExistingBuyer.map(tp => tp._id),
            paymentTerms,
            collateral: financingVolume.toString().replace(/[^0-9.]/g, ""),
            annualRevenue: annualSales.toString().replace(/[^0-9.]/g, ""),
            averageBankBalance: this.props.userDetails.balance,
            supplierIdInErp: this.props.userDetails.supplierIdInErp,
            taxId: this.props.userDetails.taxId,
            currencyCode: selectedCountryCurrency,
            saveAsDraft: isSaveFundingRequest_,
            _id: this.props.data && this.props.data._id ? this.props.data._id : false,
            previousAdditionalDocumentCount: additionalDocumentCount,
            tradingPartnerDetailsList,
            isAdditionalDocs: isAdditionalDocs,
            taxId,
            isUpdatePreFunding:isUpdate,
            fundingStatus: data && data.status?data.status:0
        };
        let _fundingFormData = new FormData();
        dataFields.currentAdditionalDocsCount = additionalDocumentCount + docs1.length;
        _fundingFormData.append("data", JSON.stringify(dataFields));
        const _fileAppend = (fileData_, fileType_) => {
            fileData_.forEach((stmt, i) => {
                if (stmt.preview) {
                    _fundingFormData.append(fileType_, stmt, stmt.name);
                }
            });
        }
        _fileAppend(docs1, "FINANCING_APPLICATION");
        _fileAppend(docs2, "LAST_TWO_YEAR_FINANCIAL_STATEMENTS");
        _fileAppend(docs3, "AGING_REPORTS");
        _fileAppend(docs4, "ARTICLE_OF_INCORPORATION");
        _fileAppend(docs5, "COPY_OF_PRINCIPAL_ID");
        _fileAppend(docs6, "PAYMENT_HISTORY");
        _fileAppend(docs7, "SALES_PROJECTION");
        _fileAppend(docs8, "OTHER_PRE_FUNDING_DOCUMENTS");
        // //Add deleted additional documents into form  data to be removed from server side as well.
        for (let bsmt_ of deletedAdditionalDocs) {
            if(bsmt_ && bsmt_.filename){
                _fundingFormData.append('deletedAdditionalDocs[]', JSON.stringify(bsmt_));
            }
        }
        tradingPartnerDetailsList.forEach(st_ => {
            for (let file of st_.invoiceFile) {
                if (file && file.name) {
                    _fundingFormData.append(`BUYER_INVOICE_FILE_${st_._id}`, file, file.name);
                }
            }
            for (let f_ of st_.additionalDocs) {
                if (f_ && f_.name) {
                    _fundingFormData.append(`BUYER_ADDITIONAL_FILES_${f_.fileType}_${st_._id}`, f_, f_.name);
                }
            }
        })
        await this.api.commonCreatePreFundingRequest(_fundingFormData);
        this.props.onClose();
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = value.replace(/\D/g, '');
        this.setState({ [name]: this.formatValue(formattedValue), buttonEnabled: true });
    }

    formatValue = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    handlePaymentTerm = (val) => {
        this.setState({
            paymentTerms: val, buttonEnabled: (val >= 1 && val <= 365) ? true : false
        })
    }

    handleTaxId = (name, value) => {
        const{country}=this.state.userDetails;
        let _taxId=value;
        let _isValidTaxID=false;
        let _stringValue="";
        if(value.length>0){
            _stringValue=value.replace(/[^0-9]/g, '');
        }
        if(isUSCountryUser(country)){
            _isValidTaxID=_stringValue.length == 9
        }
        else{
            _isValidTaxID=_stringValue.length >= 5
        }
        this.setState({
            taxId: value,isValidTaxID:_isValidTaxID
        })
    }


    selectedTradingPartnerList = async (tp_) => {
        this.setState({ selectedExistingBuyer: tp_, buttonEnabled: true });
    }

    tradingPartnerList = async (tp_, isopen_) => {
        try {
            const { selectedInvToEdit } = this.state;
            const tradingPartnerDetailsList = [...this.state.tradingPartnerDetailsList];
            const _selectedExistingBuyer = [...this.state.selectedExistingBuyer];
            if ((tp_ && tradingPartnerDetailsList.length == 0) || (tp_ && tradingPartnerDetailsList.filter(t_ => t_._id == tp_._id).length == 0)) {
                tradingPartnerDetailsList.push(tp_);
            }
            let _tradingPartnerDetailsList = tradingPartnerDetailsList.map(t_ => {
                t_.isRowSelect = false;
                return t_;
            });
            if (selectedInvToEdit) {
                _tradingPartnerDetailsList = _tradingPartnerDetailsList.map((t_) => {
                    if (t_._id == tp_._id) {
                        return tp_;
                    }
                    else {
                        return t_;
                    }
                });
            }
            else {
                let _filteredTradingPartners = _tradingPartnerDetailsList.filter(t_ => t_._id == tp_._id);
                if (_filteredTradingPartners && _filteredTradingPartners.length > 0) {
                    _selectedExistingBuyer.push(_filteredTradingPartners[0]);
                }
            }
            this.setState({ openBuyerForm: isopen_, tradingPartnerDetailsList: _tradingPartnerDetailsList, buttonEnabled: tp_ ? true : false, selectedExistingBuyer: _selectedExistingBuyer });
        }
        catch (ex) {
            this.setState({ openBuyerForm: isopen_ });
        }

    }

    handleAddDocUpload = (newDocs, stateToUpdate) => {
        this.setState(prevState => ({
            [stateToUpdate]: newDocs,
            buttonEnabled: true
        }));
    };

    handleDelete = (filteredDocs, selectedDoc, stateToUpdate) => {
        this.setState(prevState => ({
            [stateToUpdate]: filteredDocs,
            buttonEnabled: true,
            deletedAdditionalDocs: [...prevState.deletedAdditionalDocs, selectedDoc]
        }));
    };

    handleView = (doc) => {
        this.setState({
            fileViewerContent: doc,
            isFileViewerModal: true,
            isFinancialDocument: !!doc.downloadUrl
        });
    };

    handleModalClose = () => {
        this.props.onClose();
    }

    generateFileUploaders = () => {
        const items = [
            { title: this.props.t("file_upload_label.label01") },
            { title: this.props.t("file_upload_label.label02") },
            { title: this.props.t("file_upload_label.label03") },
            { title: this.props.t("file_upload_label.label04") },
            { title: this.props.t("file_upload_label.label05") },
            { title: this.props.t("file_upload_label.label06") },
            { title: this.props.t("file_upload_label.label07") },
            { title: this.props.t("file_upload_label.label12") },
        ];

        const fileUploaders = [];
        for (let i = 1; i <= items.length; i++) {
            let _fileExtensions=this.state.addDocConfig.supportedExt;
            if(i==1 || i==6){
                _fileExtensions=[".xls",".xlsx",".pdf"];
            }
            else if(i==7){
                _fileExtensions=[".xls",".xlsx"];
            }
            fileUploaders.push({
                id: i,
                title: items[i - 1].title,
                documents: this.state[`docs${i}`],
                onDocUpload: (newDocs) => this.handleAddDocUpload(newDocs, `docs${i}`),
                onDelete: (filteredDocs, selectedDoc) => this.handleDelete(filteredDocs, selectedDoc, `docs${i}`),
                onView: this.handleView,
                namelength: 30,
                supportedExt:_fileExtensions,
                //errorMsgType: this.props.t("file_upload_msg.please_provide_supported_file_format"),
                errorMsgDuplicate: this.props.t("file_upload_msg.default_error_msg"),
                placeholder: this.props.t("file_upload_msg.common_upload_msg"),
                isdisabled: false,
                isMultiple: true,
                isExtraction: false,
                isExampleFile: (i == 1 || i == 6 || i == 7) ? true : false,
                //exampleFileName: i == 1 ? this.props.t("file_upload_label.label01") : i == 6 ? this.props.t("file_upload_label.label06") : i == 7 ? this.props.t("file_upload_label.label07") : "",
                exampleFileName: this.props.t("downlaod_sample_file"),
                exampleDownloadLabel:i == 1 ? "Financing Application.xlsx" : i == 6 ? "Payment History.xlsx" : i == 7 ? "Sales Projection.xlsx" : "",
            });
        }

        return fileUploaders;
    };

    render() {

        const { financingType, existingBuyers, selectedExistingBuyer, collateral, annualSales, paymentTerms, openBuyerForm, isValueSelected, selectedCountryCurrency, docs1, fundingReqDocs, buttonEnabled } = this.state;
        // const _fundingTypeList = compareRole(
        //     this.user.currentUserRole(),
        //     enumRole.Supplier
        //   )
        //     ? [
        //         { value: "Invoice", name: "Invoice Financing" },
        //         { value: "PO", name: "PO Financing" }
        //       ]
        //     : [
        //         { value: "SupplyChain", name: "Supply Chain Finance" }
        //       ];

        // const filteredCountries = countries.filter(country => {
        //     return !blacklist.US.includes(country.country);
        // });
        const { invoiceConfig, poConfig, addDocConfig } = this.state;
        const isTaxIdEnabled=this.state.userDetails && this.state.userDetails.country && isUSCountryUser(this.state.userDetails.country)?this.state.isValidTaxID:true;
        const fileUploaders = this.generateFileUploaders();

        return (
            <>
                {this.state.isFileViewerModal && this.state.fileViewerContent &&
                    <FileViewerModal
                        onClose={() => {
                            this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                        }}
                        data={this.state.fileViewerContent}
                        isFinancialDocument={this.state.isFinancialDocument}
                    ></FileViewerModal>
                }
                {!openBuyerForm &&
                    <CommonPopUpModal
                        size="full"
                        title={this.props.t("fundingrequests_page.pre_funding_request_content.pre_funding_request_title")}
                        primaryBtn={this.props && this.props.isUpdate ?this.props.t("button_names.update"):this.props.t("button_names.submit")}
                        secondaryBtn={this.props && this.props.isUpdate ?"":this.props.t("button_names.save_as_draft")}
                        onClose={this.handleModalClose}
                        onSecBtnClick={() => this.handleCreatePreFundingRequest(true)}
                        onSubmit={() => this.handleCreatePreFundingRequest(false)}
                        isSubmitBtn={!(annualSales && collateral && (selectedExistingBuyer.length > 0 || isValueSelected) && paymentTerms >= 1 && paymentTerms <= 365 && isTaxIdEnabled)}
                        isSecBtnEnabled={!buttonEnabled}
                        promptOnClose={buttonEnabled}
                        uploadInfo={false}
                        children={
                            <div >
                                <div className="row">
                                    <div className='col-12 col-md-3'>
                                        {isUSCountryUser(this.state.userDetails.country) ? (
                                            <MaskedTextInput
                                                label={this.props.t("profile_page.financial_information_content.tax_id_label")}
                                                required={true}
                                                mask={[
                                                    /[1-9]/,
                                                    /\d/,
                                                    "-",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/
                                                ]}
                                                value={this.state.taxId}
                                                placeholder={this.props.t("common_placeholder")}
                                                onChange={this.handleTaxId}
                                            />
                                        ) : (
                                            <TextInput
                                                label={this.props.t("profile_page.financial_information_content.tax_id_label")}
                                                required={false}
                                                name="taxId"
                                                id="taxId"
                                                onChange={this.handleTaxId}
                                                placeholder={this.props.t("common_placeholder")}
                                                maxLength={15}
                                                value={this.state.taxId ? this.state.taxId.replace(/[^0-9aA-zZ.]/g, "") : this.state.taxId}
                                            />
                                        )}
                                        {!this.state.isValidTaxID && (
                                            <div className="formErrors">
                                                {this.props.t("profile_page.financial_information_content.invalid_tax_id")}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <legend className="form-label-style required">{this.props.t("fundingrequests_page.pre_funding_request_content.annual_sales")}</legend>
                                            <TextField
                                                name="annualSales"
                                                className="custom-input"
                                                value={annualSales.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                onChange={this.handleChange}
                                                fullWidth
                                                size="small"
                                                placeholder="Please enter a value"
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">{selectedCountryCurrency ? selectedCountryCurrency : "$"}</InputAdornment>,
                                                    inputProps: { maxLength: 20 },
                                                }}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <div className="form-group">
                                            <legend className="form-label-style required">{this.props.t("fundingrequests_page.pre_funding_request_content.total_financing_amount")}</legend>
                                            <TextField
                                                name="collateral"
                                                className="custom-input"
                                                value={collateral.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                onChange={this.handleChange}
                                                fullWidth
                                                size="small"
                                                placeholder={this.props.t("fundingrequests_page.pre_funding_request_content.annual_sales_placeholder")}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                    inputProps: { maxLength: 25 },
                                                }}
                                                InputLabelProps={{
                                                    shrink: false,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <PaymentTermsInput
                                            preFundterms={paymentTerms}
                                            isReadOnly={false}
                                            isPrefunding={true}
                                            value={paymentTerms}
                                            onDataChange={this.handlePaymentTerm}
                                        />
                                    </div>
                                </div>
                                <div className="row align-baseline">
                                    {fileUploaders.map((config, index) => (
                                        <>
                                            <div key={config.id} className="col-12 col-md-4 mt-3">
                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between' }}>
                                                    <div >
                                                        <legend className="form-label-style">{config.title}</legend>
                                                    </div>
                                                    {[0, 5, 6].includes(index) && (
                                                        <div  style={{  marginBottom: 5 }}>
                                                            {/* <span>Sample File:&nbsp;</span> */}
                                                            <a href={index==0?`${process.env.REACT_APP_UI_URL}/FinancingApplication.xls`:index==5?`${process.env.REACT_APP_UI_URL}/PaymentHistory.xlsx`:`${process.env.REACT_APP_UI_URL}/SalesReport.xlsx`}
                                                             >
                                                                {config.exampleFileName}
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                                <FileUploader
                                                    documents={config.documents}
                                                    onDocUpload={config.onDocUpload}
                                                    onDelete={config.onDelete}
                                                    onView={config.onView}
                                                    namelength={config.namelength}
                                                    supportedExt={config.supportedExt}
                                                    //errorMsgType={config.errorMsgType}
                                                    errorMsgDuplicate={config.errorMsgDuplicate}
                                                    placeholder={config.placeholder}
                                                    isdisabled={config.isdisabled}
                                                    isMultiple={config.isMultiple}
                                                    isExtraction={config.isExtraction}
                                                    customHeight={"9vh"}
                                                    isExampleFile={config.isExampleFile}
                                                    exampleFileContent={config.exampleFileContent}
                                                    exampleFileName={config.exampleFileName}
                                                />
                                            </div>
                                        </>

                                    ))}
                                </div>
                                <div className="centerAlign">
                                    <div className="mt-4">
                                        <button
                                            style={{ padding: "5px", fontSize: "17px" , borderRadius: "6px"}}
                                            className="btn-primary"
                                            onClick={() => this.setState({ openBuyerForm: true, selectedInvToEdit: null })}
                                        >
                                            <FontAwesomeIcon className="mr-2" icon={faFileUpload} />
                                            {this.props.t("fundingrequests_page.pre_funding_request_content.upload_invoice_previous_completed_transaction")}
                                        </button>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12">
                                        <PreFundingInvList
                                            data={this.state.tradingPartnerDetailsList}
                                            selectedTradingPartners={(t_) => this.selectedTradingPartnerList(t_)}
                                            //selectedListData={this.state.selectedExistingBuyer}
                                            openEditInvoice={(isEditInv, invDetails) => this.setState({ openBuyerForm: isEditInv, selectedInvToEdit: invDetails })}
                                            removeInvoice={(details_) => {
                                                const { tradingPartnerDetailsList, selectedExistingBuyer } = this.state;
                                                const _tradingPartnerDetailsList = tradingPartnerDetailsList.filter(t_ => t_._id != details_._id);
                                                const _selectedExistingBuyer = selectedExistingBuyer.filter(t_ => t_._id != details_._id);
                                                this.setState({
                                                    tradingPartnerDetailsList: _tradingPartnerDetailsList,
                                                    selectedExistingBuyer: _selectedExistingBuyer
                                                })
                                            }}
                                            isPrefunding={true}
                                            t={this.props.t}
                                        />
                                        <div className="privacy-message">
                                            <h2 className="privacy-title">{this.props.t("privacy_notice_title")}:</h2>
                                            {this.props.t("funding_request_privacy_message")}
                                        </div>
                                        {this.state.isFileViewerModal && this.state.fileViewerContent &&
                                            <FileViewerModal
                                                onClose={() => {
                                                    this.setState({ isFileViewerModal: false, fileViewerContent: null, isFinancialDocument: false });
                                                }}
                                                data={this.state.fileViewerContent}
                                                isFinancialDocument={this.state.isFinancialDocument}
                                            ></FileViewerModal>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    >
                    </CommonPopUpModal >
                }
                {openBuyerForm &&
                    <UploadInvPreFunding
                        selectedInv={this.state.selectedInvToEdit}
                        //buyerName={(val) => this.setState({ createdBuyerName: val })}
                        title={compareRole(this.user.currentUserRole(), enumRole.Supplier) ? "Create Buyer" : "Create Supplier"}
                        onClose={(val_, tpDetails_) => {
                            this.tradingPartnerList(tpDetails_, false);
                        }}
                        t={this.props.t}
                    />
                }
            </>
        );
    }
}

export default PreFundingRequestModal;
