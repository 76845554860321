import iconv from "iconv-lite";


export const convertUTF8String=(val_)=>{
    try{
        return val_?iconv.decode(val_,"UTF-8"):val_;
    }
    catch(ex){
        return val_; //return the original value if error occurs.
    }
  }

// Utility function to check if the string contains non-Latin characters
export const containsNonLatinCharacters = (str) => {
    // Matches characters in Chinese, Japanese, Korean character sets
    return /[\u4E00-\u9FFF\u3040-\u30FF\uAC00-\uD7AF]/.test(str);
};

// Utility function to get the correct file name
export const getFileName = (...names) => {
    for (let name of names) {
        if (name) {
            return containsNonLatinCharacters(name) ? name : convertUTF8String(name);
        }
    }

    return ''; // Return an empty string if no valid names are provided
};